import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import { Theme } from './ThemeProvider/useTheme/useTheme';

const SecondaryButton = styled(Button)`
  border: 2px solid ${(p) => (p.theme.mode === Theme.FITNESS ? '#1B7B7C' : '#ff695f')};
  color: ${(p) => (p.theme.mode === Theme.FITNESS ? '#1B7B7C' : '#ff695f')};
  background-color: #ffffff !important;
  text-align: center;
  font-size: 1.6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 0.5rem;
  .svg-inline--fa {
    margin-right: 1rem;
  }
  &:hover {
    color: ${(p) => (p.theme.mode === Theme.FITNESS ? '#1B7B7C' : '#ff695f')} !important;
  }
  &:active {
    color: ${(p) => (p.theme.mode === Theme.FITNESS ? '#1B7B7C' : '#ff695f')} !important;
  }
  &:focus {
    color: ${(p) => (p.theme.mode === Theme.FITNESS ? '#1B7B7C' : '#ff695f')} !important;
  }
  &:disabled {
    color: ${(p) => (p.theme.mode === Theme.FITNESS ? '#1B7B7C' : '#ff695f')} !important;
  }
`;

export default SecondaryButton;
