import React, { FC } from 'react';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import styled from 'styled-components';
import useAuthenticatedImage from '../hooks/useAuthenticatedImage';

const ImageCardStyle = styled(Card)`
  max-width: 232px;
  width: 100%;
  margin: 0 0 10px 15px !important;
  padding: 2rem 1rem 1rem 1rem;
  position: relative;
  border-radius: 12px;
  .dropdown {
    position: absolute;
    right: 8px;
    top: 0;
  }
  .dropdown-toggle {
    padding: 0 2px;
    height: 18px;
    vertical-align: top;
    font-size: 16px;
    line-height: 19px;
    color: #595959;
    &:hover {
      color: #111111;
    }
    &:after {
      display: none;
    }
  }
  .dropdown-item {
    font-size: 16px;
    color: #ff6960;
    &:active {
      background-color: #f9f9f9;
    }
  }
  &.filler {
    min-height: 0;
    margin: 0 7px;
    padding: 0;
    opacity: 0;
  }
  > span {
    display: block;
    cursor: pointer;
    position: absolute;
    right: 7px;
    top: 7px;
    opacity: 0.9;
    transition: 100ms ease;
    &:hover {
      opacity: 1;
    }
  }
`;

const ImageCard: FC<{ id: string; onClick?: () => void; imageSrc?: string }> = ({
  id,
  onClick,
  imageSrc,
  children,
}) => {
  const imageObject = useAuthenticatedImage(imageSrc);
  return (
    <ImageCardStyle>
      {onClick && (
        <DropdownButton alignRight variant="link" id={id} title={<i className="fas fa-ellipsis-h"></i>} size="sm">
          <Dropdown.Item onClick={onClick}>Bild löschen</Dropdown.Item>
        </DropdownButton>
      )}
      {imageObject && <Card.Img variant="top" src={imageObject} />}
      {children}
    </ImageCardStyle>
  );
};

export default ImageCard;
