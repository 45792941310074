const parseURL = (url) => {
  const parser = document.createElement('a');
  parser.href = url;
  return parser;
};

const getLocation = (type) => {
  if (type === 'node') {
    if (process.env.REACT_APP_BACKEND_API_URL) {
      return parseURL(process.env.REACT_APP_BACKEND_API_URL);
    }
    return window.location;
  } else if (type === 'django') return parseURL(process.env.REACT_APP_DJANGO_BACKEND_API_URL);
};

export const getFullURL = (relPath, type = 'node') => {
  const loc = getLocation(type);
  return `${loc.protocol}//${loc.host}${relPath}`;
};
