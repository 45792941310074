import { getFullURL } from './endpoints';

export function generatePatientRoomURL(code: string): string {
  return getFullURL('/' + code);
}

export function generateGroupRoomURL(code: string): string {
  return getFullURL('/' + code);
}

export function getSubdomain(): string {
  let host = window.location.host;
  let parts = host.split(".");
  let subdomain = "dev";
  if (parts.length >= 3) {
    subdomain = parts[0];
  }
  return subdomain;
}
export function titleCase(str: string): string {
  return str.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()).trim();
}