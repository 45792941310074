import { WebAuth } from 'auth0-js';
import { noop } from 'lodash';
import React, { createContext, memo, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import EmailValidationModal from '../components/EmailValidationModal';
import { emitNotification } from '../components/Notification';
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, AUTH0_LOGOUT_URL } from '../config';
import { fetchFromRestAPI } from './api';

export const Auth0Context = createContext({
  isAuthenticated: false,
  user: undefined,
  loading: false,
  isAdminUser: false,
  handleOnAuthenticated: noop,
  handleOnSignedIn: noop,
  loginWithRedirect: (options?: any) => Promise.resolve(),
  getIdTokenClaims: (options?: any) => Promise.resolve({ __raw: null }),
  getAccessTokenClaims: (options?: any) => Promise.resolve({ __raw: null }),
  isApprovedTherapist: false,
  logout: noop,
});

export const useAuth0 = () => useContext(Auth0Context);
const webAuth = new WebAuth({
  clientID: AUTH0_CLIENT_ID,
  domain: AUTH0_DOMAIN ? AUTH0_DOMAIN : process.env.REACT_APP_AUTH0_DOMAIN,
  redirectUri: AUTH0_LOGOUT_URL,
});


const Auth0ProviderMemo = ({ children }) => {

  const [user, setUser] = useState();
  const [accessToken, setAccessToken] = useState({ __raw: null });
  const [idToken, setIdToken] = useState({ __raw: null });
  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [isApprovedTherapist, setIsApprovedTherapist] = useState(false);
  const [logOut, setLogOut] = useState(false);
  const [requestedVerificationEMail, setRequestedVerificationEMail] = useState(false);
  const [lastAuthToken, setLastAuthToken] = useState(null);
  const [emailValidationModalShow, setEmailValidationModalShow] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies([]);

  const handleOnSignedIn = () => {
    emitNotification(
      <div>
        <big>
          Wir haben dir soeben eine E-Mail geschickt. Bitte bestätige deine E-Mail Adresse, damit du enlivio nutzen
          kannst.
        </big>
        <br />
        <br />
        Solltest du Fragen haben, schicke uns eine E-mail an{' '}
        <a href="mailto:info@enlivio.com">info@enlivio.com</a>
      </div>,
      {
        position: 'top-center',
        autoClose: 20000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );

    setTimeout(() => {
      window.location.reload();
    }, 30000);
  };

  const resendVerificationMail = async (token) => {
    const response = await fetchFromRestAPI('/api/v1/user-profile/verification-email', {
      method: 'POST',
      token: { __raw: token },
    });
    if (response.ok) {
      setRequestedVerificationEMail(true);
    }
  };

  const handleOnAuthenticated = (authResult, user) => {
    if (logOut) {
      setLoggedOutUnlock();
      return;
    }

    if (user && !user.email_verified && user.sub.startsWith('auth0|') && !logOut) {
      setLastAuthToken(authResult.accessToken);
      setEmailValidationModalShow(true);
      setLoggedOut();
      clearSessionLocalStorage();
      return;
    }

    if (!isAuthenticated) {
      setUser(user);
      const now = Date.now();
      const expiresAt = now + (authResult.expiresIn * 1000);
      localStorage.setItem('access_token', authResult.accessToken);
      localStorage.setItem('id_token', authResult.idToken);
      localStorage.setItem('user', user);
      localStorage.setItem('expires_at', parseInt(expiresAt.toString()).toString());
      setIdToken({ __raw: authResult.idToken });
      setAccessToken({ __raw: authResult.accessToken });
      // TODO: WE SHOULD USE AUTH0 PERMISSIONS HERE, TO MAKE IT POSSIBLE WE SHOULD ACTIVATE RBAC, NOT POSSIBLE TO CHANGE NOW

      setIsAdminUser(user.email.endsWith('enlivio.com'));
      setIsApprovedTherapist(user.approved);
      setIsAuthenticated(true);
    }
    // todo: keep path in local storage and redirect to it
    //history.push(redirectToAfterAuth);
  };

  const clearSessionLocalStorage = () => {
    cleanAuthCookies();

    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('user');
  };

  const setLoggedOut = () => {
    setLogOut(true);
    setIsAdminUser(false);
    setIsAuthenticated(false);
    setUser(null);
    setIdToken(null);
    setAccessToken(null);
  };

  const setLoggedOutUnlock = () => {
    setLogOut(false);
  };

  const cleanAuthCookies = () => {
    if (!cookies) {
      return;
    }
    // We need to clean auth0 cookies for old sessions and in case of using not main domain like staging
    const cookiesKeys = Object.keys(cookies);
    cookiesKeys.forEach((cookie) => {
      if (cookie.includes('auth0') || cookie.includes('did')) {
        setCookie(cookie, '', { expires: new Date() });
        removeCookie(cookie);
      }
    });
  };

  const logout = (redirect = true) => {
    setLoggedOut();
    clearSessionLocalStorage();
    webAuth.logout({
      clientID: AUTH0_CLIENT_ID,
      domain: AUTH0_DOMAIN ? AUTH0_DOMAIN : process.env.REACT_APP_AUTH0_DOMAIN,
      returnTo: AUTH0_LOGOUT_URL,
      // federated: true
    });

    setLoggedOutUnlock();
  };

  useEffect(() => {
    const accessTokenExpires = localStorage.getItem('expires_at');
    const accessToken = localStorage.getItem('access_token');
    if (accessTokenExpires && accessToken) {
      // access token in locale storage
      const now = Date.now();
      if (parseInt(accessTokenExpires) > parseInt(now.toString())) {
        if (logOut) {
          setLogOut(false);
          return;
        }
        webAuth.client.userInfo(accessToken, (err, userInfo) => {
          if (err) {
            console.error(`Error fetching user info with access token`);
            return;
          }
          const idToken = localStorage.getItem('id_token');
          const accessToken = localStorage.getItem('access_token');
          setIdToken({ __raw: idToken });
          setAccessToken({ __raw: accessToken });
          setIsAuthenticated(true);
          setUser(userInfo);
          setIsAdminUser(userInfo.email.endsWith('enlivio.com'));
          setLoading(false);
        });
      } else {
        console.log('Access token expired, clearing storage');
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('user');
        localStorage.removeItem('_online_');
      }
    }
  }, [logOut]);


  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        isAdminUser,
        isApprovedTherapist,
        handleOnSignedIn: handleOnSignedIn,
        handleOnAuthenticated: handleOnAuthenticated,
        logout: logout,
        loginWithRedirect: () => Promise.resolve(),
        getIdTokenClaims: () => {
          return Promise.resolve(accessToken);
        },
        getAccessTokenClaims: () => {
          return Promise.resolve(idToken);
        },
      }}
    >
      {children}
      <EmailValidationModal
        show={emailValidationModalShow}
        sent={requestedVerificationEMail}
        onPositive={() => {
          resendVerificationMail(lastAuthToken);
          setTimeout(() => {
            setEmailValidationModalShow(false);
            setLastAuthToken(null);
            setRequestedVerificationEMail(false);
            logout(true);
          }, 1000);
        }}
        onNegative={() => {
          setEmailValidationModalShow(false);
          setLastAuthToken(null);
          setRequestedVerificationEMail(false);
          logout(true);
        }}
      />
    </Auth0Context.Provider>
  );
};

export const Auth0Provider = memo(Auth0ProviderMemo);
