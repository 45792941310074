import React, { FC, memo, useEffect, useState } from "react";
import Avatar from "react-avatar";
import { useAuth0 } from "../../util/auth0";
import { Card } from "react-bootstrap";
import { isEmpty } from "lodash";
type Props = {
    therapies: any,
    newMessage: any,
    tKeyPair: any,
    selectedTherapy: any,
    handleSelectTherapy: (therapy) => void;
};
const ChatHeadHorizontalComponent: FC<Props> = ({ therapies, newMessage, tKeyPair, handleSelectTherapy,selectedTherapy }) => {
    const { user } = useAuth0();
    let onlineUsers = localStorage.getItem('_online_') ? localStorage.getItem('_online_').split(',') : [];
    const [newMessageList, setNewMessageList] = useState([]);
    const [therapyList, setTherapyList] = useState([]);
    const [selectedTherapyId, setSelectedTherapyId] = useState(selectedTherapy? selectedTherapy._id:'');
    const changeChatHead = (therapy) => {
        if (therapy && therapy.patient) {
            const index = newMessageList.findIndex((id) => id === therapy.patient._id);
            if (index !== -1) {
                setNewMessageList([
                    ...newMessageList.slice(0, index),
                    ...newMessageList.slice(index + 1)
                ]);
            }
            setSelectedTherapyId(therapy._id);
            handleSelectTherapy(therapy);
        }
    }
    useEffect(() => {
        if (newMessage && newMessage.senderId) {
            setNewMessageList([...newMessageList, newMessage.senderId]);
        }
        // eslint-disable-next-line
    }, [newMessage]);
    useEffect(() => {
        if (therapies && therapies.length > 0) {
            setTherapyList(therapies);
            sortTherapyList(therapies);
        }
        // eslint-disable-next-line
    }, [therapies]);

    const renderChatHead = (therapy, index) => {
        if (therapy.patient) {
            const newClass = newMessageList.indexOf(therapy.patient._id) >= 0 ? 'bg-primary' : '';
            const selected = selectedTherapyId === therapy._id ? 'chat-head-selected' : '';
            return (
                <Card key={"chatHead-" + index} className={selectedTherapyId !== therapy._id && newMessageList.indexOf(therapy.patient._id) >= 0 ? `chat-card m-2 border border-secondary ${selected} ${newClass}` : `chat-card m-2 ${selected} ${newClass}`} onClick={() => { changeChatHead(therapy) }}>
                    <Card.Body className="text-center pb-0">
                        <Avatar name={therapy.patient.firstName + " " + therapy.patient.lastName} size="40" color="#707089" className="rounded-circle mr-1" round={true} />
                        <p className="pt-3"> {therapy.patient.firstName ? therapy.patient.firstName.substring(0, 10) : ''}</p>
                    </Card.Body>
                </Card>
            )
        }
        return null;

    }
    const renderOnlineUser = () => {
        const topTherapy = therapyList.filter((x: any) => x.patient && onlineUsers.indexOf(x.patient._id) >= 0 && x.patient.accountId !== user.sub);
        if (topTherapy && topTherapy.length > 0) {
            return (<>
                {therapyList.filter((x: any) => x.patient && onlineUsers.indexOf(x.patient._id) >= 0 && x.patient.accountId !== user.sub).map((therapy, index) => {
                    return renderChatHead(therapy, index);
                })}
            </>)
        }
    }
    const renderOfflineUserLastMessage = () => {
        return (<>
            {therapyList.filter((x: any) => x.patient && x.lastMessageDate === null && onlineUsers.indexOf(x.patient._id) < 0 && x.patient.accountId !== user.sub).map((therapy, index) => {
                return renderChatHead(therapy, index);
            })}
        </>)
    }
    const renderOfflineUser = () => {
        return (<>
            {therapyList.filter((x: any) => x.patient && x.lastMessageDate !== null && onlineUsers.indexOf(x.patient._id) < 0 && x.patient.accountId !== user.sub).map((therapy, index) => {
                return renderChatHead(therapy, index);
            })}
        </>)
    }
    const renderTherapistUser = () => {
        return (<>
            {therapyList.filter((x: any) => x.patient && x.patient.accountId === user.sub).map((therapy, index) => {
                return renderChatHead(therapy, index);
            })}
        </>)
    }
    const sortTherapyList = (tList:any) => {
        if (isEmpty(selectedTherapy)) {
            let topTherapy = tList.filter((x: any) => x.patient && onlineUsers.indexOf(x.patient._id) >= 0 && x.patient.accountId !== user.sub);
            topTherapy = topTherapy.length === 0 ? tList.filter((x: any) => x.patient && x.lastMessageDate !== null && onlineUsers.indexOf(x.patient._id) < 0 && x.patient.accountId !== user.sub) : topTherapy;
            topTherapy = topTherapy.length === 0 ? tList.filter((x: any) => x.patient && x.lastMessageDate === null && onlineUsers.indexOf(x.patient._id) < 0 && x.patient.accountId !== user.sub) : topTherapy;
            topTherapy = topTherapy.length === 0 ? tList.filter((x: any) => x.patient && x.patient.accountId === user.sub) : topTherapy;
            if (topTherapy && topTherapy.length > 0) {
                handleSelectTherapy(topTherapy[0]);
                setSelectedTherapyId(topTherapy[0]._id);
            }
        }
    }
    return (
        <>
            {therapyList && therapyList.length !== 0 ? <div className="row flex-nowrap horizontal-scroll">
                {renderOnlineUser()}
                {renderOfflineUser()}
                {renderOfflineUserLastMessage()}
                {renderTherapistUser()}
            </div> : null}</>
    )
}

export default memo(ChatHeadHorizontalComponent);