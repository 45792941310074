import i18next from 'i18next';
import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.footer`
  margin-top: auto;
  justify-content: flex-end;
   background: #002b4b;
  line-height: 3;
  display: flex;
  color: white;
  width: 100%;
  a {
    color: white;
  }
`;
type Props = { isFeedbackButton: boolean };
const Footer: FC<Props> = ({ isFeedbackButton }) => {
  return (
    <Wrapper>
      <div className="container text-center">
        <span className="text-muted" dangerouslySetInnerHTML={{ __html: i18next.t('footer') }}></span>
      </div>
      {isFeedbackButton ?
        <div className="popup-btn" >
          <a rel="noopener noreferrer" target="_blank" href={i18next.t('generic.therapistFeedback.link')}>{i18next.t('generic.therapistFeedback.text')}</a>
        </div> : null}
    </Wrapper>
  )
};

export default Footer;
