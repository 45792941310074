import React, { FC } from 'react';
import styled from 'styled-components';
import useParticipants from '../../hooks/useParticipants/useParticipants';

const ParticipantsCount = styled.div`
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  width: 100%;
`;

const ParticipantsCounter: FC = () => {
  const participants = useParticipants();
  return (
    <ParticipantsCount>
      <span>{participants.length} Teilnehmende</span>
    </ParticipantsCount>
  );
};

export default ParticipantsCounter;
