import { Formik } from 'formik';
import i18next from 'i18next';
import { isEmpty } from 'lodash';
import React, { FC } from 'react';
import { Button, Form } from 'react-bootstrap';

const TIME_PATTERN = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

export type Session = {
  start: Date;
  end: Date;
};

type Props = {
  onCreateManualSession: (session: Session) => Promise<void>;
};

export const CreateManualSessionForm: FC<Props> = ({ onCreateManualSession }) => {
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  const initialValues = { date: today.getTime(), startTime: '', endTime: '' };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async ({ date, startTime, endTime }, { setSubmitting }) => {
        const start = new Date(`${date} ${startTime}`);
        const end = new Date(`${date} ${endTime}`);
        await onCreateManualSession({ start, end }); // TODO error handling
        setSubmitting(false);
      }}
      validate={({ date, startTime, endTime }) => {
        const errors: { date?: string; startTime?: string; endTime?: string } = {};
        if (isEmpty(date)) {
          errors.date = i18next.t('createManualSessions.validation.date');
        }
        if (!TIME_PATTERN.test(startTime)) {
          errors.startTime = i18next.t('createManualSessions.validation.startTime');
        }
        if (!TIME_PATTERN.test(endTime)) {
          errors.endTime = i18next.t('createManualSessions.validation.endTime');
        }
        if (!errors.startTime && !errors.endTime) {
          if (startTime > endTime) {
            errors.endTime = i18next.t('createManualSessions.validation.startTimeEndTime');
          }
        }
        return errors;
      }}
    >
      {({ values, handleChange, errors, dirty, isValid, isSubmitting, handleSubmit }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group controlId="date">
            <Form.Label>{i18next.t('createManualSessions.labels.date')}</Form.Label>
            <Form.Control type="date" value={values.date} onChange={handleChange} isInvalid={!isEmpty(errors.date)} />
            <Form.Control.Feedback type="invalid">{errors.date}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="startTime">
            <Form.Label>{i18next.t('createManualSessions.labels.startTime')}</Form.Label>
            <Form.Control
              type="time"
              value={values.startTime}
              onChange={handleChange}
              isInvalid={!isEmpty(errors.startTime)}
            />
            <Form.Control.Feedback type="invalid">{errors.startTime}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="endTime">
            <Form.Label>{i18next.t('createManualSessions.labels.endTime')}</Form.Label>
            <Form.Control
              type="time"
              value={values.endTime}
              onChange={handleChange}
              isInvalid={!isEmpty(errors.endTime)}
            />
            <Form.Control.Feedback type="invalid">{errors.endTime}</Form.Control.Feedback>
          </Form.Group>
          <Button type="submit" disabled={!dirty || !isValid || isSubmitting}>
            {i18next.t('createManualSessions.labels.submit')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default CreateManualSessionForm;
