import React, { useState, memo, useEffect } from 'react';
import styled from 'styled-components';
import i18next from 'i18next';
import { isEmpty } from 'lodash';
import 'moment/locale/de';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useAuth0 } from '../util/auth0';
import { fetchFromRestAPI } from '../util/api';
import AppointmentListGlobal from '../components/appointment/ApointmentListGlobal';

const Wrapper = styled.div`
  color: inherit;
  margin: 2rem;
  h1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 51px;
    letter-spacing: -0.03em;
    color: #FFFFFF;
  }
`;

const EmptyDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const AppointmentListWrapper = styled.main`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  .dropdown-item {
    font-size: 1.5rem;
  }
  .btn-secondary {
    border: 2px solid #ff695f;
    color: #ff695f;
  }
  h1 {
    font-weight: bold;
    font-size: 4rem;
  }
  .banner {
    display: flex;
    > div {
      max-width: 480px;
    }
  }
  .control-btns {
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: auto;
    .btn {
      margin-bottom: 8px;
    }
  }
  .patient-overview {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    > div {
      &:hover {
        background-color: #ececec;
        cursor: pointer;
        .name {
          font-weight: 700;
        }
      }
      display: flex;
      white-space: nowrap;
      padding: 4px 16px;
      align-items: center;
      background: white;
      &:first-of-type {
        padding-top: 8px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      &:last-of-type {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        padding-bottom: 8px;
      }
      .name {
        flex: 1;
        color: #444;
      }
      input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        height: 39px;
        width: 250px;
      }
    }
  }
  @media (max-width: 750px), (max-device-width: 815px) and (-webkit-min-device-pixel-ratio: 3) {
    .banner {
      h1 {
        font-size: 1.8rem;
      }
      flex-direction: column;
      .control-btns {
        align-items: flex-start;
      }
    }
    .text-right {
        text-align: left !important;
    }
    .patient-overview {
      > div {
        flex-direction: column;
        border-top: 1px solid #eee;
        &:first-of-type {
          border-top: 0;
        }
        .name {
          width: 100%;
          padding: 0;
        }
        .btn-group input {
          width: 100%;
        }
      }
    }
  }
`;
const AppointmentContainer = () => {
  const { user, getIdTokenClaims } = useAuth0();
  const [appointmentList, setAppointmentList] = useState([]);

  useEffect(() => {
    const fetchDataAsync = async () => {
      if (user) {
        await getAppointments();
      }
    };
    fetchDataAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getAppointments = async () => {
    const idToken: any = await getIdTokenClaims();
    await fetchFromRestAPI(`/api/v1/appointment/therapist`, {
      token: idToken,
    }).then((appointmentData) => {
      setAppointmentList(appointmentData);
    }).catch((err) => {
      console.error('Error removing appointment', err);
    });
  }
  
  return (
    <Container>
      <Wrapper>
       
        <AppointmentListWrapper>
          <Row>
            <Col md={12}>
              <h1>{i18next.t('header.appointments')}</h1>
              {
                !isEmpty(appointmentList) ?
                  <AppointmentListGlobal appointmentList={appointmentList} />
                  :
                  <Card><Card.Body><EmptyDiv>{i18next.t('createAppointmentForm.noAppointmentData')}</EmptyDiv></Card.Body></Card>
              }
            </Col>
          </Row>
        </AppointmentListWrapper>
      </Wrapper>
    </Container>
  );

};

export default memo(AppointmentContainer);