import React, { FC } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import StyledBigModal from '../../components/StyledBigModal';

type Props = {
  show: boolean;
  therapistData: any;
  onClose: () => void;
  blockUnblock: (id,action) => Promise<void>;
};

export const DetailsModal: FC<Props> = ({ show, therapistData, onClose,blockUnblock }) => {
  const callsByDayRows =
    therapistData && therapistData.callsByDay
      ? therapistData.callsByDay.map((record) => {
        return (
          <tr key={record.day} style={{ verticalAlign: 'middle' }}>
            <td>{record.day}</td>
            <td style={{ textAlign: 'center' }}>
              {Math.round((record.durationInHours + Number.EPSILON) * 100) / 100}
            </td>
          </tr>
        );
      })
      : null;

  const callsByPatientRows =
    therapistData && therapistData.callsByPatient
      ? therapistData.callsByPatient.map((record) => {
        return (
          <tr key={record.patientId} style={{ verticalAlign: 'middle' }}>
            <td>{record.patientId}</td>
            <td>{record.patient}</td>
            <td style={{ textAlign: 'center' }}>
              {Math.round((record.durationInHours + Number.EPSILON) * 100) / 100}
            </td>
          </tr>
        );
      })
      : null;

  return (
    <StyledBigModal show={show} onHide={() => onClose()} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Therapist details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h2>Profile info</h2>
        <Table hover>
          <tbody>
            <tr>
              <td>
                <strong>Auth0 ID</strong>
              </td>
              <td>{therapistData.accountId ? therapistData.accountId : '-'}</td>
            </tr>
            <tr>
              <td>
                <strong>OnSched ResourceID</strong>
              </td>
              <td>{therapistData.onSchedResourceID ? therapistData.onSchedResourceID : '-'}</td>
            </tr>
            <tr>
              <td>
                <strong>Insurnace Plans</strong>
              </td>
              <td>{therapistData.plans ? therapistData.plans : '-'}</td>
            </tr>
          </tbody>
        </Table>

        <h2 style={{ marginTop: '3rem' }}>Personal Details</h2>
        <Table striped bordered hover>
          <tbody>
            <tr>
              <td>
                <strong>First name</strong>
              </td>
              <td>{therapistData.firstName ? therapistData.firstName : '-'}</td>
            </tr>
            <tr>
              <td>
                <strong>Last name</strong>
              </td>
              <td>{therapistData.lastName ? therapistData.lastName : '-'}</td>
            </tr>
            <tr>
              <td>
                <strong>Email</strong>
              </td>
              <td>{therapistData.email ? therapistData.email : '-'}</td>
            </tr>
            <tr>
              <td>
                <strong>Birth date</strong>
              </td>
              <td>{therapistData.birthDate ? therapistData.birthDate : '-'}</td>
            </tr>
            <tr>
              <td>
                <strong>Credits in sec</strong>
              </td>
              <td>{therapistData.remainingCreditsInSecs ? therapistData.remainingCreditsInSecs : 0}</td>
            </tr>
            <tr>
              <td>
                <strong>Approved</strong>
              </td>
              <td>{therapistData.approved ? 'Active' : 'Blocked'}
              &nbsp;&nbsp;<Button variant="success" onClick={() => blockUnblock(therapistData._id,false)}>UnBlock</Button>
              
              &nbsp;&nbsp;<Button  variant="danger" onClick={() => blockUnblock(therapistData._id,true)}>Block</Button>
             </td>
            </tr>
          </tbody>
        </Table>

        <h2 style={{ marginTop: '3rem' }}>Sessions by date</h2>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Day</th>
              <th style={{ textAlign: 'center' }}>Duration (hours)</th>
            </tr>
          </thead>
          <tbody>{callsByDayRows}</tbody>
        </Table>

        <h2 style={{ marginTop: '3rem' }}>Sessions by patient</h2>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Patient ID</th>
              <th>Patient</th>
              <th style={{ textAlign: 'center' }}>Duration (hours)</th>
            </tr>
          </thead>
          <tbody>{callsByPatientRows}</tbody>
        </Table>
      </Modal.Body>
    </StyledBigModal >
  );
};

export default DetailsModal;
