import { generatePatientRoomURL } from './urls';
import { fetchFromRestAPI } from './api';
import { formatDate } from './dateTimeUtils';

const ROOM_INVITE_DEFAULT_TEMPLATE_ID = 'd-7cec30f04a13497b96cd5747c351b6a8';
const ROOM_INVITE_CUSTOM_MESSAGE_TEMPLATE_ID = 'd-7329e17acb29480fbee2cf878dfe8ead';

interface RoomInviteEmailOptions {
  to: string;
  inviteMessage?: string;
  patientFirstName?: string;
  patientLastName?: string;
  therapistFullname: string;
  patientId: string;
  sessionDate?: string;
  token: { __raw: string };
}

export async function sendRoomInviteEmail(options: RoomInviteEmailOptions) {
  const emailParams = {
    to: options.to,
    subject: 'Room4Physio invite',
    templateId: options.inviteMessage ? ROOM_INVITE_CUSTOM_MESSAGE_TEMPLATE_ID : ROOM_INVITE_DEFAULT_TEMPLATE_ID,
    from: 'einladung@room4physio.com',
    templateData: {
      firstName: options.patientFirstName,
      lastName: options.patientLastName,
      therapistFullname: options.therapistFullname,
      customMessageLines: options.inviteMessage ? options.inviteMessage.split('\n') : [],
      roomURL: generatePatientRoomURL(options.patientId),
      sessionDate: options.sessionDate ? formatDate(options.sessionDate) : null,
    },
  };

  await fetchFromRestAPI('/api/v1/email', {
    method: 'POST',
    body: emailParams,
    token: options.token,
  });
}

export async function sendNewRoomInviteEmail(options: RoomInviteEmailOptions) {
  const emailParams = {
    to: options.to,
    subject: 'Room4Physio invite',
    templateId: options.inviteMessage ? ROOM_INVITE_CUSTOM_MESSAGE_TEMPLATE_ID : ROOM_INVITE_DEFAULT_TEMPLATE_ID,
    from: 'einladung@room4physio.com',
    templateData: {
      firstName: options.patientFirstName,
      lastName: options.patientLastName,
      therapistFullname: options.therapistFullname,
      customMessageLines: options.inviteMessage ? options.inviteMessage.split('\n') : [],
      roomURL: generatePatientRoomURL(options.patientId),
      sessionDate: options.sessionDate ? formatDate(options.sessionDate) : null,
    },
  };

  await fetchFromRestAPI('/api/v1/email/patient/invite', {
    method: 'POST',
    body: emailParams,
    token: options.token,
  });
}
