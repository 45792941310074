import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Table, InputGroup } from 'react-bootstrap';
import { fetchFromRestAPI } from '../../util/api';
import { map } from 'lodash';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import { DefaultPlayer as Video } from 'react-html5video';
import { useAuth0 } from '../../util/auth0';
import styled from 'styled-components';
import PrimaryButton from '../../components/PrimaryButton';
const RefreshButton = styled(PrimaryButton)`
  float: right;
  margin: 1rem 0;
`;

const AdminVideoList = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [videoList, setVideoList] = useState([]);
    const [searchKey, setSearchKey] = useState('');
    const i18nextLng = localStorage.getItem('i18nextLng');
    const userLanguage = i18nextLng && i18nextLng.includes('-') ? i18nextLng.split('-')[0] : i18nextLng;
    const [tagList, setTagList] = useState([]);
    const [pageNo, setPageNo] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const { getIdTokenClaims, user } = useAuth0();
    const fetchTagList = useCallback(async () => {
        const token = await getIdTokenClaims();
        const newTagList = await fetchFromRestAPI(`/api/v1/exercise/video/tag/list`, {
            method: 'GET',
            token,
        });
        if (newTagList && newTagList.length > 0) {
            setTagList(newTagList);
        }

    }, [getIdTokenClaims]);
    const fetchVideos = useCallback(async () => {
        setHasMore(false);
        if (!user) {
            return;
        }
        setIsLoading(true);
        const token = await getIdTokenClaims();
        const newVideoList = await fetchFromRestAPI(`/api/v1/exercise/video/list/${pageNo}`, {
            method: 'POST',
            token,
        });
        if (newVideoList && newVideoList.length > 0) {
            const newArray = [...videoList, ...newVideoList];
            setVideoList(newArray);
            setHasMore(true);
        } else {
            setHasMore(false);
        }
        setIsLoading(false);
        // eslint-disable-next-line
    }, [user, getIdTokenClaims, pageNo]);


    useEffect(() => {
        fetchVideos();
        fetchTagList();
    }, [user, fetchVideos, pageNo, fetchTagList]);


    const updateVideo = async (id, body) => {
        setIsLoading(true);
        const token = await getIdTokenClaims();
        await fetchFromRestAPI(`/api/v1/exercise/video/update/${id}`, {
            method: 'PATCH',
            token,
            body
        });
        setIsLoading(false);
        fetchVideos();
    }
    const handleSearch = async (e) => {
        setIsLoading(true);
        const token = await getIdTokenClaims();
        const body = { searchKey: searchKey }
        const newVideoList = await fetchFromRestAPI(`/api/v1/exercise/video/list/0`, {
            method: 'POST',
            token,
            body
        });
        if (newVideoList && newVideoList.length > 0) {
            setVideoList(newVideoList);
            setHasMore(true);
        } else {
            setVideoList([]);
            setHasMore(false);
        }
        setIsLoading(false);
    }
    return (
        <React.Fragment>
            <Form>
                <InputGroup className="mb-3">
                    <Form.Control type="text"
                        placeholder="Search"
                        aria-describedby="basic-addon"
                        onChange={(e) => { setSearchKey(e.target.value) }}
                    />
                    <Button variant="secondary" onClick={handleSearch} id="button-addon">
                        Search
                    </Button>
                </InputGroup>
            </Form>
            <RefreshButton className="ml-2" onClick={() => { setVideoList([]); setPageNo(1); }} disabled={isLoading}>
                {isLoading ? 'Refreshing...' : 'Reset'}
            </RefreshButton>
            <RefreshButton className="ml-2" onClick={() => { setPageNo(pageNo - 1); }} disabled={pageNo <= 1}>
                Back
            </RefreshButton>
            <RefreshButton className="ml-2" onClick={() => { setPageNo(pageNo + 1); }} disabled={!hasMore}>
                {!hasMore ? 'No More' : 'Next'}
            </RefreshButton>

            <Table striped bordered hover className="h-75 overflow-auto">
                <thead>
                    <tr>
                        <th>Video</th>
                        <th className='centered-td'>DE</th>
                        <th className='centered-td'>EN</th>
                        <th className='centered-td'>IsDynamic</th>
                        <th className='centered-td'>Type</th>
                        <th className='centered-td'>Tags</th>
                    </tr>
                </thead>
                <tbody key={videoList.length} >

                    {map(videoList, (video) => {
                        return (
                            <tr key={video._id}>
                                <td className='centered-td'><Video className="react-player" loop muted preload='none'
                                    controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                                    poster={video.hThumbnail? video.hThumbnail : '/video_thumbnail.png'}
                                >
                                    <source src={video.horizontalUrl} type="video/mp4" />

                                </Video></td>
                                <td className='centered-td'>{video.deName}</td>
                                <td className='centered-td'>{video.enName}</td>
                                <td className='centered-td'>{video.isDynamic ? 'True' : 'False'}
                                </td>
                                <td className='centered-td'>
                                    <Form.Control as="select" defaultValue={video.exerciseType}
                                        onChange={(e) => {
                                            video.exerciseType = e.target.value;
                                            switch (e.target.value) {
                                                case 'dynamic':
                                                    video.isDynamic = true;
                                                    break;
                                                case 'static':
                                                    video.isDynamic = false;
                                                    break;
                                                case 'relaxation':
                                                    video.isDynamic = false;
                                                    break;
                                                case 'endurance':
                                                    video.isDynamic = true;
                                                    break;
                                            }
                                        }}>
                                        <option value="static">STATIC [isDynamic=false]</option>
                                        <option value="dynamic">DYNAMIC [isDynamic=true]</option>
                                        <option value="relaxation">RELAXATION [isDynamic=false]</option>
                                        <option value="endurance">ENDURANCE [isDynamic=true]</option>
                                    </Form.Control>
                                </td>
                                <td className='centered-td w-25'>
                                    <Typeahead
                                        id="specialization-typeahead-multiple"
                                        multiple
                                        onChange={(e) => {
                                            video.tagIds = [];
                                            e.forEach(element => {
                                                video.tagIds.push(element);
                                            });
                                            updateVideo(video._id,video);
                                        }}
                                        labelKey={(option) => userLanguage === 'de' ? `${option.de}` : `${option.en}`}
                                        options={tagList}
                                        placeholder="Video Tags"
                                        selected={video.tagIds}
                                    />
                                </td>
                                <td className='centered-td'><Button variant='dark' onClick={() => { updateVideo(video._id, video) }}>Save</Button></td>
                            </tr>
                        );
                    })}

                </tbody>
            </Table>
        </React.Fragment>
    );
};
export default AdminVideoList;
