import i18next from "i18next";
import React, { memo, useState, useEffect } from "react";
import { find } from "lodash";
import moment from "moment";
import { useAuth0 } from '../../util/auth0';
import { Accordion, Badge, Card, Col, ProgressBar, Row } from "react-bootstrap";
import styled from "styled-components";
import { fetchFromRestAPI } from "../../util/api";
import { titleCase } from "../../util/urls";
const Wrapper = styled.div`
font-family: Montserrat !important;
.card{
    border: 1px solid rgba(100, 141, 141, 0.1);
    box-shadow: 1px 1px 8px 0px rgba(100, 141, 141, 0.25);
    border-radius: 12px;
    background: #FFFFFF;
}
.border-color {
    border-bottom: 1px solid rgba(206, 212, 218, 1)
}
.h-8{
    height:6px !important;
}
.week-text{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: rgba(112, 112, 137, 1);
}
.font-2x {
    font-size: 1.2em;
}
.text-secondary{
    color: #161616 !important;
}
.reason-content {
    background: #F7F7F7;
    border: 1px solid #DFDFDF;
    box-sizing: border-box;
    padding: 8px;
    border-radius:8px;
    p {
        margin-bottom: 0px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #161616;
    }
}
`;

const TrainingPlanAccordion = ({ trainingPlanData, trainingPlanId, trainingPlanStatus }) => {
    const { getIdTokenClaims } = useAuth0();
    const [isLoading, setIsLoading] = useState(false);
    const [activityData, setActivity] = useState({ weekList: [], historyList: [] });
    const [exerciseList, setExerciseList] = useState([]);
    const [activeId, setActiveId] = useState([]);
    const i18nextLng = localStorage.getItem('i18nextLng');
    const userLanguage = i18nextLng && i18nextLng.includes('-') ? i18nextLng.split('-')[0] : i18nextLng;
    const variantByStatus = {
        DONE: 'badge-bg-info',
        SKIPPED: 'badge-bg-info',
        DONEWITHSKIPS: 'badge-bg-info',
        ABORTED: 'badge-bg-danger',
    };
    const toggleActive = (id: string) => {
        const index = activeId.indexOf(id);
        if (index < 0) {
            setActiveId(activeId => [...activeId, id]);
        }
        else {
            const temp = [...activeId];
            temp.splice(index, 1);
            setActiveId(temp);
        }
    }
    const fetchTrainingPlanAnalysis = async (trainingPlanId) => {
        setIsLoading(true);
        const idToken: any = await getIdTokenClaims();
        const analysisData = await fetchFromRestAPI(`/api/v1/trainingplan/analysis/web/${trainingPlanId}`, {
            token: idToken,
        });
        const exerciseResp = await fetchFromRestAPI(`/api/v1/trainingplan/analysis/web/exercise/${trainingPlanId}`, {
            token: idToken,
        });
        if (exerciseResp && !exerciseResp.error) {
            setExerciseList(exerciseResp);
        }
        if (analysisData && !analysisData.error && analysisData.weekList && analysisData.weekList.length > 0) {
            setActivity(analysisData);
            toggleActive(analysisData.weekList[0].weeknumber.toString());
            setIsLoading(false);
        } else {
            setActivity({ weekList: [], historyList: [] });
            setIsLoading(false);
        }
    };
    useEffect(() => {
        const fetchDataAsync = async () => {
            if (trainingPlanId) {
                await fetchTrainingPlanAnalysis(trainingPlanId);
            }
        };
        fetchDataAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trainingPlanId]);


    const renderPromoAnswer = (p, i) => {
        const question = find(p.promid.prom, { 'lang': userLanguage })["question"];
        return (
            <div key={'prom_' + i}>
                <p className="mb-0">{titleCase(question.title)}<span className="float-right pr-4">{parseInt(p.answer)}/10</span></p>
                <ProgressBar className="mb-1 h-8" variant="primary" now={parseInt(p.answer) * 10} />
            </div>);
    }
    const renderExercise = (ex, i) => {
        const exObj = exerciseList.find((x) => x._id === ex.eid);
        if (exObj) {
            return (
                <Col md={12} key={"ex_" + i}>
                    {ex.status === 'DONE' ? <i className="fas fa-check-circle text-success" aria-hidden="true" /> : <i className="fas fa-times-circle text-danger" aria-hidden="true" />} &nbsp; {exObj.name}
                    {ex.comment || ex.answer ? <div className="reason-content">
                        {ex.answer && ['NOTFIT', 'NOTIME', 'NOANSWER'].indexOf(ex.answer) >= 0 ? <span className="font-weight-bold">{i18next.t('answerAndActivity.AbortedText.' + ex.answer)}</span> : null}
                        {ex.comment ? <p dangerouslySetInnerHTML={{ __html: ex.comment }}></p> : null}</div> : null}
                </Col >
            );
        } else {
            return null;
        }
    }
    const renderListOfAbortedExercise = (exList,ex, i) => {
        const isAlreadyRender=exList.find((x)=>x.eid===ex._id);
        const exObj = exerciseList.find((x) => x._id === ex._id);
        if (!isAlreadyRender && exObj) {
            return (
                <Col md={12} key={"ex_" + i}>
                    {ex.status === 'DONE' ? <i className="fas fa-check-circle text-success" aria-hidden="true" /> : <i className="fas fa-times-circle text-danger" aria-hidden="true" />} &nbsp; {exObj.name}
                    {ex.comment || ex.answer ? <div className="reason-content">
                        {ex.answer && ['NOTFIT', 'NOTIME', 'NOANSWER'].indexOf(ex.answer) >= 0 ? <span className="font-weight-bold">{i18next.t('answerAndActivity.AbortedText.' + ex.answer)}</span> : null}
                        {ex.comment ? <p dangerouslySetInnerHTML={{ __html: ex.comment }}></p> : null}</div> : null}
                </Col >
            );
        } else {
            return null;
        }
    }
    const checkTrainingList = (exList,t, trainingName) => {
        return (
            <span key={'exTraining' + t._id}>
                {t.name === trainingName ?
                    t.exercise ? t.exercise.map((e, j) => renderListOfAbortedExercise(exList,e, j)) : null : null}
            </span>)
    }
    const renderExerciseList = (exList, index, status, trainingName) => {
        return <span key={"exlist_" + index}>
            {exList.map((value, i) => renderExercise(value, i))}
            {status === 'ABORTED' ?
                trainingPlanData ? trainingPlanData.trainings.map((t, i) => checkTrainingList(exList,t, trainingName)) : null : null}

        </span>
    }
    const renderTrainingStatusHeader = (weeknumber, training) => {
        if (moment().isoWeek() === +weeknumber && training.status !== 'UNPUBLISHED') {
            return (<Accordion.Toggle className={training.completedTraining !== training.workoutPerWeek ? "font-weight-bold p-2" : "text-success font-weight-bold p-2"} onClick={() => toggleActive(weeknumber)} as={Col} eventKey={weeknumber}>
                {training.completedTraining !== training.workoutPerWeek ? '' : <i className="fas fa-check-circle text-success" aria-hidden="true" />}&nbsp;{training.trainingName}
                <span key={'arrow_' + activeId.length} className={training.completedTraining !== training.workoutPerWeek ? "float-right pr-4 font-weight-bold" : "text-success float-right pr-4 font-weight-bold"}>
                    {training.completedTraining}/{training.workoutPerWeek}&nbsp;&nbsp;&nbsp;&nbsp;
                    {activeId.indexOf(weeknumber) >= 0 ? <i className="fas fa-angle-up" aria-hidden="true" /> : <i className="fas fa-angle-down" aria-hidden="true" />}
                </span>
            </Accordion.Toggle>);
        } else {
            return (<Accordion.Toggle className={training.completedTraining !== training.workoutPerWeek ? "text-danger font-weight-bold p-2" : "text-success font-weight-bold p-2"} onClick={() => toggleActive(weeknumber)} as={Col} eventKey={weeknumber}>
                {training.completedTraining !== training.workoutPerWeek ? <i className="fas fa-times-circle text-danger" aria-hidden="true" /> : <i className="fas fa-check-circle text-success" aria-hidden="true" />}&nbsp;{training.trainingName}
                <span key={'arrow_' + activeId.length} className={training.completedTraining !== training.workoutPerWeek ? "text-danger float-right pr-4 font-weight-bold" : "text-success float-right pr-4 font-weight-bold"}>
                    {training.completedTraining}/{training.workoutPerWeek}&nbsp;&nbsp;&nbsp;&nbsp;
                    {activeId.indexOf(weeknumber) >= 0 ? <i className="fas fa-angle-up" aria-hidden="true" /> : <i className="fas fa-angle-down" aria-hidden="true" />}
                </span>
            </Accordion.Toggle>);
        }
    }
    const renderHistoryCard = (history, index, historyList, trainingName) => {
        return (<Row className={historyList.length - 1 !== index ? 'border-color border-bottom mb-3 p-4' : 'mb-3 p-4'} key={"tp_content_" + index}>
            <Col md={6} xs={6}>
                <p className='font-weight-bold'>{moment(history.historyDate).format('DD-MM-YYYY')}</p>
            </Col>
            <Col md={6} xs={6}>
                <Badge className={'float-right badge-status align-top mr-3 ' + variantByStatus[history.status]}>
                    {i18next.t('answerAndActivity.ExerciseStatus' + history.status)}</Badge>
            </Col>
            <Col md={7}>
                {history.generalFeedback ? <p className="font-weight-bold mb-0">{i18next.t('answerAndActivity.GeneralFeedback')} </p> : null}
                {history.generalFeedback ? <div className="reason-content"><p dangerouslySetInnerHTML={{ __html: history.generalFeedback }}></p></div> : null}
                {history.answer && ['NOTFIT', 'NOTIME', 'NOANSWER'].indexOf(history.answer) >= 0 ? <p className="font-weight-bold mb-0">{i18next.t('answerAndActivity.AbortedLabel')} </p> : null}
                {history.answer && ['NOTFIT', 'NOTIME', 'NOANSWER'].indexOf(history.answer) >= 0 ? <div className="reason-content"><span className="font-weight-bold">{i18next.t('answerAndActivity.AbortedText.' + history.answer)}</span>{history.comment ? <p dangerouslySetInnerHTML={{ __html: history.comment }}></p> : null}</div> : null}
                <p className="font-weight-bold mt-2 mb-1">{i18next.t('answerAndActivity.ExerciseLabel')}</p>
                {history.comment && ['NOTFIT', 'NOTIME', 'NOANSWER'].indexOf(history.answer) < 0 ? <div className="reason-content">
                    {history.comment ? <p dangerouslySetInnerHTML={{ __html: history.comment }}></p> : null}</div> : null}
                <Row className="pl-2">
                    {renderExerciseList(history.exerciseHistory, index, history.status, trainingName)}
                </Row>
            </Col>
            <Col md={5} className="pt-5">
                {history.promAns ? history.promAns.map((prmo, j) => renderPromoAnswer(prmo, j)) : null}
            </Col>
        </Row >);
    }
    const renderTrainingHeader = (weeknumber, history, index) => {
        const historyList = history.history[weeknumber];

        return (
            <Card className="mb-3" key={"training_header_" + index}>
                <Accordion defaultActiveKey={weeknumber}>
                    <Card key={history._id} >
                        <Card.Header className="pointer">
                            {renderTrainingStatusHeader(weeknumber, history)}
                        </Card.Header>

                        <Accordion.Collapse eventKey={weeknumber}>
                            <Card.Body>
                                {historyList && historyList.length > 0 ? historyList.map((h, k) => renderHistoryCard(h, k, historyList, history.trainingName)) : i18next.t('answerAndActivity.noTrainingPlanActivity')}

                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </Card>
        );
    }
    const renderWeekAccordian = (week, history, index) => {
        return (
            <div key={'tp_card_' + week.weeknumber + index}>
                <p className="week-text m-3">
                    {moment().isoWeek() === +week.weeknumber ? `${i18next.t('answerAndActivity.WeekText')} ${week.weeknumber} (${i18next.t('answerAndActivity.CurrentText')})` : `${i18next.t('answerAndActivity.WeekText')} ${week.weeknumber}`}
                </p>
                {history.map((value, i) => renderTrainingHeader(week.weeknumber, value, i))}

            </div>
        );
    };
    return (
        <Wrapper>
            {
                isLoading === true ?
                    <Row>
                        <Col md={12} className="text-center">
                            <span className="loader">
                                <i className="fas fa-spin fa-cog" />
                            </span>
                        </Col>
                    </Row> :

                    activityData.weekList.length === 0 ?
                        <Row>
                            <Col md={12}>
                                <Card className="mb-2">
                                    <Card.Body>
                                        {i18next.t('answerAndActivity.noTrainingPlanActivity')}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row> :
                        <Row>
                            <Col md={12}>
                                {activityData.weekList.map((week, index) => renderWeekAccordian(week, activityData.historyList.filter((h) => h.weeknumber === week.weeknumber), index))}
                            </Col>
                        </Row>
            }
        </Wrapper>
    );
}

export default memo(TrainingPlanAccordion);