import styled from 'styled-components';
import React from 'react';
import i18next from 'i18next';
import { Button } from 'react-bootstrap';

const Wrapper = styled.div``;
const Heading = styled.h1`
  text-align: center;
  font-size: 3.2rem;
  color: #ffffff;
  font-weight: 700;
  padding: 4rem 0;
`;
const PatientTherapistBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: start;
  flex-wrap: wrap;
`;

const TherapistAccount = styled.div`
  text-align: center;
  font-size: 1.2rem;
`;

const PatientTherapistLoginBox = styled.div`
  max-width: 40rem;
  width: 100%;
  border-radius: 5px;
`;

const LoginBoxContent = styled.div`
  padding: 17px;
  > *:not(:last-child) {
    margin-bottom: 17px;
  }
`;

const SubHeading = styled.h2`
  height: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.6rem;
  font-weight: 700;
  color: #ffffff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const TherapistSubheading = styled(SubHeading)`
  background-color: #002b4b;
`;

const TherapistLoginRegistrationContainer = styled(PatientTherapistLoginBox)`
  background-color: #d4e1ea;
`;
const goToRegisterPage = () => {
  window.location.href="/register"
};
const goToLoginPage = () => {
  window.location.href="/"
};
const RegisterPageLayout = ({ loginComponent, signIn }) => {
  return (
    <Wrapper>
      <Heading>{i18next.t('loginpage.welcome')}</Heading>
      <PatientTherapistBox>
        <TherapistLoginRegistrationContainer>
          <TherapistSubheading>{i18next.t('loginpage.trainers')}</TherapistSubheading>
          <LoginBoxContent>
            {signIn ? <div> <p dangerouslySetInnerHTML={{ __html: i18next.t('loginpage.trainersSubheading') }} /></div> : null}
            {loginComponent}
            <TherapistAccount>
              {signIn ? i18next.t('loginpage.noRegisteredAccount') : i18next.t('loginpage.RegisteredAccount')}
              <h3>
              {signIn? <Button variant="link" onClick={goToRegisterPage}>{i18next.t('loginpage.goToSignup')}</Button> : <Button variant="link" onClick={goToLoginPage}>{i18next.t('loginpage.goToLogin')}</Button>}
              </h3>
            </TherapistAccount>
          </LoginBoxContent>
        </TherapistLoginRegistrationContainer>
      </PatientTherapistBox>
    </Wrapper>
  );
};

export default RegisterPageLayout;
