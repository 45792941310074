import React, { FC, ReactNode, memo, useState,useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import StyledModal from './StyledModal';
import styled from 'styled-components';
import i18next from 'i18next';
import { Col, Form, Row } from 'react-bootstrap';

type Props = {
    show: boolean;
    title: ReactNode;
    titleIntro: ReactNode;
    body: ReactNode;
    initValue:boolean;
    buttonPositive: ReactNode;
    buttonNegative: ReactNode;
    onPositive: () => void;
    onNegative: () => void;
};

const BodyDiv = styled.div`
    height: 60vh;
    background: #F7F7F7;
    border: 1px solid #DFDFDF;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 10px;
    overflow-y: auto;
`;

const ContraindicationModal: FC<Props> = ({
    show,
    title,
    titleIntro,
    body,
    initValue,
    buttonPositive,
    buttonNegative,
    onPositive,
    onNegative,
}) => {
    
    const [contraindictationCheckbox, setContraindictationCheckbox] = useState(initValue);
    const [intendedUserCheckbox, setIntendedUserCheckbox] = useState(initValue);
    const [scrollEnd, setScrollEnd] = useState(initValue);
    useEffect(() => {
        if (intendedUserCheckbox && contraindictationCheckbox) {
            setScrollEnd(true)
        } else {
            setScrollEnd(false)
        }
    }, [contraindictationCheckbox, intendedUserCheckbox]);// eslint-disable-line

    const handleNegative = () => {
        setIntendedUserCheckbox(false);
        setContraindictationCheckbox(false);
        onNegative();
    }

    return (
        <StyledModal size="xl" dialogClassName="modal-90w modal-dialog-centered" show={show} onHide={() => handleNegative()}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{titleIntro}</p>
                <BodyDiv
                    dangerouslySetInnerHTML={{ __html: i18next.t('createPatientForm.contraindication.body') }}
                />
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col md={12} className="mb-2 pl-0">
                        <Form.Check
                            className='font-weight-bold'
                            inline
                            label={i18next.t('createPatientForm.contraindication.contraindicationMsg')}
                            name="group1"
                            type='checkbox'
                            onChange={(e) => setContraindictationCheckbox(e.target.checked)}

                        />

                    </Col>
                    <Col md={12} className="mb-2 pl-0">
                        <Form.Check
                            className='font-weight-bold'
                            inline
                            label={i18next.t('createPatientForm.contraindication.intendedUseMsg')}
                            name="group1"
                            type='checkbox'
                            onChange={(e) => setIntendedUserCheckbox(e.target.checked)}
                        />

                    </Col>
                    <Col md={6} className="pl-0">
                        <Button className="float-left" variant="secondary" style={{ marginRight: 'auto' }} onClick={() => handleNegative()}>
                            {buttonNegative}
                        </Button>
                    </Col>
                    <Col md={6}>
                        <Button key={'accept-'+scrollEnd.toString()} className="float-right" variant={!scrollEnd ? 'secondary' : 'primary'} title={i18next.t('createPatientForm.contraindication.mouseOver')} disabled={!scrollEnd} onClick={() => onPositive()}>
                           {buttonPositive}
                        </Button>
                    </Col>
                </Row>

            </Modal.Footer>
        </StyledModal>
    );
};

export default memo(ContraindicationModal);