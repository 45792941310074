import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import StyledModal from './StyledModal';
import { Button, Modal } from 'react-bootstrap';
import { Callback } from '../util/types';

type CustomDialogProps = {
  title: string;
  description: string;
  confirmation: string;
  show: boolean;
  onCancel: Callback;
  onAccept: Callback;
};

const CustomDialog: FunctionComponent<CustomDialogProps> = ({
  title,
  description,
  confirmation,
  show,
  onCancel,
  onAccept,
}) => {
  return (
    <StyledModal show={show} onHide={onCancel} dialogClassName="modal-dialog-centered">
      <React.Fragment>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{description}</Modal.Body>
        <Modal.Footer>
          {onCancel ? <Button style={{ marginRight: 'auto' }} variant="secondary" onClick={onCancel}>
            {i18next.t('generic.cancel')}
          </Button> : null}
          {onAccept ? <Button variant="primary" onClick={onAccept}>
            {confirmation}
          </Button> : null}
        </Modal.Footer>
      </React.Fragment>
    </StyledModal>
  );
};

export default CustomDialog;
