import Button from 'react-bootstrap/Button';
import React, { FC, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Uploader from '../../Uploader';
import useShareFile from '../../../hooks/useShareFile/useShareFile';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const ShareFileControls: FC<{ disabled?: boolean }> = ({ disabled }) => {
  const { sessionId } = useVideoContext(); // not sure whether this is the correct place to retrieve the session
  const [showShareFileModal, setShowShareFileModal] = useState(false);
  const { requestShareFile, sharedFileByHost } = useShareFile();

  const onShareFileButtonClicked = () => {
    setShowShareFileModal(!showShareFileModal);
  };

  const onShareFileRequested = async (file) => {
    await requestShareFile(file);
    setShowShareFileModal(false);
  };

  const onStopShareFileButtonClicked = async () => {
    await requestShareFile(null);
  };

  if (sharedFileByHost) {
    return (
      <Button onClick={onStopShareFileButtonClicked} disabled={disabled}>
        Material ausblenden
      </Button>
    );
  }

  return (
    <>
      <Button onClick={onShareFileButtonClicked} disabled={disabled}>
        Material teilen
      </Button>
      <Modal show={showShareFileModal} size="lg" onHide={() => setShowShareFileModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Material teilen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Uploader session={sessionId} action={onShareFileRequested} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowShareFileModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ShareFileControls;
