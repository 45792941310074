import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { LocalDataTrack } from 'twilio-video';
import { isMobile } from 'mobile-device-detect';
import styled from 'styled-components';
import useFullScreenToggle from '../../hooks/useFullScreenToggle/useFullScreenToggle';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useRoomView from '../../hooks/useRoomView/useRoomView';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import GroupRoom from './GroupRoom';
import GroupRoomFullScreen from './GroupRoomFullScreen';
import GroupRoomGrid from './GroupRoomGrid';
import GroupRoomMobile from './GroupRoomMobile';
import GroupRoomP2P from './GroupRoomP2P';
import LocalVideoPreview from './LocalVideoPreview';
import ParticipantsAudioTracks from './ParticipantsAudioTracks';
import RoomState from './RoomState';
import GroupRoomMobileFullScreen from './GroupRoomMobileFullScreen';

const Container = styled.div`
  overflow: hidden;
`;

const GroupVideoCall: FC = () => {
  const roomState = useRoomState();
  const [roomView] = useRoomView();
  const [isFullScreen] = useFullScreenToggle(true);
  const { isHostConnected, isCallHost, isP2P, localTracks, isParticipantConnected } = useVideoContext();
  const history = useHistory();

  history.listen(() => {
    localTracks.forEach((track) => {
      if (!(track instanceof LocalDataTrack)) {
        track.stop();
      }
    });
  });

  const getRoomTemplate = () => {
    if (isMobile && isFullScreen) {
      return <GroupRoomMobileFullScreen />;
    }
    if (isFullScreen) {
      return <GroupRoomFullScreen />;
    }
    if (isMobile) {
      return <GroupRoomMobile />;
    }
    if (isP2P) {
      return <GroupRoomP2P />;
    }

    if (roomView === 'grid') {
      return <GroupRoomGrid />;
    }
    return <GroupRoom />;
  };

  return (
    <Container id="video-room-container">
      {roomState !== 'disconnected' && isCallHost && isParticipantConnected && <RoomState timerActive />}
      {roomState === 'disconnected' || (!isCallHost && !isHostConnected) || (isCallHost && !isParticipantConnected) ? (
        <LocalVideoPreview />
      ) : (
        getRoomTemplate()
      )}
      {roomState !== 'disconnected' && <ParticipantsAudioTracks />}
    </Container>
  );
};

export default GroupVideoCall;
