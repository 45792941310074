import i18next from 'i18next';
import React, { FC, SyntheticEvent, useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Modal from 'react-bootstrap/Modal';
import { Link, NavLink, useHistory } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import { useAuth0 } from '../util/auth0';
import StyledModal from './StyledModal';
import { Theme, ThemeProps } from './ThemeProvider/useTheme/useTheme';
import { Auth0Lock } from 'auth0-lock';
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, AUTH0_LOGOUT_URL } from '../config';
import { fetchUserAccountRemote } from '../util/UserAccount';

const Nav = styled.nav<ThemeProps>`
  color: #ffffff;
  display: flex;
  height: 50px;
  .logo {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 5px;
    height: 50px;
    background: #ffffff;
  }
  .btn-sm {
    font-size: 1.3rem;
  }
  > div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    padding: 0 20px;
    background: #ffffff;
    a {
      margin-right: 20px;
      font-size: 1.3rem;
      height: 100%;
      display: flex;
      align-items: center;
      padding-top: 3px;
      border-bottom: 3px solid transparent;
      text-decoration: none;
      &.active {
        border-bottom-color: ${(p) => (p.theme.mode === Theme.FITNESS ? '#1B7B7C' : '#ff695f')};
      }
    }
  }
  .dropdown .btn {
    padding-left: 0;
  }
  img {
    height: 100%;
  }
  h1 {
    font-size: 2.4rem;
    font-weight: 600;
    color: #434343;
    margin-right: auto;
  }

  @media (max-width: 750px), (max-device-width: 815px) and (-webkit-min-device-pixel-ratio: 3) {
    .logo {
      display: none;
    }
    .dropdown {
      margin-left: auto;
    }

    > div {
      a {
        font-size: 1.1rem;
      }
    }
  }
`;

const StyledDropdownButton = styled(DropdownButton)`
  @media (max-width: 750px), (max-device-width: 815px) and (-webkit-min-device-pixel-ratio: 3) {
    .btn-sm {
      font-size: 0;
      margin-top: 7px;
      &::after {
        font-size: 2.3rem;
        vertical-align: bottom;
      }
    }
  }
`;

const Header: FC = () => {
  const { user, isAdminUser, getIdTokenClaims } = useAuth0();
  const [modal, setModal] = useState('');
  const [notAllowedFeature, setNotAllowedFeature] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 720);
  const theme = useContext(ThemeContext);
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }
  let topNavIcon = <img src="/themes/physio/logo.png" alt="Room4Physio" />;
  if (theme.mode === Theme.FITNESS) {
    topNavIcon = <img src="/themes/fitness/logo.jpg" alt="Room4Fitness" />;
  }
  const getUserProfile = (idToken: any) => {
    fetchUserAccountRemote(idToken).then((profileResponse) => {
      if(profileResponse && profileResponse.notAllowedFeature){
        setNotAllowedFeature(profileResponse.notAllowedFeature)
      }
    });
  }
  useEffect(() => {
    getIdTokenClaims().then((idToken) => {
      getUserProfile(idToken);
    });
  }, [user]);// eslint-disable-line
  // workaround to avoid nested "a" elements
  const history = useHistory();

  const localLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('user');
    localStorage.removeItem('_online_');
    const lock = new Auth0Lock(
      AUTH0_CLIENT_ID,
      AUTH0_DOMAIN
    );

    lock.logout({ returnTo: AUTH0_LOGOUT_URL });
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })
  const mobileMenu = () => {
    return (
      <>
        <StyledDropdownButton
          variant="link"
          size="lg"
          title={<i className="fas fa-bars" aria-hidden="true" />}
          id="mobile-dropdown"
        >
          <Dropdown.Item onClick={() => history.push('/therapien')} eventKey="therapien">
            {i18next.t('header.patientsNav')}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => history.push('/appointments')} eventKey="appointments">
            {i18next.t('header.appointments')}
          </Dropdown.Item>
          {notAllowedFeature.indexOf('CHAT')<0 ? <Dropdown.Item onClick={() => history.push('/messages')} eventKey="messages">
            <span id="notificationTextBold" >{i18next.t('header.messages')}</span> <span id="notificationBadge" className='notification-dot-big ml-2 d-none'>1</span>
          </Dropdown.Item> : null}
          <Dropdown.Item onClick={() => setModal('contact')} eventKey="contact">
            {i18next.t('header.contactNav')}
          </Dropdown.Item>
          {isAdminUser ?
            <Dropdown.Item onClick={() => history.push('/admin')} eventKey="admin">
              {i18next.t('header.adminNav')}  </Dropdown.Item> : null}
          <Dropdown.Item onClick={() => history.push('/account')} eventKey="account">
            {i18next.t('header.myAccountNav')}
          </Dropdown.Item>
          <Dropdown.Item onClick={localLogout} eventKey="logout">
            {i18next.t('header.logoutNav')}
          </Dropdown.Item>
        </StyledDropdownButton>);
      </>)
  };
  const webMenu = () => {
    return (<>
      <NavLink to="/therapien">{i18next.t('header.patientsNav')}</NavLink>
      <NavLink to="/appointments">{i18next.t('header.appointments')}</NavLink>
      {notAllowedFeature.indexOf('CHAT')<0 ?
      <NavLink to="/messages"><span id="notificationTextBold" >{i18next.t('header.messages')}</span> <span id="notificationBadge" className='notification-dot-big ml-2 d-none'>1</span></NavLink>
      :null}
      <a href="/" onClick={(e: SyntheticEvent) => {
        e.preventDefault();
        setModal('contact');
      }}> {i18next.t('header.contactNav')}</a>
      {isAdminUser ? <NavLink to="/admin">{i18next.t('header.adminNav')}</NavLink> : null}
      <StyledDropdownButton
        variant="link"
        size="lg"
        title={<i className="fa fa-user-circle" aria-hidden="true" /> || i18next.t('header.accountNav')}
        id="logout-dropdown"
      >
        <Dropdown.Item onClick={() => history.push('/account')} eventKey="account">
          {i18next.t('header.myAccountNav')}
        </Dropdown.Item>
        <Dropdown.Item onClick={localLogout} eventKey="logout">
          {i18next.t('header.logoutNav')}
        </Dropdown.Item>
      </StyledDropdownButton>
    </>)
  }

  return (
    <Nav>
      <Link className="logo" to="/">
        {topNavIcon}
      </Link>
      <div>
        {isMobile ? mobileMenu() : webMenu()}
      </div>

      <StyledModal show={modal === 'contact'} onHide={() => setModal('')}>
        <Modal.Header closeButton>
          <Modal.Title>{i18next.t('header.contactHeader')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p dangerouslySetInnerHTML={{ __html: i18next.t('header.contactModalBody') }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" onClick={() => setModal('')}>
            {i18next.t('generic.close')}
          </Button>
        </Modal.Footer>
      </StyledModal>
    </Nav>
  );
};

export default Header;
