import React, { memo, useState, useEffect } from 'react';
import styled from 'styled-components';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import { Theme } from '../ThemeProvider/useTheme/useTheme';
import { Container, Badge, Button, Row, Col, Popover, Card, OverlayTrigger } from 'react-bootstrap';
import ConfirmationModal from '../../components/ConfirmationModal';
import i18next from 'i18next';
import SaveAndPublishModal from './SaveAndPublishModal';
import moment from 'moment';
import 'moment/locale/de';
import TraininPlanForm from './TraininPlanForm';
import { isEmpty, isNaN, isString } from 'lodash';
import { Prompt } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div`
  color: inherit;
  margin: 2rem;

  .btn-secondary {
    border: 2px solid ${(p) => (p.theme.mode === Theme.FITNESS ? '#1B7B7C' : '#ff695f')};
    color: ${(p) => (p.theme.mode === Theme.FITNESS ? '#1B7B7C' : '#ff695f')};
  }
`;

const Content = styled.div`
  background-color: #ffffff;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
`;

const TopNavBar = styled.div`
  padding: 0.8rem 1.6rem;
  background-color: #002b4b;
  color: #ffffff;
  font-weight: 600;
  font-size: 1.8rem;
`;

const whiteColor = {
  color: 'white',
};

const BackButton = styled(Button)`
  border: 2px solid #ffffff !important;
  color: #ffffff;
  background-color: #122b4b !important;
  text-align: center;
  font-size: 1.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 4px;
  padding-right: 0.4rem;
  padding-left: 0.6rem;
  .svg-inline--fa {
    margin-right: 1rem;
  }
`;

const TrainingPlanDetails = ({
  patient,
  training,
  unpublishTrainingPlan,
  publishTrainingPlan,
  closeButtonNavigateTo,
  saveAsDraftTrainingPlan,
  updateTrainingPlan,
  saveTemplate
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [errorDetails, setErrorDetails] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const message = i18next.t('trainingPlan.unSavedWarning');
  const history = useHistory();
  useEffect(() => {
    (async function () {
      if (await isTrainingValid(training.trainings)) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    })();
  }, [training]); // eslint-disable-line
  const unpublishTraining = async () => {
    setShowConfirmModal(false);
    unpublishTrainingPlan(training['_id']);
  };

  const saveAsDraft = () => {
    setShowSaveModal(false);
    saveAsDraftTrainingPlan()
    setDirty(false);
  };
  const saveTrainingTemplate = async (isGlobalTemplate, templateName) => {
    saveTemplate(isGlobalTemplate, templateName);
  }
  const saveTraining = async (date, isGlobalTemplate, templateName) => {
    setShowSaveModal(false);
    publishTrainingPlan(date, isGlobalTemplate, templateName);
    setDirty(false);
  };

  useEffect(() => {
    window.onbeforeunload = isDirty && (() => message);

    return () => {
      window.onbeforeunload = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  const updateTrainings = async (trainingDetails, templateTrainingList = []) => {
    if (templateTrainingList && templateTrainingList.length > 0) {
      templateTrainingList.forEach(element => {
        trainingDetails.trainings.push(element);
      });
    }
    if (await isTrainingValid(trainingDetails.trainings)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }

    trainingDetails.trainings.forEach((element) => {
      element.exercise.forEach((item) => {
        switch (item.exerciseType) {
          case 'endurance':
            item.set = undefined;
            item.repetition = undefined;
            item.isDynamic = false;
            break;
          case 'relaxation':
            item.unit = undefined;
            item.set = undefined;
            item.repetition = undefined;
            item.time = undefined;
            break;
          case 'static':
            item.isDynamic = false;
            break;
        }
        switch (item.unit) {
          case 'sec':
            item.repetition = undefined;
            break
          case 'min':
            item.repetition = undefined;
            break;
          case 'reps':
            item.isDynamic = true;
            item.time = undefined;
            break;
        }
      });
    });
    updateTrainingPlan(trainingDetails);
    setDirty(true);
  };
  const popover = (
    <Popover id="popover-basic" key={errorDetails.length}>
      <Card>
        <Card.Body className="font-15">
          <p dangerouslySetInnerHTML={{ __html: i18next.t('trainingPlan.trainingPlanGenericError') }} />
          <p dangerouslySetInnerHTML={{ __html: errorDetails }} />
        </Card.Body>
      </Card>
    </Popover>
  );
  const isTrainingValid = async (trainings) => {
    let count = 0;
    let ed = '';
    let index = 0;
    trainings.forEach((element) => {
      index++;
      let internalCount = 0;
      let trainingName = element.name;
      if (!element.name || !isString(element.name)) {
        internalCount++;
      }
      if (!element.workoutsPerWeek || !element.workoutPause) {
        internalCount++;
      }
      if (
        isNaN(element.workoutsPerWeek) ||
        parseInt(element.workoutsPerWeek) < 1 ||
        parseInt(element.workoutsPerWeek) > 7 ||
        isNaN(element.workoutPause) ||
        parseInt(element.workoutPause) < 10 ||
        parseInt(element.workoutPause) > 180
      ) {
        internalCount++;
      }

      if (isEmpty(element.exercise)) {
        internalCount++;
      } else {
        // Exercise validation
        element.exercise.forEach((item) => {
          if (!item.name || !isString(item.name)) {
            internalCount++;
          }

          if (!item.description || !isString(item.description) || item.description === "<p><br></p>") {
            internalCount++;
          }

          if (item.exerciseType !== 'relaxation') {
            if ((item.exerciseType !== 'endurance') && (isNaN(item.sets) || item.sets < 1)) {
              internalCount++;
            }
            if ((isNaN(item.repetition) || item.repetition < 1) && item.isDynamic) {
              internalCount++;
            }

            if ((isNaN(item.time) || item.time < 1) && !item.isDynamic) {
              internalCount++;
            }

            if (item.exerciseType === 'endurance' && item.time === undefined) {
              internalCount++;
            }
            switch (item.unit) {
              case 'sec':
                if (item.time === undefined || item.time <= 0) {
                  internalCount++;
                }
                break;
              case 'min':
                if (item.time === undefined || item.time <= 0) {
                  internalCount++;
                }
                break;
              case 'reps':
                if (item.repetition === undefined || item.repetition <= 0) {
                  internalCount++;
                }
                break;
            }
          }
        });
      }
      if (internalCount > 0) {
        trainingName = trainingName ? trainingName : i18next.t('trainingPlan.tabLable') + ' ' + index;
        ed = ed + '<p>' + trainingName + '</p>';
      }
      count = count + internalCount;
    });
    setErrorDetails(ed);
    return count === 0 ? true : false;
  };
 const getTrainingEndDate = (endDate:any) =>{
   if(moment(endDate).isValid() && moment(endDate,'YYYY-MM-DD').isAfter(moment())){
     return moment(endDate,'YYYY-MM-DD').format('DD-MM-YYYY');
   }else{
     return '';
   }

 }
  return (
    <Container>
      <ConfirmationModal
        show={showConfirmModal}
        title={i18next.t('trainingPlan.unpublish.title')}
        body={
          <>
            <p>{i18next.t('trainingPlan.unpublish.body')}</p>
          </>
        }
        buttonPositive={i18next.t('trainingPlan.unpublish.button')}
        buttonNegative={i18next.t('generic.cancel')}
        onPositive={unpublishTraining}
        onNegative={() => setShowConfirmModal(false)}
      />
      {showSaveModal? 
      <SaveAndPublishModal show={showSaveModal} onPositive={saveTraining} onNegative={() => setShowSaveModal(false)}
        initialValues={{ date:getTrainingEndDate(training.endDate), templateName: '', isGlobalTemplate: false }} />: null}
      <Wrapper>
        <TopNavBar>
          <Row>
          <Col md={2} xs={2} lg={1} className="pr-0"> 
          <BackButton onClick={() => history.push(`${closeButtonNavigateTo}`)}>
                <i className="fas fa-angle-left" />
                <span className="hidden-xs">{i18next.t('generic.back')}</span>
              </BackButton>
          </Col>
            <Col md={6} xs={10} lg={6} className="pr-0"> 
           
              <span className="hidden-xs">{i18next.t('trainingPlan.trainingPlanFor')} </span>
              <span className="mr-2">{
                ((patient.firstName + ' ' + patient.lastName).length>23)?
                (patient.firstName + ' ' + patient.lastName).substring(0, 20)+'...':(patient.firstName + ' ' + patient.lastName)
              }</span>
              {training.status === 'UNPUBLISHED' || training.status === 'DRAFT' ? (<Badge variant="warning" className="" style={whiteColor}>
                {i18next.t(`trainingPlan.status.${training.status}`)}
              </Badge>) : (<Badge variant="success" className="" style={whiteColor}>
                {i18next.t('trainingPlan.publishUntil')} {moment.parseZone(training.endDate).format('DD/MM/YYYY')}
              </Badge>)}
            </Col>
            <Col md={4} xs={12} lg={5} className="pl-0 pr-1">
              {training.status === 'UNPUBLISHED' || training.status === 'DRAFT' ? (
                <>
                  {!isValid ? <OverlayTrigger transition={true} placement="bottom" overlay={popover}><PrimaryButton
                    className="float-right disabled"
                    style={{ cursor: 'not-allowed' }}
                  >
                    {i18next.t('trainingPlan.saveAndPublish')}
                  </PrimaryButton></OverlayTrigger>
                    : <PrimaryButton
                      className="float-right"
                      disabled={!isValid}
                      onClick={() => {
                        setShowSaveModal(true);
                      }}
                    >
                      {i18next.t('trainingPlan.saveAndPublish')}
                    </PrimaryButton>}


                  <SecondaryButton
                    className="mr-3 float-right"
                    disabled={!isValid}
                    onClick={saveAsDraft}
                  >
                    {i18next.t('trainingPlan.saveAsDraft')}
                  </SecondaryButton>


                </>
              )
                : (
                  <>
                    {!isValid ? <OverlayTrigger transition={true} placement="bottom" overlay={popover}><PrimaryButton
                      className="float-right disabled"
                      style={{ cursor: 'not-allowed' }}
                    >
                      {i18next.t('trainingPlan.saveAndPublish')}
                    </PrimaryButton></OverlayTrigger>
                      : <PrimaryButton
                        className="float-right"
                        disabled={!isValid}
                        onClick={() => {
                          setShowSaveModal(true);
                        }}
                      >
                        {i18next.t('trainingPlan.saveAndPublish')}
                      </PrimaryButton>}

                    <SecondaryButton
                      className="mr-3 float-right"
                      onClick={() => {
                        setShowConfirmModal(true);
                      }}
                    >
                      {i18next.t('trainingPlan.unpublish.button')}
                    </SecondaryButton>

                  </>
                )}
            </Col>
          </Row>

        </TopNavBar>
        <Content>
          <TraininPlanForm training={training} updateTrainings={updateTrainings} saveTrainingTemplate={saveTrainingTemplate} />
        </Content>
      </Wrapper>
      <Prompt when={isDirty} message={message}></Prompt>
    </Container >
  );
};

export default memo(TrainingPlanDetails);
