import styled from 'styled-components';
import Form from 'react-bootstrap/Form';

const FormGroup = styled(Form.Group)`
  margin-bottom: 1.5rem;
  padding: 0;
  .form-label {
    font-size: 1.2rem;
    display: block;
  }
  .form-control {
    border-radius: 2px;
  }
  .form-control:disabled {
    background-color: #f8f9fa;
  }
`;

export default FormGroup;
