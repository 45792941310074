import React, { memo, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import i18next from 'i18next';
import { fetchFromRestAPI } from '../../util/api';
import { useParams } from 'react-router-dom';
import ConfirmationModal from '../ConfirmationModal';
import { emitNotification } from '../Notification';

const Wrapper = styled.div`
    background: #ffffff;
    width : 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content : center;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content : center;
    align-items: center;
`;


const AcceptAppointment = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const { appointmentId } = useParams<{ appointmentId: string }>();
    const handleAcceptAppointment = async () => {
        setIsLoading(true);
        await fetchFromRestAPI(`/api/v1/appointment/public/therapist/confirm/${appointmentId}`, {
            method: 'POST'
        })
            .then((response) => {
                if (response && !response.error) {
                    history.replace('/');
                } else {
                    emitNotification(i18next.t('errorMessageTemplate.genericMsg'), {
                        type: 'error',
                        position: 'top-right',
                    });
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.error('Error accept appointment', err);
                setIsLoading(false);
                window.close();
            });
    };
    return (
        <Wrapper>
            <Content>
                <ConfirmationModal
                    disableButton={isLoading}
                    show={true}
                    title={i18next.t('createAppointmentForm.acceptModal.title')}
                    body={
                        <>
                            <p>{i18next.t('createAppointmentForm.acceptModal.body')}</p>
                        </>
                    }
                    buttonPositive={i18next.t('createAppointmentForm.labels.accept')}
                    buttonNegative={i18next.t('generic.cancel')}
                    onPositive={handleAcceptAppointment}
                    onNegative={() => window.close()}
                />
            </Content>
        </Wrapper>
    );
};

export default memo(AcceptAppointment);
