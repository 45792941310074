import React, { FC } from 'react';
import { css } from '@emotion/core';
import { useQuery } from 'react-query';
import { VideoProvider } from '../components/VideoProvider';
import generateConnectionOptions from '../util/generateConnectionOptions/generateConnectionOptions';
import GroupVideoCall from '../components/groupRoom/GroupVideoCall';
import { fetchFromRestAPI } from '../util/api';
import { useHistory, useParams } from 'react-router-dom';
import { BarLoader } from 'react-spinners';

const settings = {
  bandwidthProfileMode: 'predicted',
  mode: 'collaboration',
  maxTracks: 10,
};

const override = css`
  display: block;
  margin: 150px auto 0 auto;
  border-color: red;
`;

const GroupCallContainer: FC = () => {
  const history = useHistory();
  const { code } = useParams<{ code: string }>();
  const { data: room } = useQuery(code && `/api/v1/group-rooms/${code}`, async () => {
    if(code.length === 4){
      const response = await fetchFromRestAPI(`/api/v1/group-rooms/${code}`);
      if (response && response.error) {
        history.push(`/404`);
      } else {
        return response;
      }
    } else {
      history.push(`/404`);
    }
  });

  // TODO better loading indicator
  if (!room) {
    return <BarLoader css={override} color={'#ff695f'} loading={!room} />;
  }

  const connectionOptions = generateConnectionOptions(settings);

  return (
    <VideoProvider
      options={connectionOptions}
      isP2P={room?.type === 'P2P'}
      pinRequired={room?.pinRequired}
      onError={(err) => console.error(err)}
    >
      <GroupVideoCall />
    </VideoProvider>
  );
};

export default GroupCallContainer;
