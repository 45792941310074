import { useEffect, useState } from 'react';
import { RemoteParticipant } from 'twilio-video';
// import useDominantSpeaker from '../useDominantSpeaker/useDominantSpeaker';
import useVideoContext from '../useVideoContext/useVideoContext';

export default function useParticipants() {
  const { room } = useVideoContext();
  // const dominantSpeaker = useDominantSpeaker();
  const [participants, setParticipants] = useState(
    room && room.participants ? Array.from(room.participants.values()) : []
  );

  // When the dominant speaker changes, they are moved to the front of the participants array.
  // This means that the most recent dominant speakers will always be near the top of the
  // ParticipantStrip component.
  // useEffect(() => {
  //   if (dominantSpeaker) {
  //     setParticipants((prevParticipants) => [
  //       dominantSpeaker,
  //       ...prevParticipants.filter((participant) => participant !== dominantSpeaker),
  //     ]);
  //   }
  // }, [dominantSpeaker]);

  useEffect(() => {
    const participantConnected = (participant: RemoteParticipant) =>
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    const participantDisconnected = (participant: RemoteParticipant) =>
      setParticipants((prevParticipants) => prevParticipants.filter((p) => p !== participant));

    if (!room.listeners('participantConnected').filter((f) => f.name === 'participantConnected').length) {
      room.on('participantConnected', participantConnected);
    }

    if (!room.listeners('participantDisconnected').filter((f) => f.name === 'participantDisconnected').length) {
      room.on('participantDisconnected', participantDisconnected);
    }

    return () => {
      if (room.listeners('participantConnected').filter((f) => f.name === 'participantConnected').length) {
        room.off('participantConnected', participantConnected);
      }

      if (room.listeners('participantDisconnected').filter((f) => f.name === 'participantDisconnected').length) {
        room.off('participantDisconnected', participantDisconnected);
      }
    };
  }, [room]);

  return participants;
}
