import i18next from 'i18next';
import { find } from 'lodash';
import 'moment/locale/de';
import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Row } from 'react-bootstrap';
import styled from 'styled-components';
const xType = 'datetime' as 'datetime';

const TitleDiv = styled.div`
  padding-left: 20px;
  padding-bottom: 5px;
  h3 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }
  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    line-height: 21px;
    color: #707089;
    margin-bottom: 5px;
  }
  span {
    color: #FF695F;
  }
`;

const GraphRow = styled(Row)`
  .mixed-chart {
    width: 500px;
  }
`;

const initData = {
  options: {
    chart: {
      id: 'Questionnaire-Graph',
      height: 400,
      width: 500,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      defaultLocale: 'de',
      locales: []
    },
    colors: ['#ff695f'],
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        if (val !=null && val >= 0) return val;
      },
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
      },
    },
    xaxis: {
      type: xType,
      min:undefined,
      max:undefined
    },
    lables:[],
    yaxis: {
      min: 0,
      max: 10
    },
    legend: {
      show: false,
    },
  },
  series: [],
  question: {
    title: 'EFFORT',
    content: '',
  },
  lowKey: "Low",
  highKey: "High"

};

const Answer = ({ answer }) => {
//  initData.options.xaxis.min=answer.seriesArray[0][0];
 // initData.options.xaxis.max=answer.seriesArray[answer.seriesArray.length-1][0];

  initData.options.chart.locales=[{
    name: 'de',
    options: {
      months: i18next.t('answerAndActivity.Months').split(','),
      shortMonths: i18next.t('answerAndActivity.ShortMonths').split(','),
    }
  },
  {
    name: 'en',
    options: {
      months: i18next.t('answerAndActivity.Months').split(','),
      shortMonths: i18next.t('answerAndActivity.ShortMonths').split(','),
    }
  }]
  const [answerData, setAnswer] = useState(initData);
  const i18nextLng = localStorage.getItem('i18nextLng');
  const userLanguage = i18nextLng && i18nextLng.includes('-') ? i18nextLng.split('-')[0] : i18nextLng;
  initData.options.lables=[];
  answer.seriesArray.forEach((entry) => {
    initData.options.lables.push(moment.utc(entry[0]).locale(userLanguage).format('DD MMM'));
   });
  useEffect(() => {
    if (answer && answer.promData) {
      const questionObj = find(answer.promData.prom, { lang: userLanguage });
      const question = questionObj['question'];
      const tempObj = JSON.parse(JSON.stringify(initData));

      const seriesObj = {
        name: question.title,
        type: 'line',
        data: answer.seriesArray,
      };
      tempObj.series = [];
      tempObj.series.push(seriesObj);
      tempObj.question = question;
      tempObj.highKey = questionObj['highText'];
      tempObj.lowKey = questionObj['lowText'];
      setAnswer(tempObj);
    }
  }, [answer, userLanguage]);

  return (
    <div className="app">
      <GraphRow>
        <TitleDiv>
          <h4>
            <b>{answerData.question.title}</b>
          </h4>
          <p>{answerData.question.content}</p>
          <span>{`0 - ${answerData.lowKey}`}, {`10 - ${answerData.highKey}`}</span>
        </TitleDiv>
        <div className="mixed-chart">
          <Chart options={answerData.options} series={answerData.series} type="line" />
        </div>
      </GraphRow>
    </div>
  );
};

export default memo(Answer);
