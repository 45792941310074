import { LocalAudioTrack } from 'twilio-video';
import { useCallback, useEffect } from 'react';
import useIsTrackEnabled from '../useIsTrackEnabled/useIsTrackEnabled';
import useVideoContext from '../useVideoContext/useVideoContext';
import useParticipantSentMessage from '../../components/VideoProvider/useParticipantSentMessage/useParticipantSentMessage';
import { CallMessagingAction } from '../useDataTrack/useDataTrack';

export default function useLocalAudioToggle() {
  const { localTracks } = useVideoContext();
  const audioTrack = localTracks.find((track) => track.kind === 'audio') as LocalAudioTrack;
  const isEnabled = useIsTrackEnabled(audioTrack);
  const participantSentMessage = useParticipantSentMessage();

  useEffect(() => {
    participantSentMessage.on(CallMessagingAction.ACTION_MUTE_ALL, () => {
      console.log('Global mute requested.');
      if (audioTrack && audioTrack.isEnabled) {
        audioTrack.disable();
      }
    });
    participantSentMessage.on(CallMessagingAction.ACTION_UNMUTE_ALL, () => {
      console.log('Global unmute requested.');
      if (audioTrack && audioTrack.isEnabled) {
        audioTrack.enable();
      }
    });

    return () => {
      participantSentMessage.off(CallMessagingAction.ACTION_UNMUTE_ALL);
      participantSentMessage.off(CallMessagingAction.ACTION_MUTE_ALL);
    };
  }, [audioTrack, participantSentMessage]);

  const toggleAudioEnabled = useCallback(() => {
    if (audioTrack) {
      audioTrack.isEnabled ? audioTrack.disable() : audioTrack.enable();
    }
  }, [audioTrack]);

  return [isEnabled, toggleAudioEnabled] as const;
}
