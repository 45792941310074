import { useCallback } from 'react';
import useDataTrack, { CallMessagingAction } from '../useDataTrack/useDataTrack';

export default function useMuteAllAudio() {
  const [sendMessage] = useDataTrack();

  const muteAll = useCallback(() => {
    sendMessage({
      action: CallMessagingAction.ACTION_MUTE_ALL,
    });
  }, [sendMessage]);

  return [muteAll] as const;
}
