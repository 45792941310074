import i18next from "i18next";
import { filter, isEmpty, uniq } from "lodash";
import React, { FC, memo, useEffect, useState } from "react";
import Avatar from "react-avatar";
import { Col } from "react-bootstrap";
import { box } from 'tweetnacl';
import { decrypt } from '../../util/crypto';
import { decodeBase64 } from 'tweetnacl-util';
import { formatDateForChat } from "../../util/dateTimeUtils";
import moment from "moment";
import { displayLocalLastMessage } from "../../util";
import { useAuth0 } from "../../util/auth0";
type Props = {
    therapies: any,
    newMessage: any,
    tKeyPair: any,
    selectedTherapy: any;
    handleSelectTherapy: (therapy) => void;
    refreshChatHead: () => void;
    resetNewChatMessage: () => void;
};
const ChatHeadComponent: FC<Props> = ({ therapies, newMessage, selectedTherapy, handleSelectTherapy, tKeyPair, refreshChatHead, resetNewChatMessage }) => {
    const { user } = useAuth0();
    let onlineUsers = localStorage.getItem('_online_') ? localStorage.getItem('_online_').split(',') : [];
    const [newMessageList, setNewMessageList] = useState([]);
    const [therapyList, setTherapyList] = useState([]);
    const [selectedTherapyId, setSelectedTherapyId] = useState(selectedTherapy ? selectedTherapy._id : '');
    const localtz = moment.tz.guess();
    const searchByName = (searchKey) => {
        if (searchKey && searchKey.length >= 2) {
            const newTherapies = filter(therapies, function (o) {
                return o.patient.firstName && o.patient.lastName ? (o.patient.firstName.toLowerCase() + "" + o.patient.lastName.toLowerCase()).indexOf(searchKey.toLowerCase()) >= 0 : false;
            });
            setTherapyList(newTherapies);
        } else {
            setTherapyList(therapies);
        }
    }

    const changeChatHead = (therapy) => {
        if (therapy && therapy._id !== selectedTherapyId) {
            const newArray = newMessageList.filter((id) => id !== therapy.patient._id);
            setNewMessageList(newArray);
            setSelectedTherapyId(therapy._id);
            handleSelectTherapy(therapy);
            resetNewChatMessage();
        }

    }
    useEffect(() => {
        if (newMessage && newMessage.senderId) {
            newMessageList.push(newMessage.senderId);
            let uniqueItems = uniq(newMessageList);
            setNewMessageList(uniqueItems);

            const therapy = therapyList.filter((x: any) => x.patient && x.patient._id === newMessage.senderId);
            if (therapy && therapy.length > 0 && therapy[0].lastMessage) {
                const finalKeyTherpaist = box.before(decodeBase64(therapy[0].lastMessage.patientKey), tKeyPair.secretKey);
                let actualMsg = decrypt(finalKeyTherpaist, newMessage.text);
                displayLocalLastMessage(therapy[0]._id, actualMsg, formatDateForChat(moment.parseZone(newMessage.createdAt).locale(localtz)))
            }
            refreshChatHead();
        }
        // eslint-disable-next-line
    }, [newMessage]);
    useEffect(() => {
        if (therapies && therapies.length > 0) {
            setTherapyList(therapies);
            sortTherapyList(therapies);
        }
        // eslint-disable-next-line
    }, [therapies]);
    const renderLastMessage = (id, msgObject, patientId) => {
        try {
            if (msgObject.patientKey) {
                const finalKeyTherpaist = box.before(decodeBase64(msgObject.patientKey), tKeyPair.secretKey);
                let actualMsg = decrypt(finalKeyTherpaist, msgObject.messages[0].text);
                actualMsg = actualMsg.replace(/<[^>]*>?/gm, '');
                return (
                    <div id={`lastMessage-${id}`} className={newMessageList.indexOf(patientId) >= 0 ? 'small font-weight-bold' : 'small'}>
                        {actualMsg && actualMsg.length > 20 ? actualMsg.substring(0, 20) + '...' : actualMsg}
                    </div>
                )
            }
        } catch (error) {

        }
        return null;
    }
    const renderDateLabel = (id, msgObject) => {
        try {
            if (msgObject.patientKey) {
                return (
                    <div className='small' id={`lastMessageDate-${id}`}>
                        {formatDateForChat(moment(moment.utc(msgObject.messages[0].createdAt).toDate()).locale(localtz))}
                    </div>
                )
            }
        } catch (error) {

        }
        return null;
    }
    const renderChatHead = (therapy, index) => {
        const name = therapy.patient.firstName + " " + therapy.patient.lastName;
        return <span key={'chathead-' + therapy.id + index} className={selectedTherapyId === therapy._id ? 'list-group-item list-group-item-action border-0 chat-head-bgcolor chat-head-selected' : 'list-group-item list-group-item-action border-0 chat-head-bgcolor'} onClick={() => { changeChatHead(therapy) }}>
            <div className="d-flex align-items-start">
                <Avatar name={name} size="40" color="#707089" className="rounded-circle mr-1" round={true} >
                </Avatar>
                {selectedTherapyId !== therapy._id && newMessageList.indexOf(therapy.patient._id) >= 0 ? <div className="notification-dot-head"></div> : null}

                <div className="flex-grow-1 ml-3">
                    <span className="font-weight-bold">{name.length > 25 ? name.substring(0, 25) + '...' : name}</span>
                    <span className="float-right">{therapy.lastMessage && therapy.lastMessage.messages && therapy.lastMessage.messages.length > 0 && tKeyPair ? renderDateLabel(therapy._id, therapy.lastMessage) : <div className='small' id={`lastMessageDate-${therapy._id}`}></div>}</span>
                    {therapy.lastMessage && therapy.lastMessage.messages && therapy.lastMessage.messages.length > 0 && tKeyPair ? renderLastMessage(therapy._id, therapy.lastMessage, therapy.patient._id) : <div id={`lastMessage-${therapy._id}`} className='small'></div>}

                </div>
            </div>
        </span>
    }
    const sortTherapyList = (tList: any) => {
        if (isEmpty(selectedTherapy)) {
            let topTherapy = tList.filter((x: any) => x.patient && onlineUsers.indexOf(x.patient._id) >= 0 && x.patient.accountId !== user.sub);
            topTherapy = topTherapy.length === 0 ? tList.filter((x: any) => x.patient && x.lastMessageDate !== null && onlineUsers.indexOf(x.patient._id) < 0 && x.patient.accountId !== user.sub) : topTherapy;
            topTherapy = topTherapy.length === 0 ? tList.filter((x: any) => x.patient && x.lastMessageDate === null && onlineUsers.indexOf(x.patient._id) < 0 && x.patient.accountId !== user.sub) : topTherapy;
            topTherapy = topTherapy.length === 0 ? tList.filter((x: any) => x.patient && x.patient.accountId === user.sub) : topTherapy;
            if (topTherapy && topTherapy.length > 0) {
                handleSelectTherapy(topTherapy[0]);
                setSelectedTherapyId(topTherapy[0]._id);
            }
        }
    }
    const renderOnlineUser = () => {
        const topTherapy = therapyList.filter((x: any) => x.patient && onlineUsers.indexOf(x.patient._id) >= 0 && x.patient.accountId !== user.sub);
        if (topTherapy && topTherapy.length > 0) {
            return (<>
                {topTherapy.map((therapy, index) => {
                    return renderChatHead(therapy, index);
                })}
            </>)
        }
    }
    const renderOfflineUserLastMessage = () => {
        return (<>
            {therapyList.filter((x: any) => x.patient && x.lastMessageDate === null && onlineUsers.indexOf(x.patient._id) < 0 && x.patient.accountId !== user.sub).map((therapy, index) => {
                return renderChatHead(therapy, index);
            })}
        </>)
    }
    const renderOfflineUser = () => {
        const offlineTherapy = therapyList.filter((x: any) => x.patient && x.lastMessageDate !== null && onlineUsers.indexOf(x.patient._id) < 0 && x.patient.accountId !== user.sub);
        if (offlineTherapy && offlineTherapy.length > 0) {
            return (<>
                {offlineTherapy.filter((x: any) => x.patient && x.lastMessageDate !== null && onlineUsers.indexOf(x.patient._id) < 0 && x.patient.accountId !== user.sub).map((therapy, index) => {
                    return renderChatHead(therapy, index);
                })}
            </>)
        }
    }
    const renderTherapistUser = () => {
        return (<>
            {therapyList.filter((x: any) => x.patient && x.patient.accountId === user.sub).map((therapy, index) => {
                return renderChatHead(therapy, index);
            })}
        </>)
    }
    return (
        <div className="col-5 col-lg-4 col-xl-3 col-md-5 border-right chat-head-box pr-0" key={!isEmpty(newMessage) ? 'chat-head-' + newMessage.senderId : 'chat-head'}>
            <div className="px-4 d-none d-md-block">
                <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                        <input type="text" className="form-control my-3" placeholder="Search..." onChange={(e) => searchByName(e.target.value)} />
                    </div>
                </div>
            </div>
            {therapyList && therapyList.length === 0 ?
                <><Col md={12} className="loader text-center" key={0}>{i18next.t('chat.noPatientFound')}</Col></>
                : <div className="chat-head-scroll">
                    {renderOnlineUser()}
                    {renderOfflineUser()}
                    {renderOfflineUserLastMessage()}
                    {renderTherapistUser()}
                    <br /><br /><br /><br />
                </div>}

            <hr className="d-block d-lg-none mt-1 mb-0" />
        </div>
    )
}

export default memo(ChatHeadComponent);