let subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : process.env.REACT_APP_ENVIRONMENT;
let logoutURL = process.env.REACT_APP_AUTH0_LOGOUT_URL || window.injectedEnv?.REACT_APP_AUTH0_LOGOUT_URL;
let registratonURL = process.env.REACT_APP_AUTH0_REGISTRATION_START || window.injectedEnv?.REACT_APP_AUTH0_REGISTRATION_START;
let redirectURL = process.env.REACT_APP_AUTH0_REDIRECTURL || window.injectedEnv?.REACT_APP_AUTH0_REDIRECTURL;
let i18URL = process.env.REACT_APP_I18NEXT_URL || window.injectedEnv?.REACT_APP_I18NEXT_URL;
let websocketURL= process.env.REACT_APP_WEBSOCKET_URL || window.injectedEnv?.REACT_APP_WEBSOCKET_URL;

if (subdomain) {
  logoutURL = logoutURL.replace('subdomain', subdomain);
  registratonURL = registratonURL.replace('subdomain', subdomain);
  redirectURL = redirectURL.replace('subdomain', subdomain);
  i18URL = i18URL.replace('subdomain', subdomain);
  websocketURL=websocketURL.replace('subdomain', subdomain);
}

export const AUTH0_LOGOUT_URL = logoutURL;
export const AUTH0_REGISTRATION_START = registratonURL;
export const AUTH0_REDIRECTURL = redirectURL;
export const I18NEXT_URL = i18URL;
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || window.injectedEnv?.REACT_APP_AUTH0_CLIENT_ID;
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE || window.injectedEnv?.REACT_APP_AUTH0_AUDIENCE;
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || window.injectedEnv?.REACT_APP_AUTH0_DOMAIN;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || window.injectedEnv?.REACT_APP_SENTRY_DSN;
export const HOME_URL = process.env.REACT_APP_HOME_URL || window.injectedEnv?.REACT_APP_HOME_URL;
export const I18NEXT_DEBUG = process.env.REACT_APP_I18NEXT_DEBUG || window.injectedEnv?.REACT_APP_I18NEXT_DEBUG;
export const REACT_APP_WEBSOCKET_URL = websocketURL;
export const REACT_APP_AUTH0_SECRET_INDEX_KEY = process.env.REACT_APP_AUTH0_SECRET_INDEX_KEY || window.injectedEnv?.REACT_APP_AUTH0_SECRET_INDEX_KEY