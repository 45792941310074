import React, { memo, useEffect, useState } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import styled from 'styled-components';
import MyAccountHistoryContainer from '../containers/MyAccountHistoryContainer';
import MyAccountOverviewContainer from '../containers/MyAccountOverviewContainer';
import i18next from 'i18next';
import AppointmentHistoryContainer from '../containers/AppointmentHistoryContainer';
import AvailabilityContainer from '../containers/AvailabilityContainer';
import { useAuth0 } from '../util/auth0';
import moment from 'moment';
import { fetchTherapistPlan, fetchUserAccountRemote } from '../util/UserAccount';
import BillingComponent from './myaccount/BillingComponent';

const Wrapper = styled.div`
  background-color: #ffffff;
  padding: 2rem;
  h1 {
    font-weight: bold;
    font-size: 4rem;
  }
`;

const MyAccount = () => {
  const { user, getIdTokenClaims } = useAuth0();
  const [isFetching, setIsFetching] = useState(true);
  const [plans, setPlans] = useState([]);
  const [userProfile, setUserProfile] = useState({
    firstName: '',
    lastName: '',
    street: '',
    postal: '',
    approved: false,
    birthDate: new Date(),
    telephone: '',
    date: '',
    month: '',
    year: '',
    country: '',
    isCallAllowed: true,
    plans: null
  });
  const _displayAvalabityTab = () => {
    return (
      <Tab eventKey="availabilityTherapist" title={i18next.t('myAccountOverview.tabAvailability')}>
        <AvailabilityContainer userProfile={userProfile} />
      </Tab>)
  }
  useEffect(() => {
    const fetchAccountData = async () => {
      const token = await getIdTokenClaims();
      fetchTherapistPlan().then((response) => {
        if (response && response.length > 0) {
          setPlans(response);
        }
      });
      const userProfileResponse = await fetchUserAccountRemote(token);
      if (userProfileResponse && userProfileResponse.birthDate) {
        userProfileResponse.date = moment(userProfileResponse.birthDate).date();
        userProfileResponse.month = moment(userProfileResponse.birthDate).month() + 1;
        userProfileResponse.year = moment(userProfileResponse.birthDate).year();
      }
      setUserProfile(userProfileResponse);
      setIsFetching(false);
    };
    if (!user) return;
    fetchAccountData();
  }, [user, getIdTokenClaims]);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const selectedTab = params.get('billing')? 'billing' : 'overview';
  return (
    <Wrapper>
      <h1>{i18next.t('myAccountOverview.myAccount')}</h1>
      <Tabs defaultActiveKey={selectedTab} id='myAccount'>
        <Tab eventKey="overview" title={i18next.t('myAccountOverview.tabAccount')}>
          {isFetching ? (
            <div>{i18next.t('generic.loading')}</div>
          ) : (
            <MyAccountOverviewContainer userProfile={userProfile} plans={plans} />
          )}
        </Tab>
        <Tab eventKey="trainingHistory" title={i18next.t('myAccountOverview.tabTrainingshistory')}>
          <MyAccountHistoryContainer />
        </Tab>
        <Tab eventKey="appointmentHistory" title={i18next.t('myAccountOverview.tabAppointmenthistory')}>
          <AppointmentHistoryContainer />
        </Tab>
        {isFetching ? (<div>{i18next.t('generic.loading')}</div>) : (
          _displayAvalabityTab()
        )}
        <Tab eventKey="billing" title={i18next.t('myAccountOverview.tabBillingLabel')}>
          {isFetching ? (<div>{i18next.t('generic.loading')}</div>) : (
            <BillingComponent userProfile={userProfile} />
          )}
        </Tab>
      </Tabs>
    </Wrapper>
  );
};

export default memo(MyAccount);


