import { range } from 'lodash';
import React, { ChangeEvent, FC, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import { HOME_URL } from '../../config';
import { fetchFromRestAPI } from '../../util/api';

const Wrapper = styled.main`
  background-color: #002b4b;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
  p {
    line-height: 1.1;
    font-size: 16px;

    a {
      color: #fff;
      text-decoration: underline;
    }
  }

  h1 {
    display: block;
    width: 100%;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 32px;
    line-height: 1;
    margin: 60px auto 20px auto;
    max-width: 800px;
  }

  h2 {
    display: block;
    width: 100%;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    line-height: 1;
    max-width: 800px;
  }

  .btn-secondary {
    border: 2px solid #ff695f;
    color: #ff695f;
    margin-bottom: 40px;
    font-weight: bold;
  }

  .rating {
    font-size: 23px;
    color: #ff695f;
    opacity: 0.8;
    cursor: pointer;
    &.rating-given {
      opacity: 1;
    }
    span,
    strong {
      padding: 0 7px;
    }
  }

  small {
    font-weight: bold;
  }

  textarea {
    width: 350px;
    margin-bottom: 10px;
  }

  @media (max-width: 750px), (max-device-width: 815px) and (-webkit-min-device-pixel-ratio: 3) {
    h1 {
      font-size: 1.8rem;
    }
  }
`;

const ThumbButton = styled.button`
  background: transparent;
  font-size: 22px;
  border: 0;
  display: inline-flex;
  margin: 5px;

  &:focus {
    outline: 0;
  }
`;

const LeftGroupRoom: FC = () => {
  const history = useHistory();
  const { code, isTrainer, pin } = useParams<{ code: string; isTrainer: string; pin: string }>();
  const [rating, setRating] = useState(4);
  const [ratingGiven, setRatingGiven] = useState(false);
  const [message, setMessage] = useState('');
  const [improve, setImprove] = useState('');
  const [toImprove, setToImprove] = useState(false);
  const [messageSent, setMessageSent] = useState(false);

  const goBackURL = pin ? `/${code}/${pin}` : `/${code}`;

  return (
    <Wrapper>
      <p>
        Schön, dass Du da warst! Du hast den Raum verlassen.
        <br />
        Nur ein Versehen? <a href={goBackURL}>Raum erneut betreten</a>
      </p>
      {messageSent ? (
        <h1>
          Vielen Dank,
          <br />
          wir haben Ihr Feedback erhalten
        </h1>
      ) : (
        <>
          <h1>Wie hat Dir die Stunde gefallen?</h1>
          <div className={`rating${ratingGiven ? ' rating-given' : ''}`}>
            {range(0, 4).map((index) => (
              <span
                onClick={() => {
                  setRating(index);
                  setRatingGiven(!ratingGiven);
                }}
                onMouseEnter={() => {
                  if (!ratingGiven) setRating(index);
                }}
                key={index}
              >
                {index > rating ? (
                  <strong>
                    <i className="far fa-heart" />
                  </strong>
                ) : (
                  <span>
                    <i className="fas fa-heart" />
                  </span>
                )}
              </span>
            ))}
          </div>
          {isTrainer === '0' ? (
            <>
              <small>Hinterlass eine Nachricht für Deine:n TrainerIn</small>
              <Form.Control
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  setMessage(e.target.value);
                }}
                value={message}
                as="textarea"
                rows={3}
              />
            </>
          ) : (
            <></>
          )}
          <p>Warst Du mit der Qualität des Videoanrufs zufrieden?</p>
          <div>
            <ThumbButton onClick={() => setToImprove(false)}>
              <span role="img" aria-label="Daumen hoch">
                👍
              </span>
            </ThumbButton>
            <ThumbButton onClick={() => setToImprove(true)}>
              <span role="img" aria-label="Daumen herunter">
                👎
              </span>
            </ThumbButton>
          </div>

          {toImprove ? (
            <>
              <small>Was können wir nächstes Mal besser machen?</small>
              <Form.Control
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  setImprove(e.target.value);
                }}
                value={improve}
                as="textarea"
                rows={3}
              />
            </>
          ) : (
            <></>
          )}
          <Button
            variant="secondary"
            onClick={async () => {
              await fetchFromRestAPI(`/api/v1/feedback`, {
                method: 'POST',
                body: {
                  message,
                  improve,
                  rating,
                  code,
                },
              });
              setMessageSent(true);
              if (isTrainer) {
                history.push(`/therapien`);
              } else {
                history.push(HOME_URL);
              }
            }}
          >
            Feedback abschicken
          </Button>
          <h2>
            Vielen Dank und bis zum nächsten Mal!{' '}
            <span role="img" aria-label="wave">
              👋
            </span>
          </h2>
        </>
      )}
    </Wrapper>
  );
};

export default LeftGroupRoom;
