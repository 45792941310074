import React, { FC } from 'react';
import styled from 'styled-components';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import ParticipantTracks from './ParticipantTracks';

const Container = styled.div`
  height: 1px;
  overflow: hidden;
`;

const ParticipantsAudioTracks: FC = () => {
  const {
    room: { localParticipant },
  } = useVideoContext();
  const participants = useParticipants();

  return (
    <Container>
      <ParticipantTracks participant={localParticipant} onlyAudio />
      {participants.map((participant) => (
        <ParticipantTracks key={`${participant.sid}-audio`} participant={participant} onlyAudio />
      ))}
    </Container>
  );
};

export default ParticipantsAudioTracks;
