import { useFormik } from 'formik';
import React, { memo } from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { useAuth0 } from '../../util/auth0';
import {
  updateUserAccountRemote,
  validateUserForm,
} from '../../util/UserAccount';
import PrimaryButton from '../PrimaryButton';
import i18next from 'i18next';
import { Row, Col } from 'react-bootstrap';
import { emitNotification } from '../Notification';

const DateInputDiv = styled.div`
  .form-control {
    :not(:last-child) {
      width: 60px;
      margin-right: 4px;
    }
    width: 80px;
  }
  display: flex;
`;

const FormFieldError = styled.div`
  color: #ff695f;
  font-size: 1rem;
`;

const TherapistAccountPage = ({ userProfile, plans }) => {
  const { getAccessTokenClaims } = useAuth0();
  const i18nextLng = localStorage.getItem('i18nextLng');
  const userLanguage = i18nextLng && i18nextLng.includes('-') ? i18nextLng.split('-')[0] : i18nextLng;
  const formik = useFormik({
    initialValues: userProfile,
    enableReinitialize: true,
    validate: validateUserForm,
    onSubmit: async (values, actions) => {
      const token = await getAccessTokenClaims();
      // Manipulate dob value
      values.birthDate = new Date(+values.year, +values.month - 1, +values.date);
      if (values.profilePic) {
        delete values.profilePic;
      }
      const userProfileResponse = await updateUserAccountRemote(token, values);
      if (userProfileResponse && !userProfileResponse.error) {
        emitNotification(i18next.t('errorMessageTemplate.key_profileUpdatedSuccess'), {
          type: 'error',
          position: 'top-right',
        });
      }
    },
  });
  const handleChatAllowed = async (e) => {
    if(e.target.checked){
      formik.setFieldValue('notAllowedFeature',[]);
      await formik.handleChange(e); formik.submitForm();
    }else{
      formik.setFieldValue('notAllowedFeature',['CHAT']);
      await formik.handleChange(e); 
      formik.submitForm();
    }
  };
  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Col md={6} xs={12}>
          <h2>{i18next.t('myAccountOverview.patientContactOptionHeading')}</h2>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>{i18next.t('myAccountOverview.patientContactOptionBody')}</Form.Label>

              <Form.Check
                id="isCallAllowed"
                name="isCallAllowed"
                type="switch"
                checked={formik.values.isCallAllowed}
                onChange={async (e) => { await formik.handleChange(e); formik.submitForm(); }}

                label={' ' + i18next.t('myAccountOverview.patientContactOptionSwitch')} />
              <Form.Check
                id="isChatAllowed"
                name="isChatAllowed"
                type="switch"
                checked={formik.values.notAllowedFeature.indexOf('CHAT')<0}
                onChange={handleChatAllowed}

                label={' ' + i18next.t('myAccountOverview.allowChatLabel')} />
            </Form.Group>

          </Form.Row>
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12}>
          <h2>{i18next.t('myAccountOverview.yourAccountData')}</h2>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>{i18next.t('accountSetup.firstName')}</Form.Label>
              <Form.Control
                defaultValue={formik.values.firstName}
                type="text"
                name="firstName"
                id="firstName"
                disabled={true}
                onChange={formik.handleChange}
              />
              {formik.errors.firstName && (formik.touched.firstName || formik.submitCount > 0) && (
                <FormFieldError>{formik.errors.firstName}</FormFieldError>
              )}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>{i18next.t('accountSetup.lastName')}</Form.Label>
              <Form.Control
                defaultValue={formik.values.lastName}
                type="text"
                name="lastName"
                id="lastName"
                disabled={true}
                onChange={formik.handleChange}
              />
              {formik.errors.lastName && (formik.touched.lastName || formik.submitCount > 0) && (
                <FormFieldError>{formik.errors.lastName}</FormFieldError>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>{i18next.t('accountSetup.street')}</Form.Label>
              <Form.Control
                defaultValue={formik.values.street}
                type="text"
                name="street"
                id="street"
                onChange={formik.handleChange}
              />
              {formik.errors.street && (formik.touched.street || formik.submitCount > 0) && (
                <FormFieldError>{formik.errors.street}</FormFieldError>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>{i18next.t('accountSetup.postal')}</Form.Label>
              <Form.Control
                defaultValue={formik.values.postal}
                type="text"
                name="postal"
                id="postal"
                onChange={formik.handleChange}
              />
              {formik.errors.postal && (formik.touched.postal || formik.submitCount > 0) && (
                <FormFieldError>{formik.errors.postal}</FormFieldError>
              )}
            </Form.Group>
            <Form.Group as={Col} >
              <Form.Label className='form-lable-small'>{i18next.t('accountSetup.city')}</Form.Label>
              <Form.Control
                defaultValue={formik.values.city}
                type="text"
                name="city"
                id="city"
                onChange={formik.handleChange}
              />

              {formik.errors.city && (formik.touched.city || formik.submitCount > 0) && (
                <FormFieldError>{formik.errors.city}</FormFieldError>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>{i18next.t('accountSetup.telephone')}</Form.Label>
              <Form.Control
                defaultValue={formik.values.telephone}
                type="text"
                name="telephone"
                id="telephone"
                onChange={formik.handleChange}
              />
              {formik.errors.telephone && (formik.touched.telephone || formik.submitCount > 0) && (
                <FormFieldError>{formik.errors.telephone}</FormFieldError>
              )}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>{i18next.t('accountSetup.country')}</Form.Label>
              <select className='form-control' defaultValue={formik.values.country} name="country" onChange={formik.handleChange}>
                {
                  i18next.t('accountSetup.countryList').split(',').map((val, i) => {
                    return (
                      <option key={val} value={val}>{val}</option>
                    );
                  })}
              </select>

            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>{i18next.t('accountSetup.dateOfBirth')}</Form.Label>
              <DateInputDiv>
                <Form.Control
                  defaultValue={formik.values.date}
                  name="date"
                  type="text"
                  id="date"
                  placeholder="DD"
                  disabled={true}
                  onChange={formik.handleChange}
                />
                <Form.Control
                  defaultValue={formik.values.month}
                  type="text"
                  name="month"
                  disabled={true}
                  id="month"
                  placeholder="MM"
                  onChange={formik.handleChange}
                />
                <Form.Control
                  defaultValue={formik.values.year}
                  type="text"
                  name="year"
                  id="year"
                  disabled={true}
                  placeholder="YYYY"
                  onChange={formik.handleChange}
                />
              </DateInputDiv>
              {formik.errors.date ? (
                <FormFieldError>{formik.errors.date}</FormFieldError>
              ) : null}

            </Form.Group>
          </Form.Row>
          <Form.Row className="mt-2">
            <Form.Group as={Col}>
              {plans && plans.length > 0 ?
                <>
                  <h3 className='font-weight-bold'> {i18next.t('accountSetup.yourPlan')}</h3>
                  <span>
                    {plans.map((val, i) => {
                      return (
                        <span key={'plans-' + i}>
                          {val._id === formik.values.therapistPlan ?
                            <Form.Label>
                              <span className='font-weight-bold'>
                                {userLanguage === 'de' ? val.planNameDE : val.planNameEN}  &nbsp;</span>
                              <a className='mt-0 pl-0' rel="noopener noreferrer" href={i18next.t('accountSetup.choosePlanLink')} target='_blank'>{i18next.t('accountSetup.choosePlanLinkText')}</a>
                            </Form.Label> : null}
                        </span>
                      );
                    })}
                  </span></>
                : null}
            </Form.Group>
          </Form.Row>
          <Form.Row><Form.Group as={Col}>
            <PrimaryButton type="submit">
              {i18next.t('generic.save')}
            </PrimaryButton>
          </Form.Group>
          </Form.Row>

        </Col>
      </Row>
    </Form>

  );
};

export default memo(TherapistAccountPage);