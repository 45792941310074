import i18next from 'i18next';
import { noop } from 'lodash';
import { FaVideoSlash } from 'react-icons/fa';
import React, { FC, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { LocalVideoTrack } from 'twilio-video';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { fetchFromRestAPI } from '../../util/api';
import { useAuth0 } from '../../util/auth0';
import PrimaryButton from '../PrimaryButton';
import PrivacyModal from '../PrivacyModal';
import SupportModal from '../SupportModal';
import ControlsBar from './Controls/ControlsBar';
import VideoTrack from './VideoTrack';
import { generatePatientRoomURL } from '../../util/urls';
import CopyValueBox from '../CopyValueBox';
import NavigationPrompt from "react-router-navigation-prompt";
import ConfirmationModal from '../ConfirmationModal';

const VideoWrapper = styled.div`
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: auto;
  min-height: calc(60vh);
  max-height: calc(60vh);
  padding: 1rem;
  border-radius: 1rem;
  margin: 1rem 0 1rem 0;

  @media only screen and (max-width: 768px) {
    min-height: calc(50vh);
    max-height: calc(50vh);
  }

  & video {
    position: absolute;
    z-index: 0;
    background-size: 100% 100%;
    top: 0px;
    left: 0px;
    min-width: 100%;
    min-height: 100%;
  }

  position: relative;
  background-color: #595959;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoVideoWrapper = styled.div`
  position: absolute;
  z-index: 0;
  background-size: 100% 100%;
  top: 0px;
  left: 0px;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  max-width: 100rem;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  flex-grow: unset;
  padding: 8px;
`;

const JoinCallButton = styled.div`
  align-self: center;
  padding: 3rem;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 1rem;
  width: 100%;
  text-align: center;
  color: #ffffff;

  @media only screen and (max-width: 768px) {
    Button {
      font-size: 1.3rem;
    }
  }
`;

const WelcomeMessageBox = styled.div`
  color: #ffffff;
  h1 {
    font-size: 3rem;
    font-weight: 700;
  }

  @media only screen and (max-width: 768px) {
    font-size: 1rem;
    h1 {
      font-size: 2rem;
    }
  }
`;

const PinInput = styled.input`
  width: 50%;
  height: auto;

  @media only screen and (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

enum Modal {
  'hidden',
  'privacy',
  'support',
}

const CopyLinkWrapper = styled.div`
  position: absolute;
  display: flex;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0.5rem;
  z-index: 5;
`;

const LocalVideoPreview: FC = () => {
  const { code, pin } = useParams<{ code: string; pin?: string }>();
  const {
    connect,
    isConnecting,
    localTracks,
    isAcquiringLocalTracks,
    isP2P,
    isCallHost,
    pinRequired,
    endCall
  } = useVideoContext();
  const roomState = useRoomState();

  // privacy approval (spec discussed with Stefan)
  // - a therapist has already approved when signing in - no modal shown to a therapist
  // - an anonymous user only needs to approve if he/she logs in the first time into a P2P room
  // - the modal which notifies the user about activating the camera and microphone is not needed
  const { user, loading } = useAuth0();
  const isTherapist = user && !loading;
  const retrievePrivacyAcceptanceRecord = code && !isTherapist && isP2P;
  const { data: privacyAcceptanceRecord, isSuccess } = useQuery(
    retrievePrivacyAcceptanceRecord && `/api/v1/privacy/${code}`,
    () => fetchFromRestAPI(`/api/v1/privacy/${code}`)
  );
  const showPrivacyModal = retrievePrivacyAcceptanceRecord && isSuccess && !privacyAcceptanceRecord?.accepted;
  const [modal, setModal] = useState<Modal>(Modal.hidden);
  const [pinCode, setPinCode] = useState('');
  const [isConnected, setConnected] = useState(false);

  // TODO get rid of useEffect
  useEffect(() => {
    if (showPrivacyModal) {
      setModal(showPrivacyModal ? Modal.privacy : Modal.hidden);
    }
  }, [showPrivacyModal]);

  useEffect(() => {
    if (roomState === "connected") {
      setConnected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomState]);

  const videoTrack = localTracks.find((track) => track.name.includes('camera')) as LocalVideoTrack;

  const joinRoom = () => {
    connect(code, pin ? pin : '');
  };

  const joinRoomCode = () => {
    connect(code, pinCode);
  };

  const endRoom = () => {
    setConnected(false);
    endCall(pin ? pin : '');
  }

  const endRoomOnBack = () => {
    endCall(pin ? pin : '', true);
  }

  return (
    <Wrapper>
      <WelcomeMessageBox>
        <h1>{i18next.t('room.waiting.welcome')}</h1>
        {i18next.t('room.waiting.title1')} <b>{i18next.t('pin.enterRoom')}</b> {i18next.t('room.waiting.title2')}
      </WelcomeMessageBox>
      <VideoWrapper>
        {videoTrack && <VideoTrack track={videoTrack} isLocal />}
        {!videoTrack && (
          <NoVideoWrapper>
            <FaVideoSlash />
          </NoVideoWrapper>
        )}
        <ControlsBar />
        {isTherapist && (
          <CopyLinkWrapper>
            <CopyValueBox value={code} valueCopy={generatePatientRoomURL(code)} compact />
          </CopyLinkWrapper>
        )}
      </VideoWrapper>
      <JoinCallButton>
        {
          roomState === 'disconnected' ? (
            <>
              {pinRequired && !pin && !isTherapist ? (
                <ButtonGroup>
                  <PinInput
                    type="text"
                    value={pinCode}
                    onChange={(e) => setPinCode(e.target.value)}
                    placeholder={i18next.t('pin.enterPinCode')}
                  />
                  <Button onClick={() => joinRoomCode()}>
                    <i className="fas fa-chevron-right" /> {i18next.t('pin.enterRoom')}
                  </Button>
                </ButtonGroup>
              ) : (
                  <PrimaryButton onClick={joinRoom} disabled={isAcquiringLocalTracks || isConnecting}>
                    {isConnecting ? i18next.t('room.waiting.isConnecting') : i18next.t('pin.enterRoom')}
                  </PrimaryButton>
                )}
            </>
          ) : (
              <>
                {
                  !isCallHost ? i18next.t('room.waiting.patientText') : i18next.t('room.waiting.hostText')
                }
                <br />
                <PrimaryButton onClick={endRoom}>
                  {i18next.t('room.endCall.confirmButton')}
                </PrimaryButton>
              </>
            )
        }
      </JoinCallButton>
      <SupportModal
        show={modal === Modal.support}
        onPositive={() => setModal(Modal.privacy)}
        onNegative={() => setModal(Modal.privacy)}
      />
      <PrivacyModal
        show={modal === Modal.privacy}
        onPositive={async () => {
          await fetchFromRestAPI(`/api/v1/privacy/${code}`, { method: 'POST' });
          setModal(Modal.hidden);
        }}
        onNegative={noop}
        onSupport={() => setModal(Modal.support)}
      />
      <NavigationPrompt when={isConnected}>
        {({ onConfirm, onCancel }) => (
          <ConfirmationModal
            show={true}
            title={i18next.t('room.endCall.title')}
            body={i18next.t('room.endCall.body')}
            buttonPositive={i18next.t('room.endCall.confirmButton')}
            buttonNegative={i18next.t('generic.cancel')}
            onPositive={() => {
              endRoomOnBack();
              onConfirm();
            }}
            onNegative={onCancel}
          />
        )}
      </NavigationPrompt>;
    </Wrapper>
  );
};

export default LocalVideoPreview;