import React, { FC, memo } from 'react';
import LoginBoxAuth0Lock from '../components/loginSignup/LoginBoxAuth0Lock';
import RegisterPageLayout from '../components/loginSignup/RegisterPageLayout';

const RegisterPageContainer: FC<{signIn:boolean}> = ({signIn}) => {

  return <RegisterPageLayout loginComponent={<LoginBoxAuth0Lock signIn={signIn} />} signIn={signIn} />;
};

export default memo(RegisterPageContainer);
