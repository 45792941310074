import moment from 'moment';
import React, { FC, memo } from "react";
import 'moment-timezone';
import { box } from 'tweetnacl';
import { decrypt } from '../../util/crypto';
import { decodeBase64 } from 'tweetnacl-util';
import { formatDateForChat } from '../../util/dateTimeUtils';
import i18next from 'i18next';
import emoji from 'to-emoji';
import { ReactComponent as ErrorIcon } from '../../assets/error-icon.svg';
type Props = {
    message: any, pname: string, therapist: any, patientPublicKey: any, therapistPrivateKey: any,
    resendMessage: (text) => void;
};
const ChatMessage: FC<Props> = ({ message, pname, therapist, patientPublicKey, therapistPrivateKey, resendMessage }) => {
    const messageClass = message && message.senderId === therapist._id ? 'right' : 'left';
    const msgNotSent = message.isNew ? 'chat-bg-right-notsent' : '';
    const finalKeyTherpaist = box.before(decodeBase64(patientPublicKey), therapistPrivateKey);
    const localtz = moment.tz.guess();
    if (messageClass === "left") {
        return (
            <div className={`chat-message-${messageClass} pb-4`} >
                <div className={`flex-shrink-1 chat-bg-${messageClass} chat-round py-2 px-3 mr-3 w-100 clearfix`} >

                    <p className="mt-1 p-0 mb-0" dangerouslySetInnerHTML={{ __html: emoji.emoticonToEmoji(decrypt(finalKeyTherpaist, message.text)) }}></p>
                    <p className="small text-nowrap mt-0 p-0 mb-0 text-right" >{moment(message.createdAt).isValid() ? formatDateForChat(moment(moment.utc(message.createdAt).toDate()).locale(localtz)) : null} </p>
                </div>
            </div>)
    } else {
        return (
            <><div className={`chat-message-${messageClass} mt-3`} id={'chat-message-body-' + message.ackId} onClick={() => { if (message.ackId) resendMessage(decrypt(finalKeyTherpaist, message.text)) }}>
                <div className={`flex-shrink-1 chat-bg-${messageClass} chat-round py-2 px-3 mr-3 w-100 clearfix ${msgNotSent}`} id={message.ackId ? message.ackId : ''}>
                    <p id={'chat-actual-message-' + message.ackId} className="mt-1 p-0 mb-0" dangerouslySetInnerHTML={{ __html: emoji.emoticonToEmoji(decrypt(finalKeyTherpaist, message.text)) }}></p>
                    <p className="small text-nowrap mt-0 p-0 mb-0 text-right" id={message.ackId ? message.ackId : ''}>{moment(message.createdAt).isValid() ? formatDateForChat(moment(moment.utc(message.createdAt).toDate()).locale(localtz)) : null} 
                    {message.ackId ? <span><i id={'chat-loading-' + message.ackId} className="fas fa-spin fa-spinner chat-date-loading" /></span> : null}</p>
                </div>
            </div>
              
            {message.ackId ? <div className={`chat-message-error text-right mr-4 d-none chat-error-message-${message.ackId}`}><p className={`small text-nowrap text-primary mt-0 p-0 mb-0 w-100 `}> {i18next.t('chat.sendingFailed')}  <ErrorIcon className='small' width={12} height={12} /></p></div> : null}
            </>)
    }
};

export default memo(ChatMessage);
