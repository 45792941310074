import i18next from 'i18next';
import { map } from 'lodash';
import moment from 'moment';
import React, { FC, memo, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { fetchFromRestAPI } from '../util/api';
import { useAuth0 } from '../util/auth0';
const Wrapper = styled.div`
  h2 {
    font-size: 1.8rem;
    font-weight: 700;
    margin-top: 2rem;
  }
`;
const AppointmentHistoryContainer: FC = () => {
  const { user, getIdTokenClaims } = useAuth0();
  const [appointmentList, setAppointmentList] = useState([]);
  const getAppointmentList = async (token) => {
    const list = await fetchFromRestAPI('/api/v1/appointment/therapist/history', {
      method: 'GET',
      token,
    });

    if (list && list.error) {
      return [];
    }
    return list;
  }

  

  useEffect(() => {
    if (user) {
      getIdTokenClaims().then((idToken) => {
        getAppointmentList(idToken).then((list) => setAppointmentList(list));
        })
    }
  }, [user, getIdTokenClaims]);

 const renderAppointmentTable = (a,index)=>{
return(
  <tr key={'a-'+index}>
    <td>{a.patient.firstName} {a.patient.lastName} </td>
    <td>{moment(a.start).format('DD.MM.YYYY')}</td>
    <td>{moment(a.start).format('HH:mm')} </td>
    <td>{moment(a.end).format('HH:mm')}</td>
    <td>{moment.duration(moment(a.end).diff(moment(a.start))).asHours().toFixed(2)}h</td>
  </tr>
)
 }
  return (
      <Wrapper>
        <Row>
          <Col md={12}>
            <h2>{i18next.t('myAccountOverview.appointmentHeading')}</h2>
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>{i18next.t('myAccountOverview.appointmentPatient')}</th>
                  <th>{i18next.t('myAccountOverview.appointmentDate')}</th>
                  <th>{i18next.t('myAccountOverview.appointmentStartTime')}</th>
                  <th>{i18next.t('myAccountOverview.appointmentEndTime')}</th>
                  <th>{i18next.t('myAccountOverview.appointmentDuration')}</th>
                </tr>
              </thead>
              <tbody>
              {map(appointmentList, (a, i) => renderAppointmentTable(a, i))}
              </tbody>
            </table>
           
          </Col>
        </Row>
      </Wrapper>
  );
};

export default memo(AppointmentHistoryContainer);