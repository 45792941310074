import EventEmitter from 'events';
import Video, { ConnectOptions, LocalDataTrack, LocalTrack, Room } from 'twilio-video';
import i18next from 'i18next';
import { useCallback, useEffect, useRef, useState } from 'react';
import { isMobile } from '../../../util';
import { Callback } from '../../../util/types';
import { fetchFromRestAPI } from '../../../util/api';
import { emitWarnNotification } from '../../Notification';
import { useAuth0 } from '../../../util/auth0';
import { useHistory } from 'react-router';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.TwilioVideo = Video;

export default function useRoom(localTracks: LocalTrack[], onError: Callback, options?: ConnectOptions) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [room, setRoom] = useState<Room>(new EventEmitter() as Room);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isCallHost, setIsCallHost] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [isHostConnected, setIsHostConnected] = useState(false);
  const [isParticipantConnected, setIsParticipantConnected] = useState(false);
  const [pinCode, setPinCode] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const localTracksRef = useRef<LocalTrack[]>([]);
  const { user, getIdTokenClaims } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    // It can take a moment for Video.connect to connect to a room. During this time, the user may have enabled or disabled their
    // local audio or video tracks. If this happens, we store the localTracks in this ref, so that they are correctly published
    // once the user is connected to the room.
    localTracksRef.current = localTracks;
  }, [localTracks]);

  const fetchTwilioRoomAccessToken = useCallback(
    async (roomCode, pin) => {
      let token;
      if (user) {
        token = await getIdTokenClaims();
      }

      let pinUrl = '';
      if (pin) {
        pinUrl = `/${pin}`;
      }

      try {
        const { roomAccessToken, isCallHost, sessionId } = await fetchFromRestAPI(
          `/api/v1/group-rooms/${roomCode}/session${pinUrl}`,
          {
            method: 'POST',
            token,
          }
        );
        setIsCallHost(isCallHost);
        setSessionId(sessionId);
        return roomAccessToken;
      } catch (e) {
        if (e.code === 'room-not-found') {
          emitWarnNotification(`Es konnte kein Gruppenraum unter dem code "${roomCode}" gefunden werden.`);
        } else if (e.code === 'room-full') {
          emitWarnNotification(
            `Der Raum ist leider bereits voll. Vielleicht haben Sie diese Seite bereits auf einem anderen Gerät oder Browser geöffnet?`
          );
        } else if (e.code === 'pin-required') {
          emitWarnNotification(i18next.t('pin.error-required'));
        } else if (e.code === 'pin-participated') {
          emitWarnNotification(i18next.t('pin.error-participated'));
        } else if (e.code === 'pin-cancelled') {
          emitWarnNotification(i18next.t('pin.error-cancelled'));
        } else if (e.code === 'pin-wrong') {
          emitWarnNotification(i18next.t('pin.error-wrong'));
        } else if (e.code === 'event-wrong-trainer') {
          emitWarnNotification(i18next.t('pin.error-wrong-trainer'));
        } else {
          emitWarnNotification(
            `Leiter konnte eine Verbindung zum Gruppenraum nicht hergestellt werden. Bitte versuchen Sie es erneut!`
          );
        }
        return '';
      }
    },
    [user, getIdTokenClaims]
  );

  const connect = useCallback(
    (roomCode, pin) => {
      if (pin) {
        setPinCode(pin);
      }
      setIsConnecting(true);
      const dataTrack = localTracks.find((track) => track.kind === 'data') as LocalDataTrack;
      console.log(`Init group call with code ${roomCode}`);
      const updateParticipantStates = (participant) => {
        if (participant.identity === 'host') {
          setIsHostConnected(true);
        } else {
          setIsParticipantConnected(true);
        }
      };
      return fetchTwilioRoomAccessToken(roomCode, pin)
        .then((twilioRoomAccessToken) => {
          return Video.connect(twilioRoomAccessToken, { ...options, tracks: [dataTrack] });
        })
        .then(
          (newRoom) => {
            setRoom(newRoom);

            setIsHostConnected(
              !!Array.from(newRoom.participants.values()).find((participant) => {
                return participant.identity === 'host';
              })
            );

            setIsParticipantConnected(
              !!Array.from(newRoom.participants.values()).find((participant) => {
                return participant.identity !== 'host';
              })
            );

            if (!newRoom.listeners('participantConnected').filter((f) => f.name === 'updateParticipantStates').length) {
              newRoom.on('participantConnected', updateParticipantStates);
            }

            const disconnect = () => newRoom.disconnect();

            newRoom.once('disconnected', () => {
              // Reset the room only after all other `disconnected` listeners have been called.
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              setTimeout(() => setRoom(new EventEmitter() as Room));
              window.removeEventListener('beforeunload', disconnect);

              if (isMobile) {
                window.removeEventListener('pagehide', disconnect);
              }
            });

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.twilioRoom = newRoom;

            localTracksRef.current.forEach((track) =>
              // Tracks can be supplied as arguments to the Video.connect() function and they will automatically be published.
              // However, tracks must be published manually in order to set the priority on them.
              // All video tracks are published with 'low' priority. This works because the video
              // track that is displayed in the 'MainParticipant' component will have it's priority
              // set to 'high' via track.setPriority()
              newRoom.localParticipant.publishTrack(track, { priority: track.kind === 'video' ? 'low' : 'standard' })
            );

            setIsConnecting(false);

            // Add a listener to disconnect from the room when a user closes their browser
            window.addEventListener('beforeunload', disconnect);

            if (isMobile) {
              // Add a listener to disconnect from the room when a mobile user closes their browser
              window.addEventListener('pagehide', disconnect);
            }
          },
          (error) => {
            onError(error);
            setIsConnecting(false);
          }
        );
    },
    [options, onError, localTracks, fetchTwilioRoomAccessToken]
  );

  const finishGroupRoomCallRemote = async (roomCode, sessionId) => {
    const token = await getIdTokenClaims();
    return fetchFromRestAPI(`/api/v1/group-rooms/${roomCode}/session/${sessionId}`, {
      method: 'DELETE',
      token,
    });
  };

  const endCall = async (pin = null, isBack = false) => {
    if (isCallHost) {
      await finishGroupRoomCallRemote(room.name, sessionId);
    }
    await room.disconnect();
    if (!isBack) history.push(`/${room.name}/verlassen/${isCallHost ? '1' : '0'}/${pin ? pin : ''}`);
  };

  return {
    room,
    isConnecting,
    connect,
    isCallHost,
    isHostConnected,
    isParticipantConnected,
    sessionId,
    endCall,
    pinCode,
    isFullScreen,
    setIsFullScreen,
  };
}
