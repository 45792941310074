import React, { FC } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

const StyledToastContainer = styled.div`
  .Toastify {
    &__toast {
      font-family: inherit;
      font-size: 1.6rem;
      padding: 8px 16px;
      &--info {
        background-color: #595959;
        opacity: 0.8;
      }
    }
    &__toast-container {
      width: 50rem;
      &--top-center {
        top: 6rem;
        margin-left: -250px;
      }
    }

    @media only screen and (max-width: 812px) {
      &__toast-container {
        width: 100%;
        margin: 1em;
        &--top-center {
          top: 5rem;
          margin-left: 0px;
        }
      }
    }
  }
`;

const defaultToastOtions = {
  autoClose: 5000,
  type: 'info',
};

export function emitNotification(message, options: any = defaultToastOtions) {
  toast(message, options);
}

export function emitWarnNotification(message) {
  toast.warn(message);
}

const Notification: FC = () => {
  return (
    <StyledToastContainer>
      <ToastContainer position={toast.POSITION.TOP_CENTER} autoClose={5000} />
    </StyledToastContainer>
  );
};

export default Notification;
