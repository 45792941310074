import React, { FC } from 'react';
import styled from 'styled-components';

import MainParticipant from './MainParticipant';
import EndCallButton from './Controls/EndCallButton';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import ToggleVideoButton from './Controls/ToggleVideoButton';
import ToggleAudioButton from './Controls/ToggleAudioButton';
import ParticipantNetworkIndicator from './ParticipantNetworkIndicator';
import ParticipantSwitch from './ParticipantSwitch';
import ParticipantsCounter from './ParticipantsCounter';
import ToggleFullScreenButton from './Controls/ToggleFullScreenButton';

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #002b4b;
  display: grid;
  grid-template-areas:
    'mainParticipant'
    'participantList'
    'bottomControlsBar';
  grid-template-rows: calc(90vh - 18em) 15em 5em;
  grid-template-columns: 1fr;

  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
    grid-template-areas:
      'mainParticipant participantList'
      'bottomControlsBar bottomControlsBar';
    grid-template-columns: 1fr 16em;
    grid-template-rows: 1fr 5em;
  }
`;

const NetworkIndicatorContainer = styled.div`
  position: absolute;
  left: 2rem;
  top: 2.3rem;

  @media only screen and (max-width: 768px) {
    left: 0.9rem;
    top: 1.8rem;
  }
`;

const MainParticipantContainer = styled.div`
  position: relative;
  padding-top: 3rem;
  grid-area: mainParticipant;
  display: flex;
  justify-content: center;

  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
    padding-top: 5rem;
  }
`;

const ParticipantBlock = styled.div`
  margin-top: 5em;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
    margin-top: 5em;
  }
`;

const BottomControlsBarMobile = styled.div`
  position: absolute;
  width: 100%;
  bottom: 1.5em;
  left: 50%;
  transform: translateX(-50%);
  grid-area: bottomControlsBar;

  display: flex;
  align-items: center;
  justify-content: center;

  .btn {
    font-size: 12px !important;
    margin: 0px 2px 0px 2px;
  }

  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
    grid-area: participantList;
    top: 3em;
    position: absolute;
    right: 1.5rem;
  }
`;

const ParticipantCount = styled.div`
  position: absolute;
  left: 5rem;
  top: 2rem;
  z-index: 2;

  @media only screen and (max-width: 768px) {
    left: 1.5rem;
    top: 2rem;
  }
`;

const GroupRoomMobile: FC = () => {
  const {
    isCallHost,
    isP2P,
    room: { localParticipant },
    pinCode,
  } = useVideoContext();

  return (
    <Container>
      {!isP2P && isCallHost && (
        <NetworkIndicatorContainer>
          <ParticipantNetworkIndicator participant={localParticipant} />
        </NetworkIndicatorContainer>
      )}
      <EndCallButton pin={pinCode} />
      {!isP2P && !isCallHost && (
        <ParticipantCount>
          <ParticipantsCounter />
        </ParticipantCount>
      )}
      <MainParticipantContainer id="main-video-container">
        <MainParticipant />
      </MainParticipantContainer>
      <ParticipantBlock>
        <ParticipantSwitch />
      </ParticipantBlock>
      <BottomControlsBarMobile>
        <ToggleVideoButton />
        <ToggleAudioButton />
        <ToggleFullScreenButton />
      </BottomControlsBarMobile>
    </Container>
  );
};

export default GroupRoomMobile;
