import i18next from 'i18next';
import moment from 'moment';
import React, { FC, memo, useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { useAuth0 } from '../../util/auth0';
import { fetchTherapistPlan, fetchTherapistCurrentPlan, cancelTherapistPlan } from '../../util/UserAccount';
import CustomDialog from '../CustomDialog';
import PrimaryButton from '../PrimaryButton';
import UpgradePlanModal from './UpgradePlanModal';
import ConfirmationModal from '../ConfirmationModal';
import { maxBy } from 'lodash';
const Wrapper = styled.div`
  h2 {
    font-size: 1.8rem;
    font-weight: 700;
    margin-top: 2rem;
  }
`;
type Props = { userProfile: any };
const BillingComponent: FC<Props> = ({ userProfile }) => {
    const { getIdTokenClaims } = useAuth0();
    const localtz = moment.tz.guess();
    const i18nextLng = localStorage.getItem('i18nextLng');
    const userLanguage = i18nextLng && i18nextLng.includes('-') ? i18nextLng.split('-')[0] : i18nextLng;
    const [plans, setPlans] = useState([]);
    const [currentPlan, setCurrentPlans] = useState(null);
    const [isShowUpgradeModal, setShowUpgradeModal] = useState(false);
    const [isShowComplete, setIsShowComplete] = useState(false);
    const [cancelSubModal, setCancelSubModal] = useState(false);
    const [cancelSubSuccessModal, setCancelSubSuccessModal] = useState(false);
    const getPlanList = () => {
        fetchTherapistPlan().then((response) => {
            if (response && response.length > 0) {
                setPlans(response);
            }
        });
        getIdTokenClaims().then((token) => {
            fetchTherapistCurrentPlan(token).then((response) => {
                if (response && !response.error) {
                    setCurrentPlans(response);
                }
            });
        });
    }
    useEffect(() => {
        if (plans.length === 0) {
            getPlanList();
        }
    }, [getIdTokenClaims]); // eslint-disable-line
    const renderPricePlan = () => {
        return (<Card.Body>
            <h3 className='font-weight-bold mb-3'>{i18next.t('planDetails.currentPlan')}</h3>
            {plans && plans.length > 0 ? renderPlanList() : null}
        </Card.Body>);
    }
    const handleSelectPlan = () => {
        setShowUpgradeModal(false);
        setIsShowComplete(true);
    }
    const handleReloadPage = () => {
        setCancelSubModal(false);
        setCancelSubSuccessModal(false);
        setIsShowComplete(false);
        getPlanList();
    }
    const handleCancelUpgradePlan = () => {
        setShowUpgradeModal(false);
    }
    const onCancelSubscription = () => {
        setCancelSubModal(false)
        setCancelSubSuccessModal(true);
        getIdTokenClaims().then((token) => {
            cancelTherapistPlan(token).then((response) => {
                if (response && !response.error) {
                }
            });
        });
    }
    const getEndDate = (cPlan:any) =>{
        if(moment.parseZone().locale(localtz).isSameOrBefore(moment.parseZone(cPlan.startDate).locale(localtz))){
           return moment.parseZone(currentPlan.startDate).locale(localtz).format('DD.MM.YYYY')
        }else{
          return  moment.parseZone(currentPlan.endDate).locale(localtz).format('DD.MM.YYYY')
        }
    }
    const renderUpgradeButton = (maxPlan: any, plan: any) => {
        if (currentPlan && currentPlan.isCanceled) {
            return (
                <ul className="list-unstyled m-0 p-0">
                    {maxPlan._id !== plan._id ? <li> <PrimaryButton onClick={() => { setShowUpgradeModal(true) }}>{i18next.t('planDetails.upgradePlanButton')}</PrimaryButton></li> : null}
                </ul>);
        } else {
            return (
                <ul className="list-unstyled m-0 p-0">
                    {maxPlan._id !== plan._id ? <li> <PrimaryButton onClick={() => { setShowUpgradeModal(true) }}>{i18next.t('planDetails.upgradePlanButton')}</PrimaryButton></li> : null}
                    <li><Button variant='link' className="text-muted pl-0 underline-link" onClick={() => setCancelSubModal(true)}><small>{i18next.t('planDetails.cancelSubscription')}</small></Button></li>
                </ul>);
        }
    }
    const renderCurrentPlan = (plan: any) => {
        const maxPlan = maxBy(plans, 'planPrice');
        const points = userLanguage === 'de' ? plan.planPointsDE : plan.planPointsEN
        return (
            <Col className="mt-2" key={plan._id}>
                <Card className="border-0 plan-price-card-account">
                    <Card.Body className="pb-0 mb-0">
                        <h3 className="font-weight-bold">{userLanguage === 'de' ? plan.planNameDE : plan.planNameEN}</h3>
                        <Row>
                            <Col md={8}>
                                <ul className="price-list list-unstyled mt-3 mb-4 pl-3 pr-5">
                                    {points.map((p, i) => {
                                        return <li className="pl-2" key={'li-' + i}><span dangerouslySetInnerHTML={{ __html: p }}></span></li>
                                    })}
                                </ul>
                            </Col>
                            <Col md={4} className='d-flex justify-content-center align-items-center'>
                                {renderUpgradeButton(maxPlan, plan)}
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer className="border-0 plan-price-card-footer pt-0 pl-3">
                        {currentPlan.isCanceled ?
                            <span className="small text-muted">{i18next.t('planDetails.planWillBeCanceled').replace('{price}', plan.planPrice + " " + i18next.t('planDetails.currencySymbol')).replace('{startDate}', moment.parseZone(currentPlan.startDate).locale(localtz).format('DD.MM.YYYY')).replace('{endDate}', moment.parseZone(currentPlan.endDate).locale(localtz).format('DD.MM.YYYY'))} </span>
                            :
                            <span className="small text-muted">{i18next.t('planDetails.nextPaymentLabel').replace('{price}', plan.planPrice + " " + i18next.t('planDetails.currencySymbol')).replace('{startDate}', moment.parseZone(currentPlan.startDate).locale(localtz).format('DD.MM.YYYY')).replace('{endDate}', moment.parseZone(currentPlan.endDate).locale(localtz).format('DD.MM.YYYY'))} </span>}
                    </Card.Footer>
                </Card>
            </Col>);
    }
    const renderPlanList = () => {
        const plan = plans.filter((x) => x._id === userProfile.therapistPlan);
        return (
            <Row className="">
                {plan && plan.length > 0 && currentPlan ? renderCurrentPlan(plan[0]) : null}
            </Row>);
    }

    return (
        <Wrapper>
            <Row className="">
                <Col md={4}>
                    {renderPricePlan()}</Col>
            </Row>
            {plans && userProfile ? <UpgradePlanModal key="upgradeModal" currentPlan={currentPlan} planList={plans} show={isShowUpgradeModal} onPositive={handleSelectPlan} onAbort={handleCancelUpgradePlan}></UpgradePlanModal> : null}
            <CustomDialog title={i18next.t('planDetails.upgradeSuccessTitle')} description={i18next.t('planDetails.upgradeSuccessBody')} confirmation={i18next.t('generic.close')} show={isShowComplete} onCancel={null} onAccept={handleReloadPage}></CustomDialog>
            <CustomDialog title={i18next.t('planDetails.cancelSuccessTitle')} description={i18next.t('planDetails.cancelSuccessBody')} confirmation={i18next.t('generic.close')} show={cancelSubSuccessModal} onCancel={null} onAccept={handleReloadPage}></CustomDialog>
            {userProfile && currentPlan ?
                <ConfirmationModal
                    show={cancelSubModal}
                    title={i18next.t('planDetails.cancelModalTitle')}
                    body={i18next.t('planDetails.cancelModalBody').replace('{startDate}', moment.parseZone(currentPlan.startDate).locale(localtz).format('DD.MM.YYYY')).replace('{endDate}', getEndDate(currentPlan))}
                    buttonPositive={i18next.t('planDetails.cancelButton')}
                    buttonNegative={i18next.t('generic.close')}
                    onPositive={onCancelSubscription}
                    onNegative={() => setCancelSubModal(false)}
                /> : null}
        </Wrapper>
    );
};

export default memo(BillingComponent);