import { reduce } from 'lodash';
import React, { FC, memo, useEffect, useState } from 'react';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import styled from 'styled-components';
import CallStatisticsPerDayChart from '../components/CallStatisticsPerDayChart';
import CallStatisticsPerPatientChart from '../components/CallStatisticsPerPatientChart';
import { fetchFromRestAPI } from '../util/api';
import { useAuth0 } from '../util/auth0';

const Wrapper = styled.div`
  width: 80%;
  height: 500px;
  margin: 0 auto;
`;

const TotalWrapper = styled.div`
  padding: 1rem 0;
  text-align: center;
`;

const TotalLabel = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
`;

const TotalNumber = styled.div`
  font-size: 2.6rem;
  font-weight: 700;
`;

const Chart = styled.div`
  height: 400px;
`;

const ChartToggler = styled(ToggleButtonGroup)`
  position: absolute;
  right: 0;
  margin-top: 1rem;
  margin-right: 4rem;
`;

const NoDataAvailableContainer = styled.div`
  text-align: center;
  margin-top: 2rem;
`;

enum CHARTS {
  HOURS_PER_DAY,
  HOURS_PER_PATIENT,
}

const MyAccountHistoryContainer: FC = () => {
  const { user, getIdTokenClaims } = useAuth0();
  const [activeChart, setActiveChart] = useState(CHARTS.HOURS_PER_DAY);
  const [callStatisticsPerDay, setCallStatisticsPerDay] = useState([]);
  const [callStatisticsPerPatient, setCallStatisticsPerPatient] = useState([]);

  const getHourByTime = async (token) => {
    const hourByTime = await fetchFromRestAPI('/api/v1/statistics/hours-by-time', {
      method: 'GET',
      token,
    });

    if (hourByTime && hourByTime.error) {
      return [];
    }
    return hourByTime;
  }

  const getHourByPatient = async (token) => {
    const hourByPatient = await fetchFromRestAPI('/api/v1/statistics/hours-by-patient', {
      method: 'GET',
      token,
    });

    if (hourByPatient && hourByPatient.error) {
      return [];
    }
    return hourByPatient;
  }

  useEffect(() => {
    if (user) {
      getIdTokenClaims().then((idToken) => {
        getHourByTime(idToken).then((hourByTime) => setCallStatisticsPerDay(hourByTime));
        getHourByPatient(idToken).then((hourByPatient) => setCallStatisticsPerPatient(hourByPatient));
      })
    }
  }, [user, getIdTokenClaims]);


  const callSumHours = reduce(callStatisticsPerDay, (sum, dayStat) => sum + dayStat.durationInHours, 0);

  const getChart = (chartType) => {
    if (chartType === CHARTS.HOURS_PER_DAY && callStatisticsPerDay.length > 0) {
      return <CallStatisticsPerDayChart callStatisticsPerDay={callStatisticsPerDay} />;
    }

    if (chartType === CHARTS.HOURS_PER_PATIENT && callStatisticsPerPatient.length > 0) {
      return <CallStatisticsPerPatientChart callStatisticsPerPatient={callStatisticsPerPatient} />;
    }

    return <NoDataAvailableContainer>Keine Daten vorhanden!</NoDataAvailableContainer>;
  };

  return (
    <Wrapper>
      <ChartToggler type="radio" name="options" value={activeChart} onChange={setActiveChart}>
        <ToggleButton type="radio" variant="outline-dark" value={CHARTS.HOURS_PER_DAY}>
          <i className="fas fa-chart-bar" />
        </ToggleButton>
        <ToggleButton type="radio" variant="outline-dark" value={CHARTS.HOURS_PER_PATIENT}>
          <i className="fas fa-list" />
        </ToggleButton>
      </ChartToggler>
      <TotalWrapper>
        <TotalLabel>Gesamtzeit:</TotalLabel>
        <TotalNumber>{callSumHours.toFixed(2)}h</TotalNumber>
      </TotalWrapper>
      <Chart>{getChart(activeChart)}</Chart>
    </Wrapper>
  );
};

export default memo(MyAccountHistoryContainer);