import React, { useState, useEffect, FunctionComponent } from 'react';
import i18next from 'i18next';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '../../util/auth0';
import { fetchFromRestAPI } from '../../util/api';
import SecondaryButton from '../../components/SecondaryButton';
import { Badge, Col, Row } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import { ITherapy } from '../../util/types';
import ContraindicationModal from '../../components/ContraindicationModal';

const Wrapper = styled.main`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 20px 0px 20px 20px;
  .dropdown-item {
    font-size: 1.5rem;
  }
  .btn-secondary {
    border: 2px solid #ff695f;
    color: #ff695f;
  }
  h1 {
    font-weight: bold;
    font-size: 4rem;
  }
  .banner {
    display: flex;
    > div {
      max-width: 480px;
    }
  }
  .control-btns {
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: auto;
    .btn {
      margin-bottom: 8px;
    }
  }
  .patient-overview {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    > div {
      &:hover {
        background-color: #ececec;
        cursor: pointer;
        .name {
          font-weight: 700;
        }
      }
      display: flex;
      white-space: nowrap;
      padding: 4px 16px;
      align-items: center;
      background: white;
      &:first-of-type {
        padding-top: 8px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      &:last-of-type {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        padding-bottom: 8px;
      }
      .name {
        flex: 1;
        color: #444;
      }
      .btn-secondary {
        margin-left: 12px;
      }
      input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        height: 39px;
        width: 250px;
      }
    }
  }
  @media (max-width: 750px), (max-device-width: 815px) and (-webkit-min-device-pixel-ratio: 3) {
    .banner {
      h1 {
        font-size: 1.8rem;
      }
      flex-direction: column;
      .control-btns {
        align-items: flex-start;
      }
    }
    .patient-overview {
      > div {
        flex-direction: column;
        border-top: 1px solid #eee;
        &:first-of-type {
          border-top: 0;
        }
        .name {
          width: 100%;
          padding: 0;
        }
        .btn-group input {
          width: 100%;
        }
        .btn-secondary {
          margin: 8px auto 8px 0;
        }
      }
    }
  }
`;

const WorkoutStatusBadge: FunctionComponent<{ status: string }> = ({ status }) => {
  const variantByStatus = {
    DRAFT: 'warning',
    PUBLISHED: 'success',
    UNPUBLISHED: 'warning',
  };
  return <Badge className="mr-3 mb-2" style={{ color: 'white', fontSize: '100%' }} variant={variantByStatus[status]}>
    {i18next.t(`trainingPlan.status.${status}`)}</Badge>;
};

type WorkoutsContainerProps = {
  therapy: ITherapy;
};

const WorkoutsContainer: FunctionComponent<WorkoutsContainerProps> = ({ therapy }) => {
  const { getIdTokenClaims } = useAuth0();
  const { push } = useHistory();
  const [trainingPlan, setTrainingPlan] = useState([]);
  const [patientID, setPatientId] = useState('');
  const [showContraindication, setShowContraindication] = useState(false);
  // const [modal, setModal] = useState(null);
  useEffect(() => {
    const fetchDataAsync = async () => {
      if (!therapy) return null;
      const jsonPatient = JSON.parse(JSON.stringify(therapy.patient));
      if (jsonPatient && jsonPatient.insurancePlan && !jsonPatient.isContraindicationsAccepted) {
        setPatientId(jsonPatient._id);
      }
      await fetchTrainingPlans(therapy.id);
    };
    fetchDataAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [therapy]);

  async function fetchTrainingPlans(therapyId) {
    const token = await getIdTokenClaims();
    const trainingData = await fetchFromRestAPI(`/api/v1/trainingplan/therapy/${therapyId}`, {
      token,
    });
    setTrainingPlan(trainingData);
  }
  const handleContraindication = async () => {
    setShowContraindication(false);
    const token = await getIdTokenClaims();
    const patientData = { _id: patientID }
    await fetchFromRestAPI('/api/v1/patients/update/contraindications', {
      method: 'PATCH',
      body: patientData,
      token,
    });
    if (trainingPlan && trainingPlan["_id"])
      push(`/therapien/${therapy.id}/workout/${trainingPlan["_id"]}`);
    else
      push(`/therapien/${therapy.id}/workout`);

  }
  return (
    <Wrapper>
      <ContraindicationModal
        show={showContraindication}
        title={i18next.t('createPatientForm.contraindication.title')}
        titleIntro={i18next.t('createPatientForm.contraindication.titleIntro')}
        body={i18next.t('createPatientForm.contraindication.body')}
        buttonPositive={i18next.t('createPatientForm.contraindication.save')}
        buttonNegative={i18next.t('generic.back')}
        onPositive={handleContraindication}
        onNegative={() => setShowContraindication(false)}
        initValue={false}
      ></ContraindicationModal>
      <Row>
        <Col md={12}>
          <div dangerouslySetInnerHTML={{ __html: i18next.t('workouts.introText') }} />
        </Col>
        <Col md={12} className="text-right">
          {!isEmpty(trainingPlan) ? <WorkoutStatusBadge status={trainingPlan["status"]}></WorkoutStatusBadge> : <></>}

          {
            !isEmpty(trainingPlan) ?
              <SecondaryButton onClick={() => { patientID !== '' ? setShowContraindication(true) : push(`/therapien/${therapy.id}/workout/${trainingPlan["_id"]}`) }}>
                {i18next.t('workouts.editTraining')}
              </SecondaryButton> :
              <SecondaryButton onClick={() => { patientID !== '' ? setShowContraindication(true) : push(`/therapien/${therapy.id}/workout`) }}>
                {i18next.t('workouts.createTraining')}
              </SecondaryButton>
          }
        </Col>
      </Row>
    </Wrapper>
  );
};

export default WorkoutsContainer;
