import React, { FC } from 'react';
import { Participant as IParticipant } from 'twilio-video';
import ParticipantInfo from './ParticipantInfo';
import ParticipantTracks from './ParticipantTracks';
import styled from 'styled-components';
import { FaVideoSlash } from 'react-icons/fa';

interface ParticipantProps {
  participant: IParticipant;
  disableAudio?: boolean;
  enableScreenShare?: boolean;
  onClick: () => void;
  isSelected: boolean;
  isInGrid?: boolean;
}

interface ParticipantWrapperProps {
  isSelected?: boolean;
  isInGrid?: boolean;
}

const ParticipantWrapper = styled.div<ParticipantWrapperProps>`
  position: relative;
  height: ${(props: ParticipantWrapperProps) => (props.isInGrid ? 'auto' : '150px')};
  width: ${(props: ParticipantWrapperProps) => (props.isInGrid ? '100%' : '170px')};
  text-align: center;
  border-radius: 1rem;
  overflow: hidden;
  float: left;
  display: inline-flex;
  background-color: #595959;
  color: #fff;
  align-items: center;
  justify-content: center;
  align-items: center;
  border: ${(props: ParticipantWrapperProps) => (props.isSelected ? '2px solid #ff695f' : '0px')};
  margin: 2px;

  & video {
    overflow: hidden;
    position: absolute;
    z-index: 1;
    background-size: 100% 100%;
    top: 0px;
    left: 0px;
    min-width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (max-width: 768px) {
    width: 135px;
    height: 135px;
  }
`;

const NoVideoWrapper = styled.div`
  position: absolute;
  z-index: 0;
  background-size: 100% 100%;
  top: 0px;
  left: 0px;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Participant: FC<ParticipantProps> = ({
  participant,
  disableAudio,
  enableScreenShare,
  onClick,
  isSelected,
  isInGrid,
}) => {
  return (
    <ParticipantWrapper isSelected={isSelected} isInGrid={isInGrid}>
      <ParticipantInfo participant={participant} isSelected={false} onClick={onClick}>
        <NoVideoWrapper>
          <FaVideoSlash />
        </NoVideoWrapper>
        <ParticipantTracks participant={participant} disableAudio enableScreenShare={enableScreenShare} />
      </ParticipantInfo>
    </ParticipantWrapper>
  );
};

export default Participant;
