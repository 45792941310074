import React, { memo } from 'react';
import Modal from 'react-bootstrap/Modal';
import StyledModal from '../StyledModal';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Field, Formik } from 'formik';
import i18next from 'i18next';
import { isEmpty } from 'lodash';
import moment from 'moment';
import 'moment/locale/de';

import 'rc-time-picker/assets/index.css';
import DayPickerInput from 'react-day-picker/DayPickerInput'
import styled from 'styled-components';
import { useAuth0 } from '../../util/auth0';
const ErrorDiv = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`;
const SaveAndPublishModal = ({
    show,
    onPositive,
    onNegative,
    initialValues,
}) => {
    const { isAdminUser } = useAuth0();
    const maxDate = moment().add(28, 'days');
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validateOnMount
            onSubmit={async ({ date, isGlobalTemplate, templateName }, { setSubmitting, setFieldValue }) => {
                onPositive(date, isGlobalTemplate, templateName);
                setSubmitting(false);
                setFieldValue("date", '');
                setFieldValue("isGlobalTemplate", false);
                setFieldValue("templateName", '');
            }}
            validate={({ date, templateName, isGlobalTemplate }) => {
                const errors: { date?: string; templateName?: string } = {};
                if (isEmpty(date)) {
                    errors.date = i18next.t('trainingPlan.validation.date');
                } else {
                    const selectedDate = moment(date, 'DD-MM-YYYY');
                    if (maxDate.diff(selectedDate, 'days') < 0) {
                        errors.date = i18next.t('trainingPlan.validation.greaterDate');
                    }

                    if (selectedDate.diff(maxDate, 'days') > 0) {
                        errors.date = i18next.t('trainingPlan.validation.pastDate');
                    }
                }
                if (isAdminUser && isGlobalTemplate && isEmpty(templateName)) {
                    errors.templateName = i18next.t('trainingPlan.validation.templateName');
                }
                return errors;
            }}
        >
            {({ values, errors, isValid, isSubmitting, handleSubmit, setFieldValue }) => (

                <StyledModal size="md" show={show} onHide={() => onNegative()}>
                    <Form onSubmit={handleSubmit} noValidate>
                        <Modal.Header closeButton>
                            <Modal.Title>{i18next.t('trainingPlan.saveAndPublish')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                {i18next.t('trainingPlan.saveAndPublishModal.body')}
                            </p>
                            <Form.Group controlId="date" bsPrefix="md-form form-group w-50">
                                <Form.Label>{i18next.t('trainingPlan.saveAndPublishModal.label')}</Form.Label>
                                <Field name="date" >
                                    {({ field, meta }) =>
                                    (
                                        <>
                                            <DayPickerInput
                                                keepFocus={false}
                                                placeholder="DD-MM-YYYY"
                                                inputProps={{ style: { padding: "0.75rem", border: "1px solid #e2e8f0", width: "100%", borderRadius: "0.25rem" } }}
                                                onDayChange={(day) => { moment(day).format('DD-MM-YYYY') !== values.date ? setFieldValue(field.name, moment(day).format('DD-MM-YYYY')) : setFieldValue(field.name, values.date); }}
                                                value={values.date} dayPickerProps={{ disabledDays: [{ before: moment().toDate(), after: maxDate.toDate() },new Date(parseInt(values.date.split('-')[2]), parseInt(values.date.split('-')[1]) - 1, parseInt(values.date.split('-')[0]))], firstDayOfWeek: 1 }}
                                                format={'DD-MM-YYYY'}
                                            />
                                            {errors && errors.date && errors.date.length > 0 ? (
                                                <ErrorDiv>{errors.date}</ErrorDiv>
                                            ) : null}
                                        </>

                                    )
                                    }
                                </Field>

                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()}>
                                {i18next.t('generic.cancel')}
                            </Button>
                            <Button type="submit" disabled={!isValid || isSubmitting} variant="danger">
                                {i18next.t('trainingPlan.saveAndPublish')}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </StyledModal>
            )}
        </Formik>
    );
};

export default memo(SaveAndPublishModal);
