import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';

const StyledBigModal = styled(Modal)`
  color: #595959;
  .modal-content {
    border-radius: 0.8rem;
    padding: 1rem;
  }
  .modal-title {
    font-size: 2.6rem;
    font-weight: 700;
  }
  .modal-header {
    border: none;
    .close {
      font-size: 3rem;
      font-weight: 300;
      color: inherit;
    }
  }
  .modal-body {
    strong {
      color: #000000;
    }
  }

  @media (max-width: 750px), (max-device-width: 815px) and (-webkit-min-device-pixel-ratio: 3) {
    .give-permission-img {
      display: none;
    }
  }
`;

export default StyledBigModal;
