import React from 'react';
import styled from 'styled-components';
import TherapistAccountPage from './loginSignup/TherapistAccountPage';


const Wrapper = styled.div`
  h2 {
    font-size: 1.8rem;
    font-weight: 700;
    margin-top: 2rem;
  }
`;


const MyAccountOverview = ({userProfile,plans}) => {
  
  return (
    <Wrapper>
      <section>
      <TherapistAccountPage userProfile={userProfile} plans={plans}/>
      </section>
    
    </Wrapper>
  );
};

export default MyAccountOverview;
