import contentDisposition from 'content-disposition';
import i18next from 'i18next';
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import useAuthenticatedImage from '../hooks/useAuthenticatedImage';
import { fetchFromRestAPI } from '../util/api';
import { useAuth0 } from '../util/auth0';
import { getFullURL } from '../util/endpoints';

const Wrapper = styled.main`
  display: flex;
  flex-grow: 1;
  background: rgba(255, 255, 255, 0.7);

  .materials {
    display: flex;
    flex-grow: 1;
    width: 100%;
    align-content: flex-start;
    flex-direction: column;
    border-radius: 4px;
    margin-bottom: 30px;
    padding: 20px;
    &.fullscreen {
      min-height: calc(100vh - 80px);
      .card-deck {
        margin-top: 30px;
      }
      .card {
        cursor: default;
        &:hover {
          background: transparent;
        }
      }
    }
    h1 {
      font-weight: bold;
      font-size: 4rem;
      color: #444;
      margin-top: 10px;
    }
    strong {
      color: #444;
      font-size: 19px;
      font-weight: normal;
      display: block;
      max-width: 500px;
    }
    .uploader {
      display: flex;
      flex-direction: column;
      text-align: center;
      flex-grow: 1;
      width: 100%;
      line-height: 2.5;
      &.hover .drag-message {
        background: #ff392c;
      }
      .drag-message {
        position: absolute;
        opacity: 0;
        pointer-events: none;
        transition: opacity 200ms;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: #ff695f;
        border-radius: 4px;
        padding: 2px;
        .fa-upload {
          margin-right: 8px;
        }
        span {
          height: 100%;
          border-radius: 4px;
          border: 1px dashed #eee;
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
        }
      }
      .text-muted {
        font-size: 0.8em;
      }
      .btn {
        height: 50px;
        position: relative;
        background: white;
        border: 2px solid #ff695f;
        font-weight: bold;
        width: 130px;
        margin: auto;
        border-radius: 8px;
        font-size: 16px;
        input {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }
      }
    }
    .card-deck {
      color: #333;
      margin: 0 -7px;
      .card {
        cursor: pointer;
        min-height: 100px;
        min-width: 160px;
        max-width: 250px;
        margin: 0 7px 12px;
        padding: 32px 12px 0;
        position: relative;
        border-radius: 12px;
        &:hover {
          background: #eee;
        }
        &.filler {
          min-height: 0;
          margin: 0 7px;
          padding: 0;
          opacity: 0;
        }
        &.upload-card {
          background: rgba(255, 105, 95, 0.6);
          border: 3px dashed rgb(255, 105, 95);
          color: #fff;
          padding-bottom: 30px;
        }
        .loader {
          display: inline-block;
          width: 100%;
          padding: 5px 0;
        }
        > span {
          display: block;
          cursor: pointer;
          position: absolute;
          right: 7px;
          top: 7px;
          opacity: 0.9;
          transition: 100ms ease;
          &:hover {
            opacity: 1;
          }
        }
      }
      img {
        margin: auto;
        border-radius: 2px;
      }
      .card-footer {
        margin-top: auto;
        white-space: nowrap;
        text-align: center;
        padding: 8px 0.5rem 4px;
        font-size: 0.9rem;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background: transparent;
        line-height: 1;
        color: #777;
        a {
          cursor: pointer;
          text-decoration: none;
          color: #777;
        }
      }
    }
  }

  @media (max-width: 750px), (max-device-width: 815px) and (-webkit-min-device-pixel-ratio: 3) {
    .materials .card-deck .card {
      margin: 8px auto;
    }
  }
`;

type IFile = {
  name: string;
  url: string;
  loading: boolean;
  type: string;
};

const FileCard: FC<{ file: IFile; action: (file: IFile) => void; setEditFile: (file: IFile) => void }> = ({
  file,
  action,
  setEditFile,
}) => {
  const imageObject = useAuthenticatedImage(file.url);
  return (
    <Card
      onClick={() => {
        if (action) action(file);
      }}
    >
      <span
        onClick={(e) => {
          e.stopPropagation();
          setEditFile(file);
        }}
        title={i18next.t('generic.edit')}
      >
        <i className="fas fa-ellipsis-v" />
      </span>

      {!file.loading && file.type.indexOf('image') === 0 && imageObject && <Card.Img variant="top" src={imageObject} />}
      <Card.Footer>
        {file.loading ? (
          <span className="loader">
            <i className="fas fa-spin fa-cog" />
          </span>
        ) : null}
        <span onClick={() => setEditFile(file)}>{file.name}</span>
      </Card.Footer>
    </Card>
  );
};

const Uploader: FC<{ session?: string; action?: (file: any) => void }> = ({ action, session }) => {
  const { user, getIdTokenClaims } = useAuth0();
  const [drag, setDrag] = useState(false);
  const [editFile, setEditFile] = useState(null);
  const [, setUpdated] = useState(new Date().toJSON());
  const files = useRef([]);

  // TODO this needs to be refactored
  useEffect(() => {
    let timeout;

    if (!user) return;

    function dragover(e) {
      e.preventDefault();

      document.body.classList.add('dropping');

      if (timeout) clearTimeout(timeout);

      timeout = setTimeout(() => {
        document.body.classList.remove('dropping');
      }, 500);
    }

    async function load() {
      const token = await getIdTokenClaims();

      files.current = await fetchFromRestAPI('/api/v1/files', {
        token,
      });

      setUpdated(new Date().toJSON());
    }

    document.body.addEventListener('dragover', dragover);

    load();

    return () => {
      document.body.removeEventListener('dragover', dragover);
    };
  }, [user]); // eslint-disable-line

  async function upload(file) {
    const token = await getIdTokenClaims();

    const response = await fetch(getFullURL('/api/v1/upload'), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token.__raw}`,
        'content-type': file.type,
        'Content-Disposition': contentDisposition(file.name),
      },
      body: file,
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const { file: newFile } = await response.json();

    files.current = { ...files.current, [newFile.id]: newFile };

    setUpdated(new Date().toJSON());
  }

  async function destroy() {
    edit({ ...editFile, deleted: true });

    setEditFile(null);
  }

  async function edit(file) {
    file = file || editFile;

    setEditFile(null);

    const token = await getIdTokenClaims();

    const response = await fetchFromRestAPI('/api/v1/files', {
      method: 'POST',
      body: file,
      token,
    });

    files.current = {
      ...files.current,
      [response.id]: response,
    };

    setUpdated(new Date().toJSON());
  }

  return (
    <Wrapper>
      <div className={`materials ${action ? '' : 'fullscreen'}`}>
        {action ? null : <h1>Meine Bilder</h1>}
        {action ? null : (
          <strong>Lade Bilder hoch, um Sie während der Video-Stunde mit deinen Klienten zu teilen.</strong>
        )}
        <CardDeck>
          <Card className="upload-card">
            <div
              className={`uploader ${drag ? 'hover' : ''}`}
              onDragOver={() => setDrag(true)}
              onDragLeave={() => setDrag(false)}
              onDrop={(e) => {
                e.preventDefault();
                [].map.call(e.dataTransfer.files, upload);
              }}
            >
              <div className="drag-message">
                <span>
                  <i className="fas fa-upload" />
                </span>
              </div>
              <span>Bild hier ablegen oder</span>
              <Button variant="link" block size="sm">
                Auswählen
                <input
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    [].map.call(e.target.files, upload);
                  }}
                  type="file"
                  multiple
                />
              </Button>
            </div>
          </Card>
          {Object.keys(files.current).map((key) => {
            const file = files.current[key];
            if (file.session && file.session !== session) return null;
            if (file.deleted) return null;
            return <FileCard key={key} file={file} action={action} setEditFile={setEditFile} />;
          })}
          {new Array(30).fill(null).map((_, index) => (
            <Card key={`filler-${index}`} className="filler" />
          ))}
        </CardDeck>
      </div>

      <Modal show={editFile !== null} onHide={() => setEditFile(null)}>
        <Modal.Header closeButton>
          <Modal.Title>{i18next.t('generic.edit')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editFile ? (
            <>
              {editFile.type.indexOf('image') === 0 ? (
                <img style={{ width: '100%' }} src={editFile.url} alt="" />
              ) : (
                <a href={editFile.url} rel="noopener noreferrer" target="_blank">
                  Herunterladen
                </a>
              )}
              <br />
              <br />
              <Form.Control
                value={editFile.name}
                onChange={(e) => setEditFile({ ...editFile, name: e.target.value })}
                placeholder="Name"
              />
            </>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ marginRight: 'auto' }} onClick={destroy} variant="danger">
            {i18next.t('generic.delete')}
          </Button>
          <Button variant="secondary" onClick={() => setEditFile(null)}>
            {i18next.t('generic.close')}
          </Button>
          <Button variant="success" onClick={() => edit(editFile)}>
            {i18next.t('generic.save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </Wrapper>
  );
};

export default Uploader;
