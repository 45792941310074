import React, { FC, useRef, useState } from 'react'
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd'
import { XYCoord } from 'dnd-core'
import { Card, Form, Row, Col, InputGroup, DropdownButton, Dropdown, ButtonGroup, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import styled from 'styled-components';
import { Formik, Field } from 'formik';
import i18next from 'i18next';
import { isEmpty, isObject } from 'lodash';
import ConfirmationModal from '../../components/ConfirmationModal';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Switch from "react-switch";
import { DefaultPlayer as Video } from 'react-html5video';
export const ItemTypes = {
    CARD: 'card',
}

const MoveSpan = styled.span`
    cursor: move;
  `;

const FlexDiv = styled.div`
    display: flex;
  
   .disabled-box {
      pointer-events: none;
      opacity: 0.5;
    }
    .react-switch {
      vertical-align: middle;
      margin-left: 4px;
    }
    .font-2x {
        font-size: 1.2em;
        color: #6c757d !important;
    }
    .font-2x:hover {
        font-size: 1.2em;
        color: #ff695f !important;
    }
  `;

const PaddingDiv = styled.div`
    padding-right: 10px;
  `;

const WidthDiv = styled.div`
    width: 100%;
    textarea {
      height: 70px;
    }
  `;

const ErrorDiv = styled.div`
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  `;

const warningColor = "#ffc107";
const errorColor = "#dc3545";
const style = {
    border: '1px dashed gray',
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    backgroundColor: 'white',
    cursor: 'move',
}

export interface CardProps {
    id: any
    index: number
    moveCard: (dragIndex: number, hoverIndex: number) => void,
    exerciseObj: any,
    removeExercise: any,
    updateExercise: any,
    userLanguage: any,
    tabIndex: any
}

interface DragItem {
    index: number
    id: string
    type: string
}

export const ExcerciseCardDnD: FC<CardProps> = ({ id, index, moveCard, tabIndex, exerciseObj, removeExercise, updateExercise, userLanguage }) => {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const ref = useRef<HTMLDivElement>(null)
    const moveUp = (currentIndex) => {
        if (currentIndex > 0)
            moveCard(currentIndex, currentIndex - 1);
    }
    const moveDown = (currentIndex) => {
        console.log("moveDown currentIndex", currentIndex)
        moveCard(currentIndex, currentIndex + 1);
    }
    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item: DragItem, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY - 10) {
                return
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY + 10) {
                return
            }

            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
            return { id, index }
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0 : 1
    drag(drop(ref))

    const handleRemoveExercise = async () => {
        removeExercise(index, tabIndex);
        setShowConfirmModal(false);
    };

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }]
        ],
    };

    const formats = [
        'bold', 'italic', 'underline',
        'list', 'bullet'
    ];

    const popover = (
        <Popover id="popover-basic">
            <Card>
                <Card.Body className="font-15">
                    <p>{i18next.t(`exerciseCard.form.skipPauseDescription`)}</p>
                </Card.Body>
            </Card>
        </Popover>
    );
    return (
        <Card ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
            <ConfirmationModal
                show={showConfirmModal}
                title={i18next.t('exerciseCard.deleteExercise.title')}
                body={
                    <>
                        <p>{i18next.t('exerciseCard.deleteExercise.body')}</p>
                    </>
                }
                buttonPositive={i18next.t('generic.delete')}
                buttonNegative={i18next.t('generic.cancel')}
                onPositive={handleRemoveExercise}
                onNegative={() => setShowConfirmModal(false)}
            />
            <Formik
                enableReinitialize
                validateOnMount
                initialValues={exerciseObj}
                onSubmit={console.log}
                validate={(formValues) => {
                    const errors: any = {
                        name: null,
                        description: null,
                        sets: null,
                        time: null,
                        repetition: null,
                        setsColor: null,
                        timeColor: null,
                        repetitionColor: null,
                    };
                    if (isEmpty(formValues.name)) {
                        errors.name = i18next.t('exerciseCard.validation.name');
                    }

                    if (isEmpty(formValues.description) || formValues.description === "<p><br></p>") {
                        errors.description = i18next.t('exerciseCard.validation.description');
                    }

                    if (isNaN(formValues.sets) || formValues.sets < 1) {
                        // sets count is less than 1
                        errors.sets = i18next.t('exerciseCard.validation.lowerSets');
                        errors.setsColor = errorColor;
                    }

                    if (isNaN(formValues.sets) || formValues.sets > 5) {
                        // sets count is gretaer than 5
                        errors.sets = i18next.t('exerciseCard.validation.greaterSets');
                        errors.setsColor = warningColor;
                    }
                    let unit = isNaN(formValues.unit) ? formValues.unit : 'sec';
                    switch (formValues.exerciseType) {
                        case 'endurance':
                            delete errors.sets;
                            delete errors.setsColor;
                            unit = 'min';
                            formValues.unit = 'min';
                            break;
                        case 'static':
                            unit = 'sec';
                            formValues.unit = 'sec';
                    }

                    switch (unit) {
                        case 'sec':
                            if ((isNaN(formValues.time) || formValues.time < 1)) {
                                errors.time = i18next.t('exerciseCard.validation.lowerTime');
                            } else if ((isNaN(formValues.time) || formValues.time < 5)) {
                                errors.time = i18next.t('exerciseCard.validation.lowerTime');
                                errors.timeColor = warningColor;
                            }
                            else if ((isNaN(formValues.time) || formValues.time > 60)) {
                                errors.time = i18next.t('exerciseCard.validation.greaterTime');
                                errors.timeColor = warningColor;
                            }
                            formValues.repetition = formValues.time;
                            if ((isNaN(formValues.repetition) || formValues.repetition < 1)) {
                                errors.repetition = i18next.t('exerciseCard.validation.lowerRepetition');
                                errors.repetitionColor = errorColor;
                            }
                            else if ((isNaN(formValues.repetition) || formValues.repetition > 30)) {
                                errors.repetition = i18next.t('exerciseCard.validation.greaterRepetition');
                                errors.repetitionColor = warningColor;
                            }
                            break;
                        case 'min':
                            if ((isNaN(formValues.time) || formValues.time < 1)) {
                                errors.time = i18next.t('exerciseCard.validation.lowerTime');
                                errors.timeColor = errorColor;
                            }
                            else if ((isNaN(formValues.time) || formValues.time > 60)) {
                                errors.time = i18next.t('exerciseCard.validation.greaterTime');
                                errors.timeColor = warningColor;
                            }
                            formValues.repetition = formValues.time;
                            if ((isNaN(formValues.repetition) || formValues.repetition > 30)) {
                                errors.repetition = i18next.t('exerciseCard.validation.greaterRepetition');
                                errors.repetitionColor = warningColor;
                            }
                            else if ((isNaN(formValues.repetition) || formValues.repetition < 1)) {
                                errors.repetition = i18next.t('exerciseCard.validation.lowerRepetition');
                            }
                            break;
                        case 'reps':
                            if ((isNaN(formValues.repetition) || formValues.repetition > 30)) {
                                errors.repetition = i18next.t('exerciseCard.validation.greaterRepetition');
                                errors.repetitionColor = warningColor;
                            }
                            else if ((isNaN(formValues.repetition) || formValues.repetition < 1)) {
                                errors.repetition = i18next.t('exerciseCard.validation.lowerRepetition');
                                errors.repetitionColor = errorColor;
                            }
                            formValues.time = formValues.repetition;
                            if ((isNaN(formValues.time) || formValues.time < 1)) {
                                errors.time = i18next.t('exerciseCard.validation.lowerTime');
                                errors.timeColor = errorColor;
                            }
                            else if ((isNaN(formValues.time) || formValues.time < 5)) {
                                errors.time = i18next.t('exerciseCard.validation.lowerTime');
                                errors.timeColor = warningColor;
                            }
                            else if ((isNaN(formValues.time) || formValues.time > 60)) {
                                errors.time = i18next.t('exerciseCard.validation.greaterTime');
                                errors.timeColor = warningColor;
                            }

                            break;
                    }
                    if ((isNaN(formValues.time) || formValues.time < 0)) {
                        errors.time = i18next.t('exerciseCard.validation.invlaidValue');
                        errors.timeColor = errorColor;
                    }
                    if ((isNaN(formValues.repetition) || formValues.repetition < 0)) {
                        errors.time = i18next.t('exerciseCard.validation.invlaidValue');
                        errors.repetitionColor = errorColor;
                    }
                    if (formValues.exerciseType !== 'endurance' && (isNaN(formValues.sets) || formValues.sets < 0)) {
                        errors.sets = i18next.t('exerciseCard.validation.invlaidValue');
                        errors.setsColor = errorColor;
                    }
                    updateExercise(index, tabIndex, formValues);

                    return errors;
                }}
            >
                {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors, setFieldTouched, setFieldValue }) => (
                    <Form noValidate>
                        <Row bsPrefix="row p-3">
                            {!isEmpty(values.video) && isObject(values.video) && values.video.horizontalUrl ? (
                                <Col md={3} className="pl-1">
                                    <FlexDiv>
                                        <PaddingDiv className='hidden-xs' draggable={true} onDragStart={event => event.preventDefault()} >
                                            <Button variant="link" className="p-0 font-2x" onClick={() => { moveUp(index) }}> <i className="fas fa-angle-up"></i></Button>

                                            <br></br>
                                            <Button variant="link" className="p-0 font-2x" onClick={() => { moveDown(index) }}> <i className="fas fa-angle-down"></i></Button>
                                        </PaddingDiv>
                                        <Video className="react-player" loop preload='none' draggable={true} onDragStart={event => event.preventDefault()}
                                            controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                                            poster={!isEmpty(values.video.hThumbnail) ? values.video.hThumbnail : '/video_thumbnail.png'}
                                        >
                                            <source src={values.video.horizontalUrl} type="video/mp4" />

                                        </Video>
                                    </FlexDiv>
                                </Col>
                            ) : (
                                <></>
                            )}
                            <Col md={isEmpty(values.video) || values.exerciseType === 'relaxation' ? 8 : 5}>
                                <FlexDiv>
                                    {isEmpty(values.video) ? (
                                        <PaddingDiv>
                                            <br></br>
                                            <MoveSpan ref={ref}>
                                                <i className="fas fa-grip-lines"></i>
                                            </MoveSpan>
                                        </PaddingDiv>
                                    ) : (
                                        <></>
                                    )}
                                    <WidthDiv>
                                        {!values.isCustom ?
                                            <Form.Group draggable={true} onDragStart={event => event.preventDefault()} >
                                                <Field name="customDesc">
                                                    {({ field, meta }) =>
                                                    (
                                                        <>
                                                            <Form.Label>
                                                                <Switch className="react-switch" title={i18next.t('exerciseCard.form.customDescripton')}
                                                                    onColor="#ff2d1f"
                                                                    handleDiameter={15}
                                                                    uncheckedIcon={false}
                                                                    checkedIcon={false}
                                                                    height={18}
                                                                    width={32}
                                                                    onChange={(state: boolean) => {
                                                                        setFieldValue(field.name, state);
                                                                        !state ? setFieldValue("name", userLanguage === 'en' ? values.video.enName : values.video.deName) : setFieldValue("name", values.name);
                                                                        !state ? setFieldValue("description", userLanguage === 'en' ? values.video.enDescription : values.video.deDescription) : setFieldValue("description", values.description);
                                                                        setFieldTouched(field.name, state);
                                                                        setFieldTouched("name", state);
                                                                    }}
                                                                    checked={values.customDesc} />
                                                                <span className="ml-1">{i18next.t('exerciseCard.form.customDescripton')}</span>
                                                            </Form.Label>
                                                        </>
                                                    )
                                                    }
                                                </Field>

                                            </Form.Group> : null}
                                        <Form.Group draggable={true} onDragStart={event => event.preventDefault()} >
                                            <Form.Control
                                                disabled={!values.customDesc}
                                                type="text"
                                                name="name"
                                                id={`name-${index}-${tabIndex}`}
                                                placeholder={i18next.t('exerciseCard.form.name')}
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={!isEmpty(errors.name)}
                                                title={values.name}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className={!values.customDesc ? "disabled-box" : ""} draggable={true} onDragStart={event => event.preventDefault()} >
                                            <Field name="description" >
                                                {({ field, meta }) =>
                                                (
                                                    <>
                                                        <ReactQuill value={field.value} modules={modules}
                                                            formats={formats}
                                                            placeholder={i18next.t('exerciseCard.form.description')}
                                                            onChange={field.onChange(field.name)}
                                                            onBlur={() => setFieldTouched("description", true)}
                                                        />
                                                        {meta.touched && meta.error && (
                                                            <ErrorDiv>{meta.error}</ErrorDiv>
                                                        )}
                                                    </>
                                                )
                                                }
                                            </Field>

                                        </Form.Group>
                                    </WidthDiv>
                                </FlexDiv>
                            </Col>
                            {values.exerciseType === 'relaxation' ?
                                <Col md={1}>
                                    <span className="float-right pointer" onClick={() => setShowConfirmModal(true)}>
                                        <i className="fas fa-times"></i>
                                    </span>
                                </Col> :

                                <Col md={4}>
                                    <Row>
                                        {(() => {
                                            switch (values.exerciseType) {
                                                case 'endurance':
                                                    return (
                                                        <Col md={10} className='pr-0 pl-0'>
                                                            <Form.Group draggable={true} onDragStart={event => event.preventDefault()} >
                                                                <Form.Label>{i18next.t('exerciseCard.form.amount')}</Form.Label>
                                                                <InputGroup className={errors.timeColor ? (errors.timeColor === warningColor ? 'exerciseInValid' : 'exerciseInError') : null}>
                                                                    <Form.Control
                                                                        type="number"
                                                                        min="1"
                                                                        name="time"
                                                                        id={`time-${index}-${tabIndex}`}
                                                                        placeholder={i18next.t('exerciseCard.form.time')}
                                                                        value={values.time}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        isInvalid={!isEmpty(errors.time)}
                                                                        style={{ borderColor: !isEmpty(errors.timeColor) ? errors.timeColor.toString() : '' }}
                                                                    />
                                                                    <InputGroup.Prepend>
                                                                        <Button variant="primary" size="sm" className="btn-dd-normal">{i18next.t(`exerciseCard.form.dropdown.min`)}</Button>
                                                                    </InputGroup.Prepend>
                                                                    <Form.Control.Feedback key={'fgtime-' + values.time} type="invalid" style={{ color: !isEmpty(errors.timeColor) ? errors.timeColor.toString() : '' }}>
                                                                        {errors.time}
                                                                    </Form.Control.Feedback>
                                                                </InputGroup>
                                                            </Form.Group>
                                                        </Col>
                                                    );
                                                case 'static':
                                                    return (
                                                        <>
                                                            <Col md={3} className='pr-2 pl-0'>
                                                                <Form.Group className={errors.setsColor ? (errors.setsColor === warningColor ? 'exerciseInValid' : 'exerciseInError') : null} draggable={true} onDragStart={event => event.preventDefault()} >
                                                                    <Form.Label>{i18next.t('exerciseCard.form.sets')}</Form.Label>
                                                                    <Form.Control
                                                                        type="number"
                                                                        min="1"
                                                                        name="sets"
                                                                        id={`sets-${index}-${tabIndex}`}
                                                                        placeholder={i18next.t('exerciseCard.form.sets')}
                                                                        onBlur={handleBlur}
                                                                        value={values.sets}
                                                                        onChange={handleChange}
                                                                        className={!isEmpty(errors.setsColor) ? (errors.setsColor === warningColor ? 'exerciseInValid' : 'exerciseInError') : ''}
                                                                        isInvalid={!isEmpty(errors.sets)}
                                                                        style={{ borderColor: !isEmpty(errors.setsColor) ? errors.setsColor.toString() : '' }}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid" style={{ color: !isEmpty(errors.setsColor) ? (errors.setsColor === warningColor ? warningColor : errorColor) : '' }}>
                                                                        {errors.sets}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={7} className='pr-0 pl-0'>
                                                                <Form.Group draggable={true} onDragStart={event => event.preventDefault()} >
                                                                    <Form.Label>{i18next.t('exerciseCard.form.amount')}</Form.Label>
                                                                    <InputGroup className={!isEmpty(errors.timeColor) ? (errors.timeColor === warningColor ? 'exerciseInValid' : 'exerciseInError') : null}>
                                                                        <Form.Control
                                                                            type="number"
                                                                            min="1"
                                                                            name="time"
                                                                            id={`time-${index}-${tabIndex}`}
                                                                            placeholder={i18next.t('exerciseCard.form.time')}
                                                                            value={values.time}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            isInvalid={!isEmpty(errors.time)}
                                                                            style={{ borderColor: !isEmpty(errors.timeColor) ? errors.timeColor.toString() : '' }}
                                                                        />
                                                                        <InputGroup.Prepend>
                                                                            <Button variant="primary" size="sm" className="btn-dd-normal">{i18next.t(`exerciseCard.form.dropdown.sec`)}</Button>
                                                                        </InputGroup.Prepend>
                                                                        <Form.Control.Feedback key={'staticfgtime-' + values.time} type="invalid" style={{ color: !isEmpty(errors.timeColor) ? errors.timeColor.toString() : '' }}>
                                                                            {errors.time}
                                                                        </Form.Control.Feedback>
                                                                    </InputGroup>
                                                                </Form.Group>
                                                            </Col>
                                                        </>
                                                    );
                                                case 'dynamic':
                                                    return (
                                                        <>
                                                            <Col md={3} className='pr-2 pl-0'>
                                                                <Form.Group className={errors.setsColor ? (errors.setsColor === warningColor ? 'exerciseInValid' : 'exerciseInError') : null} draggable={true} onDragStart={event => event.preventDefault()} >
                                                                    <Form.Label>{i18next.t('exerciseCard.form.sets')}</Form.Label>
                                                                    <Form.Control
                                                                        type="number"
                                                                        min="1"
                                                                        name="sets"
                                                                        id={`sets-${index}-${tabIndex}`}
                                                                        placeholder={i18next.t('exerciseCard.form.sets')}
                                                                        onBlur={handleBlur}
                                                                        value={values.sets}
                                                                        onChange={handleChange}
                                                                        className={errors.setsColor ? (errors.setsColor === warningColor ? 'exerciseInValid' : 'exerciseInError') : ''}
                                                                        isInvalid={!isEmpty(errors.sets)}
                                                                        style={{ borderColor: !isEmpty(errors.setsColor) ? warningColor : '' }}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid" style={{ color: !isEmpty(errors.setsColor) ? (errors.setsColor === warningColor ? warningColor : errorColor) : '' }}>
                                                                        {errors.sets}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={7} className='pr-0 pl-0'>
                                                                {(() => {
                                                                    switch (values.unit) {
                                                                        case 'sec':
                                                                            return (<Form.Group draggable={true} onDragStart={event => event.preventDefault()} >
                                                                                <Form.Label>{i18next.t('exerciseCard.form.amount')}</Form.Label>
                                                                                <InputGroup className={!isEmpty(errors.timeColor) ? (errors.timeColor === warningColor ? 'exerciseInValid' : 'exerciseInError') : null}>
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        min="1"
                                                                                        name="time"
                                                                                        id={`time-${index}-${tabIndex}`}
                                                                                        placeholder={i18next.t('exerciseCard.form.time')}
                                                                                        value={values.time}
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        isInvalid={!isEmpty(errors.time)}
                                                                                        style={{ borderColor: !isEmpty(errors.timeColor) ? errors.timeColor.toString() : '' }}
                                                                                    />
                                                                                    <InputGroup.Prepend>
                                                                                        <DropdownButton variant="primary" as={ButtonGroup} size="sm" title={i18next.t(`exerciseCard.form.dropdown.${values.unit}`)} className="p-0 dropdown-time">
                                                                                            <Dropdown.Item onClick={() => { setFieldValue('unit', 'sec'); }}>{i18next.t('exerciseCard.form.dropdown.sec')}</Dropdown.Item>
                                                                                            <Dropdown.Item onClick={() => { setFieldValue('unit', 'reps'); }}>{i18next.t('exerciseCard.form.dropdown.reps')}</Dropdown.Item>
                                                                                        </DropdownButton>
                                                                                    </InputGroup.Prepend>
                                                                                    <Form.Control.Feedback key={'dynamicfgtime-' + values.time} type="invalid" style={{ color: errors.timeColor ? errors.timeColor.toString() : '' }}>
                                                                                        {errors.time}
                                                                                    </Form.Control.Feedback>
                                                                                </InputGroup>
                                                                            </Form.Group>);
                                                                        case 'reps':
                                                                            return (<Form.Group draggable={true} onDragStart={event => event.preventDefault()} >
                                                                                <Form.Label>{i18next.t('exerciseCard.form.amount')}</Form.Label>
                                                                                <InputGroup className={!isEmpty(errors.repetitionColor) ? (errors.repetitionColor === warningColor ? 'exerciseInValid' : 'exerciseInError') : null}>
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        min="1"
                                                                                        name="repetition"
                                                                                        id={`repetition-${index}-${tabIndex}`}
                                                                                        placeholder={i18next.t('exerciseCard.form.repetition')}
                                                                                        value={values.repetition}
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        isInvalid={!isEmpty(errors.repetition)}
                                                                                        style={{ borderColor: !isEmpty(errors.repetitionColor) ? errors.repetitionColor.toString() : '' }}
                                                                                    />
                                                                                    <InputGroup.Prepend>
                                                                                        <DropdownButton variant="primary" as={ButtonGroup} size="sm" title={i18next.t(`exerciseCard.form.dropdown.${values.unit}`)} className="p-0 dropdown-time">
                                                                                            <Dropdown.Item onClick={() => { setFieldValue('unit', 'sec'); }}>{i18next.t('exerciseCard.form.dropdown.sec')}</Dropdown.Item>
                                                                                            <Dropdown.Item onClick={() => { setFieldValue('unit', 'reps'); }}>{i18next.t('exerciseCard.form.dropdown.reps')}</Dropdown.Item>
                                                                                        </DropdownButton>
                                                                                    </InputGroup.Prepend>
                                                                                    <Form.Control.Feedback key={'dynamicfgreps-' + values.repetition} type="invalid" style={{ color: !isEmpty(errors.repetitionColor) ? errors.repetitionColor.toString() : '' }}>
                                                                                        {errors.repetition}
                                                                                    </Form.Control.Feedback>
                                                                                </InputGroup>
                                                                            </Form.Group>);
                                                                    }
                                                                })()}

                                                            </Col>

                                                        </>
                                                    );
                                            }
                                        })()}
                                        <Col md={2}>
                                            <br></br>
                                            <span className="float-right pointer" onClick={() => setShowConfirmModal(true)}>
                                                <i className="fas fa-times"></i>
                                            </span>
                                        </Col>
                                        <Col md={10} className='pr-0 pl-0'>
                                            <Form.Group draggable={true} onDragStart={event => event.preventDefault()} >
                                                <Form.Check type="checkbox" >
                                                    <Form.Check.Input className="mt-2" type="checkbox" defaultChecked={values.skipPause} name="skipPause" onChange={handleChange} />
                                                    <Form.Check.Label>{i18next.t(`exerciseCard.form.skipPause`)}&nbsp;
                                                        <OverlayTrigger transition={true} placement="bottom" overlay={popover}>
                                                            <Button variant="link" className="p-0"><i className="fas fa-info-circle"></i></Button>
                                                        </OverlayTrigger>
                                                    </Form.Check.Label>
                                                </Form.Check>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                            }
                        </Row>
                    </Form>
                )}
            </Formik>
        </Card>
    )
}
