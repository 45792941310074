import React, { FC, useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ControlsTooltip from './Controls/ControlsToolTip';
import CopyValueBox from '../CopyValueBox';
import { useParams } from 'react-router-dom';
import { generatePatientRoomURL } from '../../util/urls';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: block;
  min-width: 500px;
`;

const InputContainer = styled.div`
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  width: 100%;

  input {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    height: 39px;
    width: 250px;
  }
`;

const RoomPath: FC<{ timerActive?: boolean }> = ({ timerActive }) => {
  const { code } = useParams<{ code: string }>();
  const [showHidePath, setShowHidePath] = useState(false);
  const toggleShowHidePathButton = () => setShowHidePath(!showHidePath);

  return (
    <Wrapper>
      <InputContainer>
        <ButtonGroup>
          <ControlsTooltip id="tooltip-toggle-path" label="Link teilen">
            <Button onClick={toggleShowHidePathButton}>
              <i className="fas fa-link" />
            </Button>
          </ControlsTooltip>
          {showHidePath && <CopyValueBox value={code} valueCopy={generatePatientRoomURL(code)} />}
        </ButtonGroup>
      </InputContainer>
    </Wrapper>
  );
};

export default RoomPath;
