import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import FormGroup from '../../components/FormGroup';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { fetchFromRestAPI } from '../../util/api';
import { useAuth0 } from '../../util/auth0';
import { emitNotification } from '../../components/Notification';

const RechargeCredits = () => {
  const [email, setEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [creditHours, setCreditHours] = useState(null);
  const { getIdTokenClaims } = useAuth0();

  const addCredit = async () => {
    setIsLoading(true);
    const token = await getIdTokenClaims();
    await fetchFromRestAPI('/api/v1/credits', {
      method: 'POST',
      token,
      body: {
        therapistEmail: email,
        creditsInHours: parseInt(creditHours),
      },
    });
    setIsLoading(false);
  };

  const handleFormSubmit = (event) => {
    event.stopPropagation();
    event.preventDefault();
    addCredit()
      .then(() => {
        emitNotification(`Successfully added ${creditHours} hours of credit to therapist with email ${email}`);
      })
      .catch((err) => {
        emitNotification(`Failed to add credit to therapist with email ${email}, error: "${err}"`, { type: 'error' });
      })
      .finally(() => {
        setEmail('');
        setCreditHours('');
      });
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <Form.Row>
        <FormGroup as={Col}>
          <Form.Label>Email</Form.Label>
          <Form.Control
            value={email}
            type="email"
            required={true}
            disabled={isLoading}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </FormGroup>
      </Form.Row>
      <Form.Row>
        <FormGroup as={Col}>
          <Form.Label>Credits in Stunden</Form.Label>
          <Form.Control
            type="number"
            required={true}
            value={creditHours}
            disabled={isLoading}
            onChange={(e) => {
              setCreditHours(e.target.value);
            }}
          />
        </FormGroup>
      </Form.Row>
      <Form.Row>
        <Button variant="primary" type="submit" disabled={isLoading}>
          Credits hinzufügen
        </Button>
      </Form.Row>
    </Form>
  );
};

export default RechargeCredits;
