import i18next from 'i18next';
import { map } from 'lodash';
import React, { useState } from 'react';
import { Button, Card, Form, InputGroup, Table, Col, Row } from 'react-bootstrap';
import ConfirmationModal from '../../components/ConfirmationModal';
import { fetchFromRestAPI } from '../../util/api';
import { useAuth0 } from '../../util/auth0';

const DeletePatient = () => {
    const { getIdTokenClaims } = useAuth0();
    const [accountID, setAccountID] = useState('');
    const [deleteCount, setDeleteCount] = useState(0);
    const [deleteCountModal, setDeleteCountModal] = useState(0);
    const [showConfirm, setShowConfirm] = useState(false);
    const [patientData, setPatientData] = useState({
        patient: null, therapy: [], activeTrainingPlanCount: 0, appointmentCount: 0,
        fileUploadTokenCount: 0,
        filesCount: 0,
        scheduledSessionCount: 0,
        sessionCount: 0
    });
    const getPatientData = async () => {
        const token = await getIdTokenClaims();
        const body = { accountId: accountID };
        await fetchFromRestAPI(`/api/v1/admin/patient/verify-data`, { token: token, method: 'POST', body }).then((response) => {
            if (response && !response.error) {
                setPatientData(response);
            }
        });
    }
    const onDelete = async () => {
        const d = deleteCountModal + 1;
        if (d > 3) {
            setShowConfirm(false);
            const token = await getIdTokenClaims();
            const body = { accountId: accountID };
            await fetchFromRestAPI(`/api/v1/admin/patient/delete`, { token: token, method: 'DELETE', body }).then((response) => {
                setDeleteCountModal(0);
                setPatientData({
                    patient: null, therapy: [], activeTrainingPlanCount: 0, appointmentCount: 0,
                    fileUploadTokenCount: 0,
                    filesCount: 0,
                    scheduledSessionCount: 0,
                    sessionCount: 0
                });
                setAccountID('');
            });
        } else {
            setDeleteCountModal(d);
        }
    }
    return (
        <>
            <ConfirmationModal
                show={showConfirm}
                title={'Delete Patient ' + accountID}
                body={'Are you sure? you want to delete the patient? Action can not be reverted back! '}
                buttonPositive={i18next.t('generic.delete')}
                buttonNegative={i18next.t('generic.close')}
                onPositive={onDelete}
                onNegative={() => { setShowConfirm(false) }}
            />
            <Card>
                <Card.Body>
                    <Form.Group>
                        <InputGroup className="mb-3">
                            <Form.Control type="text" value={accountID} onChange={(e) => { setAccountID(e.target.value) }} ></Form.Control>
                            <Button variant='dark' className='pt-1 pr-3 pl-3 pb-0 rounded-0'
                                onClick={(e) => {
                                    if(accountID && accountID.length>0){
                                    getPatientData();
                                    }
                                }}
                            >
                                View Patient Data
                            </Button>
                        </InputGroup>
                    </Form.Group>
                </Card.Body>
            </Card>
            {patientData && patientData.patient ?
                <Card key={accountID} className="mt-2">
                    <Card.Body>
                        <h3>Patient Details : {accountID}</h3>
                        <Row>
                            <Col md={6}>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <td>Name</td>
                                            <td>{patientData.patient.firstName} {patientData.patient.lastName}</td>
                                        </tr>
                                        <tr>
                                            <td>isCodeVerified</td>
                                            <td>{patientData.patient.isCodeVerified}</td>
                                        </tr>
                                        <tr>
                                            <td>Insurance Plan</td>
                                            <td>{patientData.patient.insurancePlan ? patientData.patient.insurancePlan.planCode : ''}</td>
                                        </tr>
                                        <tr>
                                            <td>DOB</td>
                                            <td>{patientData.patient ? patientData.patient.dob : ''}</td>
                                        </tr>
                                        <tr>
                                            <td>Gender</td>
                                            <td>{patientData.patient ? patientData.patient.gender : ''}</td>
                                        </tr>
                                        <tr>
                                            <td>onSchedCustomerID</td>
                                            <td>{patientData.patient ? patientData.patient.onSchedCustomerID : ''}</td>
                                        </tr>

                                        {patientData && patientData.therapy ?
                                            <>
                                                <tr>
                                                    <td> <h4><strong>Therapy ({patientData.therapy.length})</strong></h4></td>
                                                    <td></td>
                                                </tr>
                                                {map(patientData.therapy, (therapy) => {
                                                    return (<>
                                                        <tr>
                                                            <td>Therapy ID</td>
                                                            <td>{therapy ? therapy.id : ''}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Therapist</td>
                                                            <td>{therapy && therapy.therapist ? therapy.therapist.email : ''}</td>
                                                        </tr>
                                                    </>)
                                                })}
                                            </>
                                            : null}
                                    </tbody>
                                </Table>
                            </Col>
                            <Col md={6}>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <td>Active Training Plan</td>
                                            <td>{patientData.activeTrainingPlanCount}</td>
                                        </tr>
                                        <tr>
                                            <td>Appointment Count</td>
                                            <td>{patientData.appointmentCount}</td>
                                        </tr>
                                        <tr>
                                            <td>File Upload Token Count</td>
                                            <td>{patientData.fileUploadTokenCount}</td>
                                        </tr>
                                        <tr>
                                            <td>Files Count</td>
                                            <td>{patientData.filesCount}</td>
                                        </tr>
                                        <tr>
                                            <td>Scheduled Session Count</td>
                                            <td>{patientData.sessionCount}</td>
                                        </tr>
                                        <tr>
                                            <td>Scheduled Session Count</td>
                                            <td>{patientData.sessionCount}</td>
                                        </tr>
                                        <tr>
                                            <td><Button variant='dark'
                                                onClick={(e) => {
                                                    setDeleteCount(0);
                                                    setDeleteCountModal(0);
                                                    setPatientData({
                                                        patient: null, therapy: [], activeTrainingPlanCount: 0, appointmentCount: 0,
                                                        fileUploadTokenCount: 0,
                                                        filesCount: 0,
                                                        scheduledSessionCount: 0,
                                                        sessionCount: 0
                                                    });
                                                    setAccountID('');
                                                }}
                                            >
                                                Cancel
                                            </Button></td>
                                            <td> <Button variant='primary'
                                                onClick={(e) => {
                                                    const d = deleteCount + 1;
                                                    if (d > 5) {
                                                        setShowConfirm(true)
                                                    } else {
                                                        setDeleteCount(d);
                                                    }
                                                }}
                                            >
                                                Delete Patient
                                            </Button></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card> : null
            }
        </>
    )
};
export default DeletePatient;