import React, { FC } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuth0 } from '../util/auth0';

const AuthenticatedRoute: FC<RouteProps> = ({ children, ...props }) => {
  const authObject = useAuth0();
  let isAuthenticated = authObject.isAuthenticated;
  const accessToken = localStorage.getItem('access_token');
  const accessTokenExpires = localStorage.getItem('expires_at');
  const now = Date.now();
  if (accessToken && accessTokenExpires && parseInt(accessTokenExpires) > parseInt(now.toString())) {
    isAuthenticated = true;
  }


  return <Route {...props} render={() => (isAuthenticated ? children : <Redirect to="/" />)} />;
};

export default AuthenticatedRoute;
