import React, { FC } from 'react';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import styled from 'styled-components';
import Participant from './Participalnt';

const Container = styled.div`
  overflow-x: auto;
  grid-area: mainParticipant;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
`;
const GridContainer = styled.div`
  display: inline-grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  width: 95vmin;
  height: calc(90vh - 4em);
  grid-gap: 1em;
  margin: 0 auto;
  justify-items: center;
`;

const ParticipantGrid: FC = () => {
  const {
    room: { localParticipant },
  } = useVideoContext();
  const participants = useParticipants();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();

  return (
    <Container id="grid-view-container">
      <GridContainer>
        <Participant
          participant={localParticipant}
          isSelected={selectedParticipant === localParticipant}
          isInGrid
          onClick={() => setSelectedParticipant(localParticipant)}
        />
        {participants.map((participant) => (
          <Participant
            key={participant.sid}
            participant={participant}
            isSelected={selectedParticipant === participant}
            isInGrid
            onClick={() => setSelectedParticipant(participant)}
          />
        ))}
      </GridContainer>
    </Container>
  );
};

export default ParticipantGrid;
