import React, { FunctionComponent, useState } from 'react';
import i18next from 'i18next';
import StyledModal from '../StyledModal';
import { Row, Col, Modal } from "react-bootstrap";
import moment from 'moment';
import PlanSelectComponent from '../PlanSelectComponent';
import { upgradeTherapistPlan } from '../../util/UserAccount';
import { useAuth0 } from '../../util/auth0';
type Props = {
    currentPlan: any;
    planList: any[];
    show: boolean;
    onPositive,
    onAbort,
};

const UpgradePlanModal: FunctionComponent<Props> = ({
    currentPlan,
    planList,
    show,
    onPositive,
    onAbort,
}) => {
    const { getIdTokenClaims } = useAuth0();
    const [isLoading, setIsLoading] = useState(false);
    const planFilter = currentPlan && !currentPlan.isCanceled ? planList.filter((x) => x._id !== currentPlan.planId) : planList;
    const handlePlanSelect = async (plan: any) => {
        setIsLoading(true);
        const token = await getIdTokenClaims();
        const body = { planId: plan._id, startDate: moment(moment().format('YYYY-MM-DDT00:00:00+00:00')).toDate(), endDate: moment(moment().endOf("month").format('YYYY-MM-DDT23:00:00+00:00')).toDate() }
        const profileResponse = await upgradeTherapistPlan(token, body);
        if (profileResponse && !profileResponse.error) {
            setIsLoading(false);
            onPositive();
        } else {
            setIsLoading(false);
        }

    }

    return (
        <StyledModal show={show} onHide={onAbort} dialogClassName={planFilter.length > 1 ? "modal-dialog-centered modal-lg" : "modal-dialog-centered"}>
            <React.Fragment>
                <Modal.Header closeButton>
                    <Modal.Title>{i18next.t('planDetails.upgradeModalTitle')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isLoading ? <Row>
                        <Col md={12} className="text-center">

                            <span className="loader">
                                <i className="fas fa-spin fa-cog fa-5x text-light" />
                            </span>

                        </Col>
                    </Row> :
                        <Row>
                            {planFilter.map((plan, i) => {
                                return <Col md={planFilter.length > 1 ? 6 : 12} key={`plan-${plan._id}${i}`} ><PlanSelectComponent plan={plan} selectPlan={(plan) => { handlePlanSelect(plan) }} buttonText={i18next.t('planDetails.upgradePlanButton')} /></Col>
                            })
                            }
                        </Row>}
                </Modal.Body>
            </React.Fragment>
        </StyledModal>
    );
};

export default UpgradePlanModal;
