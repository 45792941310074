import { useContext, useEffect } from 'react';
import { VideoContext } from '../../components/VideoProvider';
import useParticipantSentMessage from '../../components/VideoProvider/useParticipantSentMessage/useParticipantSentMessage';
import { fetchFromRestAPI } from '../../util/api';
import { useAuth0 } from '../../util/auth0';
import useDataTrack, { CallMessagingAction } from '../useDataTrack/useDataTrack';

export default function useShareFile() {
  const { sharedFile, setSharedFile } = useContext(VideoContext);

  const [sendMessage] = useDataTrack();

  const participantMessages = useParticipantSentMessage();

  const { getIdTokenClaims } = useAuth0();

  const requestShareFile = async (file) => {
    if (file) {
      const token = await getIdTokenClaims();
      const fileAccessToken = await fetchFromRestAPI(`/api/v1/files/${file.id}/access-token`, {
        method: 'POST',
        token,
      });
      sendMessage({
        action: CallMessagingAction.ACTION_SHARE_FILE,
        file,
        token: fileAccessToken.id,
      });
      setSharedFile({ file, token: fileAccessToken.id });
    } else {
      if (sharedFile) {
        const token = await getIdTokenClaims();
        await fetchFromRestAPI(`/api/v1/files/${sharedFile.file.id}/access-token/${sharedFile.token}`, {
          method: 'DELETE',
          token,
        });
      }
      sendMessage({
        action: CallMessagingAction.ACTION_SHARE_FILE,
        file,
      });
      setSharedFile(null);
    }
  };

  useEffect(() => {
    participantMessages.on(CallMessagingAction.ACTION_SHARE_FILE, (data) => {
      setSharedFile(data);
    });
    return () => {
      participantMessages.off(CallMessagingAction.ACTION_SHARE_FILE);
    };
  });

  return { sharedFileByHost: sharedFile, requestShareFile };
}
