import i18next from 'i18next';
import React, { FC } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import StyledModal from './StyledModal';

type Props = {
  show: boolean;
  onPositive: () => void;
  onNegative: () => void;
};

export const SupportModal: FC<Props> = ({ show, onPositive, onNegative }) => {
  return (
    <StyledModal show={show} onHide={() => onNegative()}>
      <Modal.Header closeButton>
        <Modal.Title>{i18next.t('loginpage.welcome')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p dangerouslySetInnerHTML={{ __html: i18next.t('header.contactModalBody') }} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={() => onPositive()}>
          {i18next.t('generic.close')}
        </Button>
      </Modal.Footer>
    </StyledModal>
  );
};

export default SupportModal;
