import copy from 'copy-to-clipboard';
import React, { FC } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

const StyledButtonGroup = styled(ButtonGroup)`
  display: inline-flex;
  align-items: stretch;
  input {
    height: inherit;
  }
`;

const IconButton = styled(Button)`
  white-space: nowrap;
`;

const CopyValueBox: FC<{
  value: string;
  valueCopy?: string;
  compact?: boolean;
}> = ({ value, valueCopy = null, compact = false }) => {
  const onCopyClickHandler = () => {
    valueCopy ? copy(valueCopy) : copy(value);
  };

  return (
    <StyledButtonGroup>
      <Form.Control disabled value={value} />
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onCopyClickHandler();
        }}
      >
        <i className="fas fa-clipboard" />
        {compact ? '' : '  Kopieren'}
      </IconButton>
    </StyledButtonGroup>
  );
};

export default CopyValueBox;
