import useParticipants from '../useParticipants/useParticipants';
import useVideoContext from '../useVideoContext/useVideoContext';

export default function useHostParticipant() {
  const participants = useParticipants();
  const {
    room: { localParticipant },
  } = useVideoContext();

  return [localParticipant, ...participants].find((p) => p.identity === 'host');
}
