import React, { FC } from 'react';
import styled from 'styled-components';
import { FaLock } from 'react-icons/fa';
import ActiveTimer from './Timer';
import RoomPath from './RoomPath';
import ParticipantsCounter from './ParticipantsCounter';

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-flow: wrap;
  left: 5rem;
  top: 2rem;
  z-index: 2;

  @media only screen and (max-width: 768px) {
    left: 3.5rem;
    top: 1.5rem;
  }

  @media only screen and (max-width: 350px) {
    display: none;
  }
`;

const LockerIcon = styled.div`
  color: #fff;
  font-size: 18px;
  line-height: 25px;
  margin-right: 5px;
`;

const RoomPathContainer = styled.div`
  position: absolute;
  display: flex;
  flex-flow: wrap;
  left: 14rem;
  top: 0.5rem;
  z-index: 2;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const RoomState: FC<{ timerActive?: boolean }> = ({ timerActive }) => {
  return (
    <Wrapper>
      <LockerIcon>
        <FaLock />
      </LockerIcon>
      <ActiveTimer isActive={timerActive} />
      <ParticipantsCounter />
      <RoomPathContainer>
        <RoomPath />
      </RoomPathContainer>
    </Wrapper>
  );
};

export default RoomState;
