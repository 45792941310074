import React, { FunctionComponent, useState, useEffect, memo } from 'react';
import i18next from 'i18next';
import { useAuth0 } from '../../util/auth0';
import { fetchFromRestAPI } from '../../util/api';
import styled from 'styled-components';
import PrimaryButton from '../../components/PrimaryButton';
import { ITherapy } from '../../util/types';
import { isEmpty, map, find } from 'lodash';
import { Form } from 'react-bootstrap';
import { emitNotification } from '../../components/Notification';

const Wrapper = styled.main`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 8px;
  .dropdown-item {
    font-size: 1.5rem;
  }
  .btn-secondary {
    border: 2px solid #ff695f;
    color: #ff695f;
  }
  h1 {
    font-weight: bold;
    font-size: 4rem;
  }
  .banner {
    display: flex;
    > div {
      max-width: 480px;
    }
  }
  .control-btns {
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: auto;
    .btn {
      margin-bottom: 8px;
    }
  }
  .patient-overview {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    > div {
      &:hover {
        background-color: #ececec;
        cursor: pointer;
        .name {
          font-weight: 700;
        }
      }
      display: flex;
      white-space: nowrap;
      padding: 4px 16px;
      align-items: center;
      background: white;
      &:first-of-type {
        padding-top: 8px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      &:last-of-type {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        padding-bottom: 8px;
      }
      .name {
        flex: 1;
        color: #444;
      }
      .btn-secondary {
        margin-left: 12px;
      }
      input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        height: 39px;
        width: 250px;
      }
    }
  }

  @media (max-width: 750px), (max-device-width: 815px) and (-webkit-min-device-pixel-ratio: 3) {
    .banner {
      h1 {
        font-size: 1.8rem;
      }
      flex-direction: column;
      .control-btns {
        align-items: flex-start;
      }
    }
    .patient-overview {
      > div {
        flex-direction: column;
        border-top: 1px solid #eee;
        &:first-of-type {
          border-top: 0;
        }
        .name {
          width: 100%;
          padding: 0;
        }
        .btn-group input {
          width: 100%;
        }
        .btn-secondary {
          margin: 8px auto 8px 0;
        }
      }
    }
  }
`;

const PromDiv = styled.div`
  background: #FFFFFF;
  border: 1px solid #CED4DA;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  margin-bottom: 10px;
`;

const ButtonDiv = styled.div`
  max-width : 200px;
`;

type PromsContainerProps = {
  therapy: ITherapy;
};

const PromsContainer: FunctionComponent<PromsContainerProps> = ({ therapy }) => {
  const { getIdTokenClaims } = useAuth0();
  const [promsData, setPromsData] = useState(null);
  const [isSaved, setIsSaved] = useState(false);
  const i18nextLng = localStorage.getItem('i18nextLng');

  useEffect(() => {
    const fetchDataAsync = async () => {
      if (therapy) {
        await fetchQuestions(therapy.id);
      }
    };
    fetchDataAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [therapy]);

  async function fetchQuestions(therapyId) {
    const token = await getIdTokenClaims();
    const promData = await fetchFromRestAPI(`/api/v1/promquestions/list/${therapyId}`, {
      token,
    });
    if (!isEmpty(promData.mapped)) {
      promData.master.forEach((questions) => {
        questions["isSelected"] = promData.mapped.promid.includes(questions._id);
      })
    }
    setPromsData(promData);
  }

  const saveProm = async () => {
    const body = {
      "therapyId": therapy.id,
      "promid": promsData.master.filter((x) => x.isSelected).map((x) => x._id)
    }

    const token = await getIdTokenClaims();
    await fetchFromRestAPI('/api/v1/promquestions/mapping', {
      method: 'POST',
      body: body,
      token,
    }).then(async () => {
      await fetchQuestions(therapy.id);
      setIsSaved(true);
      emitNotification(i18next.t('proms.saveText'));
    }).catch((err) => {
      console.error('Error saving prom', err);
      emitNotification('Error saving prom');
    });
  }

  const handleChecked = (event, index) => {
    promsData.master[index]["isSelected"] = event.target.checked;
    setPromsData(JSON.parse(JSON.stringify(promsData)));
  }

  const renderCard = (questions, index) => {
    const userLanguage = i18nextLng && i18nextLng.includes("-") ? i18nextLng.split("-")[0] : i18nextLng;
    const question = find(questions.prom, { 'lang': userLanguage })["question"];

    return (
      <PromDiv key={index}>
        <Form.Check bsPrefix="form-check mr-2" type="checkbox" defaultChecked={questions.isSelected}
          onChange={(Event) => handleChecked(Event, index)} />
        <b>{question.title} - {question.content}</b>
        <span className="text-muted ml-auto">{i18next.t(`proms.${questions.trigger}`)}</span>
      </PromDiv>
    );
  }

  return (
    <Wrapper>
      <div className="banner flex-column" dangerouslySetInnerHTML={{ __html: i18next.t('proms.introText') }} />
      {
        !isEmpty(promsData) && !isEmpty(promsData.master) ?
          map(promsData.master, (questions, i) => renderCard(questions, i))
          : <></>
      }
      <ButtonDiv>
        <PrimaryButton onClick={saveProm}>{i18next.t('proms.saveButton')}</PrimaryButton>
        {isSaved ? <span className="ml-2">{i18next.t('proms.saveText')}</span> : <></>}
      </ButtonDiv>
    </Wrapper>
  );
};

export default memo(PromsContainer);