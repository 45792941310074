import { box, randomBytes } from 'tweetnacl';
import {
    decodeUTF8,
    encodeUTF8,
    encodeBase64,
    decodeBase64
} from 'tweetnacl-util';
import * as crypto from 'crypto-js';
const newNonce = () => randomBytes(box.nonceLength);
export const generateKeyPair = (therapistSecretKey: string) => {
    var uint8array = decodeBase64(therapistSecretKey);
    const tKeyPair = box.keyPair.fromSecretKey(uint8array);
    return tKeyPair;
}
export const formatChatString = (formValue: string) => {
    let actualText = replaceAll(formValue, "<div><br /></div>", "");
    actualText = actualText.replace(/(^<br>+|<br>+$)/mg, '');//eslint-disable-line
    actualText = actualText.replace(/^(\ ?<br( \/)?>\ ?)+|(\ ?<br( \/)?>\ ?)+$/, ''); //eslint-disable-line
    return actualText;
}
function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
function replaceAll(str, find, replace) {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}
export const encrypt = (
    secretOrSharedKey: Uint8Array,
    msg: any,
    key?: Uint8Array
) => {
    const nonce = newNonce();
    const messageUint8 = decodeUTF8(msg);
    const encrypted = key
        ? box(messageUint8, nonce, key, secretOrSharedKey)
        : box.after(messageUint8, nonce, secretOrSharedKey);

    const fullMessage = new Uint8Array(nonce.length + encrypted.length);
    fullMessage.set(nonce);
    fullMessage.set(encrypted, nonce.length);

    return encodeBase64(fullMessage);
};

export const decrypt = (
    secretOrSharedKey: Uint8Array,
    messageWithNonce: string,
    key?: Uint8Array
) => {
    try {
        const messageWithNonceAsUint8Array = decodeBase64(messageWithNonce);
        const nonce = messageWithNonceAsUint8Array.slice(0, box.nonceLength);
        const message = messageWithNonceAsUint8Array.slice(
            box.nonceLength,
            messageWithNonce.length
        );
        const decrypted = key
            ? box.open(message, nonce, key, secretOrSharedKey)
            : box.open.after(message, nonce, secretOrSharedKey);

        if (!decrypted) {
            return "*************";
        }
        return encodeUTF8(decrypted);
    } catch (error) {
        return "*************";
    }
};

export function decryptText(encryptedText: string, SECRET_KEY: string) {
    try {
        var bytes = crypto.AES.decrypt(encryptedText, SECRET_KEY);
        return bytes.toString(crypto.enc.Utf8);
    } catch (error) {
        return null;
    }
}