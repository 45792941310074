import React, { FC } from 'react';
import { Participant, Track } from 'twilio-video';
import usePublications from '../../hooks/usePublications/usePublications';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import Publication from './Publication';
import styled from 'styled-components';
import useShareFile from '../../hooks/useShareFile/useShareFile';
import useHostParticipant from '../../hooks/useHostParticipant/useHostParticipant';
import PublicationAudio from './PublicationAudio';
import { useSharedAuthenticatedImage } from '../../hooks/useAuthenticatedImage';

interface ParticipantTracksProps {
  participant: Participant;
  disableAudio?: boolean;
  enableScreenShare?: boolean;
  videoPriority?: Track.Priority | null;
  hideVideo?: boolean;
  onlyAudio?: boolean;
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const SharedImage = styled.img`
  max-height: 100%;
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

/*
 *  The object model for the Room object (found here: https://www.twilio.com/docs/video/migrating-1x-2x#object-model) shows
 *  that Participant objects have TrackPublications, and TrackPublication objects have Tracks.
 *
 *  The React components in this application follow the same pattern. This ParticipantTracks component renders Publications,
 *  and the Publication component renders Tracks.
 */

const ParticipantTracks: FC<ParticipantTracksProps> = ({
  participant,
  disableAudio,
  enableScreenShare,
  videoPriority,
  hideVideo,
  onlyAudio,
}) => {
  const { room } = useVideoContext();
  const publications = usePublications(participant);
  const isLocal = participant === room.localParticipant;

  let filteredPublications;

  if (enableScreenShare && publications.some((p) => p.trackName.includes('screen'))) {
    filteredPublications = publications.filter((p) => !p.trackName.includes('camera') && p.kind !== 'data');
  } else {
    filteredPublications = publications.filter((p) => !p.trackName.includes('screen') && p.kind !== 'data');
  }

  const { sharedFileByHost } = useShareFile();
  const hostParticipant = useHostParticipant();

  const sharedFileObject = useSharedAuthenticatedImage(sharedFileByHost?.file?.url, sharedFileByHost?.token);

  if (onlyAudio) {
    filteredPublications = publications.filter((p) => p.kind === 'audio');

    return (
      <>
        {filteredPublications.map((publication) => (
          <PublicationAudio
            key={`${publication.trackSid}-audio`}
            publication={publication}
            participant={participant}
            isLocal={isLocal}
          />
        ))}
      </>
    );
  }

  if (participant === hostParticipant && sharedFileByHost?.file?.type?.indexOf('image') === 0) {
    filteredPublications = publications.filter((p) => p.kind === 'audio');
    return (
      <Wrapper>
        {filteredPublications.map((publication) => (
          <Publication
            key={publication.trackSid}
            publication={publication}
            participant={participant}
            isLocal={isLocal}
            disableAudio={disableAudio}
            videoPriority={videoPriority}
            hideVideo={hideVideo}
          />
        ))}
        <SharedImage src={sharedFileObject} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {filteredPublications.map((publication) => (
        <Publication
          key={publication.trackSid}
          publication={publication}
          participant={participant}
          isLocal={isLocal}
          disableAudio={disableAudio}
          videoPriority={videoPriority}
          hideVideo={hideVideo}
        />
      ))}
    </Wrapper>
  );
};

export default ParticipantTracks;
