import { isEmpty } from 'lodash';
import { useQuery } from 'react-query';
import { useAuth0 } from '../util/auth0';

export const useAuthenticatedImage = (key?: string): string | undefined => {
  const { getIdTokenClaims } = useAuth0();
  const { data } = useQuery(
    key,
    async () => {
      const token = await getIdTokenClaims();
      const response = await fetch(`/api/v1/files/images/${key}`, {
        headers: {
          Authorization: `Bearer ${token.__raw}`,
        },
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const blob = await response.blob();
      return window.URL.createObjectURL(blob);
    },
    {
      enabled: !isEmpty(key),
    }
  );
  return data;
};

export const useSharedAuthenticatedImage = (key?: string, token?: string): string | undefined => {
  const { data } = useQuery(
    key,
    async () => {
      const response = await fetch(`/api/v1/files/shared-images/${key}?token=${token}`);
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const blob = await response.blob();
      return window.URL.createObjectURL(blob);
    },
    {
      enabled: !isEmpty(key),
    }
  );
  return data;
};

export default useAuthenticatedImage;
