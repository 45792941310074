import moment from 'moment';
import 'moment/locale/de';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

export function formatDate(date) {
  return moment(date).locale('de').format('LLLL');
}

export function formatDateFromNow(date) {
  return moment(date).locale('de').fromNow();
}

export function formatDuration(duration) {
  return duration.format('h [Stunden], m [Minuten]');
}
export function daysRemaining(date:any) {
  var eventdate = moment(date);
  var todaysdate = moment();
  return eventdate.diff(todaysdate, 'days');
}

export function formatDateForChat(val) {
  const CONFIG_TIME_AGO = {
    future: "in %s",
    past: "%s ago",
    s: "secs",
    ss: "%ss",
    m: "a min",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "a day",
    dd: "%dd",
    M: "month",
    MM: "%dM",
    y: "year",
    yy: "%dY"
  };
  const localtz = moment.tz.guess();
  moment.updateLocale("en", { relativeTime: CONFIG_TIME_AGO });
  if (moment(val).isValid())
    return val.format('YYYYMMDD') === moment.parseZone().locale(localtz).format('YYYYMMDD') ? moment.parseZone(val).locale(localtz).format('HH:mm') : moment.parseZone(val).locale(localtz).fromNow();
  else
    return moment.parseZone(val).locale(localtz).fromNow();
};

export function isValidDate(dateString) {
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
    return false;
  var parts = dateString.split("/");
  var day = parseInt(parts[1], 10);
  var month = parseInt(parts[0], 10);
  var year = parseInt(parts[2], 10);

  if (year < 1000 || year > 3000 || month === 0 || month > 12)
    return false;

  var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
    monthLength[1] = 29;

  return day > 0 && day <= monthLength[month - 1];
};

export function addMinutes(time, minsToAdd) {
  function D(J) { return (J < 10 ? '0' : '') + J; };
  if (!time) {
    time = '00';
  }
  var piece = time.split(':');
  var mins = piece[0] * 60 + +piece[1] + +minsToAdd;

  return D(mins % (24 * 60) / 60 | 0) + ':' + D(mins % 60);

}
export function timeArray(interval, from, till) {
  var result = [];
  var start = new Date(1, 1, 1, from, 0);
  var end = new Date(1, 1, 1, till, 0);
  result.push(format(start));
  for (var d = start; d < end; d.setMinutes(d.getMinutes() + interval)) {
    const k = new Date(d);
    k.setMinutes(k.getMinutes() + 15);
    if (result.indexOf(format(k)) < 0)
      result.push(format(k));
  }
  return result;
}

export function format(inputDate) {
  var hours = inputDate.getHours();
  var minutes = inputDate.getMinutes();
  minutes = minutes < 10 ? ("0" + minutes) : minutes;
  hours = hours < 10 ? ("0" + hours) : hours;
  return hours + ":" + minutes;
}


export function getTimeZoneOffset(dateString) {
  var offset = new Date(dateString).getTimezoneOffset(), o = Math.abs(offset);
  return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
}