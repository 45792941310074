import React, { FC } from 'react';
import ControlsTooltip from './Controls/ControlsToolTip';
import styled from 'styled-components';
import useTimer from '../../hooks/useTimer/useTimer';

const Wrapper = styled.div`
  color: #fff;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 31px;
`;

const ActiveTimer: FC<{ isActive?: boolean }> = ({ isActive }) => {
  const [timerSeconds, isTimerActive, resetTimer, setTimerState] = useTimer();

  const getTimeFormatted = (seconds) => new Date(seconds * 1000).toISOString().substr(11, 8);

  if (!isActive && isTimerActive) {
    setTimerState(false);
    resetTimer();
  }

  if (isActive && !isTimerActive) {
    resetTimer();
    setTimerState(true);
  }

  return (
    <Wrapper>
      <ControlsTooltip id="tooltip-mute-all" label={'Time of session'}>
        <div>{getTimeFormatted(timerSeconds ? timerSeconds : 0)}</div>
      </ControlsTooltip>
    </Wrapper>
  );
};

export default ActiveTimer;
