import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import React from 'react';
import { Theme } from './ThemeProvider/useTheme/useTheme';

const StyledButton = styled(Button)`
  background-color: ${(p) => (p.theme.mode === Theme.FITNESS ? '#1B7B7C' : '#ff695f')};
  border-color: ${(p) => (p.theme.mode === Theme.FITNESS ? '#1B7B7C' : '#ff695f')};
`;

const PrimaryButton = (props) => {
  return <StyledButton {...props} />;
};

export default PrimaryButton;
