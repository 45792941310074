import React, { FC } from 'react';
import Button from 'react-bootstrap/Button';
import { FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa';
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import ControlsTooltip from './ControlsToolTip';

const ToggleAudioButton: FC<{ disabled?: boolean; showLabel?: boolean }> = ({ disabled, showLabel }) => {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  return (
    <ControlsTooltip id="tooltip-toggle-audio" label={isAudioEnabled ? 'Mikrofon ausschalten' : 'Mikrofon einschalten'}>
      <Button onClick={toggleAudioEnabled} disabled={disabled}>
        {isAudioEnabled ? <FaMicrophone /> : <FaMicrophoneSlash />}
        {showLabel ? (isAudioEnabled ? ' Mikro ausschalten' : ' Mikro einschalten') : ''}
      </Button>
    </ControlsTooltip>
  );
};

export default ToggleAudioButton;
