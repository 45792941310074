import Button from 'react-bootstrap/Button';
import { FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa';
import React, { FC, useState } from 'react';
import ControlsTooltip from './ControlsToolTip';
import useMuteAllAudio from '../../../hooks/useMuteAllAudio/useMuteAllAudio';
import styled from 'styled-components';
import useParticipants from '../../../hooks/useParticipants/useParticipants';
import { emitNotification } from '../../Notification';

const Wrapper = styled.div`
  display: inline-block;
  margin-left: 0.5em;
`;

const MuteAllAudioButton: FC<{ disabled?: boolean }> = ({ disabled }) => {
  const [muteAll] = useMuteAllAudio();
  const [allMuted, setAllMuted] = useState(false);
  const participants = useParticipants();

  const muteAllClickAction = () => {
    setAllMuted(true);
    muteAll();
    emitNotification(`Alle Teilnehmer wurden stumm geschalten`);
    return true;
  };

  const anyParticipantUnmuted = () => {
    let anyUnmuted = false;

    participants.forEach((participant) => {
      if (participant.audioTracks) {
        Array.from(participant.audioTracks.values()).forEach((track) => {
          if (track.isTrackEnabled) {
            anyUnmuted = true;
          }
        });
      }
    });

    return anyUnmuted;
  };

  const isDisabled = () => disabled || !anyParticipantUnmuted() || allMuted;
  const unmuteAllWhenTrackEnabled = (track) => {
    if (track && track.kind === 'audio') {
      setAllMuted(false);
    }
  };

  participants.forEach((participant) => {
    if (!participant.listeners('trackEnabled').filter((f) => f.name === 'unmuteAllWhenTrackEnabled').length) {
      participant.on('trackEnabled', unmuteAllWhenTrackEnabled);
    }
  });

  return (
    <Wrapper>
      <ControlsTooltip id="tooltip-mute-all" label={'Alle auf stumm'}>
        <Button onClick={muteAllClickAction} disabled={isDisabled()}>
          {true ? <FaMicrophone /> : <FaMicrophoneSlash />} Alle auf stumm
        </Button>
      </ControlsTooltip>
    </Wrapper>
  );
};

export default MuteAllAudioButton;
