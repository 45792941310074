import { useCallback } from 'react';
import { createGlobalState } from 'react-hooks-global-state';

const { useGlobalState } = createGlobalState({ roomView: 'standard' });

export default function useRoomView() {
  const [roomView, setRoomView] = useGlobalState('roomView');

  const changeRoomView = useCallback(
    (view) => {
      setRoomView(view);
    },
    [setRoomView]
  );

  return [roomView, changeRoomView] as const;
}
