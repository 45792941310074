import i18next from 'i18next';
import { map } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import CardDeck from 'react-bootstrap/CardDeck';
import styled from 'styled-components';
import { formatDate } from '../util/dateTimeUtils';
import ImageCard from './ImageCard';
import SecondaryButton from './SecondaryButton';
import CreateManualSessionForm, { Session as SessionType } from './sessions/CreateManualSessionForm';
import StyledModal from './StyledModal';

const Session = styled.div`
  margin-bottom: 1.6rem;
  line-height: 1.8;
`;

const SessionListWrapper = styled.div`
  background-color: #ffffff;
  padding: 0.8rem 1.6rem;
  h2 {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.8;
    margin: 0;
  }
`;

const TrainingPictureCountLabel = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
`;

const UpcomingSessions = styled.div`
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px #dfdfdf solid;
`;

const SessionList = ({
  patient,
  scheduledSessions,
  onCreateTrainingPlan,
  onRemoveFile,
  onScheduleNewSession,
  onCreateManualSession,
}) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <SessionListWrapper>
      <UpcomingSessions>
        {/* <SecondaryButton onClick={() => setShowModal(true)}>{i18next.t('sessionList.createButton')}</SecondaryButton> */}
        <StyledModal show={showModal} onHide={() => setShowModal((s) => !s)}>
          <Modal.Header closeButton>
            <Modal.Title>{i18next.t('sessionList.modalTitle')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CreateManualSessionForm
              onCreateManualSession={async (session: SessionType) => {
                const submissionErrors = await onCreateManualSession(session);
                setShowModal(false);
                return submissionErrors;
              }}
            />
          </Modal.Body>
        </StyledModal>
      </UpcomingSessions>
      {false && (
        /* currently deactivated US335, code not yet cleaned up */
        <UpcomingSessions>
          <h2>Anstehende Termine</h2>
          {scheduledSessions
            .filter((session) => {
              return new Date(session.start) > new Date() && session.patient === patient.id;
            })
            .map((session) => {
              return <div key={session.id}>{formatDate(session.start)}</div>;
            })}
          <SecondaryButton onClick={onScheduleNewSession}>
            <i className="fa fa-calendar" />
            Neuen Termin anlegen
          </SecondaryButton>
        </UpcomingSessions>
      )}

      { patient.sessions.length ? 
        <h2>Vergangene Termine</h2> : <></>
        // <span className="text-muted">Keine Termine</span>
      }

      {patient.sessions.map((session, idx) => {
        const startMoment = moment(session.start);
        let sessionDuration;

        if (session.deleted) return null;

        if (session.end) {
          const endMoment = moment(session.end);
          sessionDuration = endMoment.diff(startMoment, 'minutes');
        }

        const images = map(session.images, (image) => (
          <ImageCard onClick={() => onRemoveFile(image)} key={image.id} id={image.id} imageSrc={image.url} />
        ));

        return (
          <Session key={idx}>
            <div>
              {formatDate(session.start)}{' '}
              {sessionDuration ? `(${sessionDuration} Minute${sessionDuration === 1 ? '' : 'n'})` : ''}{' '}
              {i18next.t(session.roomCode ? 'sessionList.online' : 'sessionList.offline')}
            </div>
            <TrainingPictureCountLabel>{images.length} Trainingsfotos</TrainingPictureCountLabel>
            <CardDeck>{images}</CardDeck>
            {/* <SecondaryButton onClick={() => onCreateTrainingPlan(session.id)}>
              {session.trainingsplan && session.trainingsplan.exercises
                ? 'PDF Trainingsplan editieren'
                : 'PDF Trainingsplan erstellen'}
            </SecondaryButton> */}
          </Session>
        );
      })}
    </SessionListWrapper>
  );
};

export default SessionList;
