import { Field, Formik } from 'formik';
import i18next from 'i18next';
import { isEmpty, map } from 'lodash';
import React, { useState, FC, useEffect } from 'react';
import { Alert, Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import moment from 'moment';
import 'moment/locale/de';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import styled from 'styled-components';
import { getTimeZoneOffset, timeArray } from '../../util/dateTimeUtils';
import { fetchFromRestAPI } from '../../util/api';
import { useAuth0 } from '../../util/auth0';
const TIME_PATTERN = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

export type appointmentData = {
  date: string;
  startTime: string;
  endTime: string;
  duration: number;
};
const ErrorDiv = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`;
const RowDiv = styled.div`
max-height: 47vh;
overflow-y: auto;
overflow-x: hidden;
`;

type Props = {
  therapy: any,
  onCreateAppointment: (appointment: appointmentData) => Promise<void>;
};
export const CreateAppointmentForm: FC<Props> = ({ therapy, onCreateAppointment }) => {
  const { getIdTokenClaims } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const [displayDayPicker, setDisplayDayPicker] = useState(false);
  const [, setAdays] = useState([]);
  const [allDays, setAllDays] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
  const [selectedDuration, setDuration] = useState(15);
  const today = new Date();
  const twoMonth = moment().add(2, 'months');
  const twoMonthDate = twoMonth.toDate();
  const nearestFutureMinutes = (interval, someMoment) => {
    const roundedMinutes = Math.ceil(someMoment.minute() / interval) * interval;
    return someMoment.clone().minute(roundedMinutes).second(0);
  }

  const checkIfBooked = (aForThatDay: any[], dateString: any, timeString: any, duration: number, tzOffset: any) => {
    const isBooked = [];
    const dStart = moment.parseZone(dateString + "T" + timeString);
    const dEnd = moment.parseZone(dateString + "T" + timeString).add(duration, "minutes");
    aForThatDay.forEach(element => {
      const isBooked0 = moment.parseZone(dStart).isBetween(element.startDateTime, element.endDateTime) || moment.parseZone(element.startDateTime).isSame(dStart);
      isBooked.push(isBooked0);
      const isBooked15 = moment.parseZone(element.startDateTime).isBetween(dStart, dEnd);
      isBooked.push(isBooked15);
    });
    return isBooked.indexOf(true) >= 0;
  }
  useEffect(() => {
    const getAvailabilityDays = async () => {
      setIsLoading(true);
      const token = await getIdTokenClaims();
      const timeSlot = [];
      let appointmentList = [];
      await fetchFromRestAPI(`/api/v1/appointment/therapist`, { token: token }).then((appointmentData) => {
        if (appointmentData && appointmentData.length) {
          appointmentList = appointmentData;
        }

        const tzOffset = getTimeZoneOffset(selectedDate);
        let tArray = timeArray(15, 6, 22);
        const stopDateTime = selectedDate + "T22:00";
        const aForThatDay = appointmentList.filter(x => moment.parseZone(x.startDateTime).format('YYYY-MM-DD') === moment.parseZone(selectedDate).format('YYYY-MM-DD'));
        for (let index = 0; index <= tArray.length - 1; index++) {
          let indexID = 1;
          switch (selectedDuration) {
            case 30: indexID = 2; break;
            case 45: indexID = 3; break;
            case 60: indexID = 4; break;
          }
          if (tArray[index] && tArray[index + indexID]) {
            const endDate = selectedDate + "T" + tArray[index + indexID] + ":00";
            const startDate = selectedDate + "T" + tArray[index] + ":00";
            if (moment(endDate).isValid() && moment.parseZone(endDate).isSameOrBefore(moment.parseZone(stopDateTime))) {
              timeSlot.push(
                {
                  "startDateTime": startDate,
                  "endDateTime": endDate,
                  "displayTime": moment.parseZone(startDate).format('HH:mm'),
                  "duration": selectedDuration,
                  "isBooked": moment.parseZone(startDate).isSameOrAfter(moment.parseZone()) ? checkIfBooked(aForThatDay, selectedDate, tArray[index] + ":00", selectedDuration, tzOffset) : false
                });
            }
          }
        }
        setAllDays(timeSlot);
        setIsLoading(false);
      }).catch((err) => {
        console.error('Error all appointment', err);
        setAllDays(timeSlot);
        setIsLoading(false);
      });

    };
    getAvailabilityDays()
  }, [selectedDuration, selectedDate, getIdTokenClaims, therapy])
  const initialValues = { date: moment().format('DD-MM-YYYY'), startTime: nearestFutureMinutes(15, moment()).format('HH:mm'), endTime: '', duration: 15 };
  return (
    <Formik
      enableReinitialize
      validateOnMount={true}
      initialValues={initialValues}
      onSubmit={async ({ date, startTime, endTime, duration }, { setSubmitting }) => {
        await onCreateAppointment({ date, startTime, endTime, duration });
        setSubmitting(false);
      }}
      validate={({ date, startTime, endTime }) => {
        const currentDateTime = moment();
        const errors: { date?: string; startTime?: string; endTime?: string } = {};
        if (isEmpty(date)) {
          errors.date = i18next.t('createAppointmentForm.validation.date');
        }
        if (!TIME_PATTERN.test(startTime)) {
          errors.startTime = i18next.t('createAppointmentForm.validation.startTime');
        }
        if (!TIME_PATTERN.test(endTime)) {
          errors.endTime = i18next.t('createAppointmentForm.validation.endTime');
        }
        if (!errors.startTime && !errors.endTime) {
          if (startTime > endTime) {
            errors.endTime = i18next.t('createAppointmentForm.validation.startTimeEndTime');
          }
        }
        if (!errors.date) {
          const dateSelected = moment(date, 'DD-MM-YYYY');
          const minDate = moment();
          if (dateSelected.diff(minDate, 'days') < 0) {
            errors.date = i18next.t('trainingPlan.validation.pastDate');
          }
          const maxDate = moment().add(2, 'months');
          if (dateSelected.diff(maxDate, 'days') > 0) {
            errors.date = i18next.t('trainingPlan.validation.futureDate');
          }
        }
        if (!errors.date && !errors.startTime) {
          const startDateTime = moment(date + ' ' + startTime, 'DD-MM-YYYY HH:mm');
          if (startDateTime.diff(currentDateTime, 'minutes') < 0) {
            errors.startTime = i18next.t('createAppointmentForm.validation.pastTime');
          }

        }
        if (!errors.date && !errors.endTime && !errors.startTime) {
          const startDateTime = moment(date + ' ' + startTime, 'DD-MM-YYYY HH:mm');
          const endDateTime = moment(date + ' ' + endTime, 'DD-MM-YYYY HH:mm');
          if (endDateTime.diff(startDateTime, 'minutes') < 0) {
            errors.endTime = i18next.t('createAppointmentForm.validation.startTimeEndTime');
          }
        }

        return errors;
      }}
    >
      {({ values, handleChange, errors, dirty, isValid, isSubmitting, handleSubmit, setFieldValue, setFieldTouched, setFieldError }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group controlId="date">
            <Form.Label>{i18next.t('createAppointmentForm.labels.date')}</Form.Label>
            <Form.Group>
              <Field name="date">
                {({ field, meta }) =>
                (
                  <>
                    <InputGroup className="mb-3" onClick={() => { setDisplayDayPicker(!displayDayPicker) }}>
                      <Form.Control disabled={true} value={field.value} ></Form.Control>
                      <Button variant='dark' className='pt-1 pr-3 pl-3 pb-0 rounded-0'
                        onClick={(e) => {
                          e.stopPropagation();
                          setDisplayDayPicker(!displayDayPicker)
                        }}
                      >
                        <i className="fas fa-calendar" />
                      </Button>
                    </InputGroup>
                    {displayDayPicker ?
                      <DayPicker
                        numberOfMonths={2}
                        pagedNavigation={true}
                        fromMonth={today}
                        toMonth={twoMonthDate}
                        disabledDays={[{ before: today, after: twoMonthDate }, new Date(parseInt(values.date.split('-')[2]), parseInt(values.date.split('-')[1]) - 1, parseInt(values.date.split('-')[0]))]}
                        firstDayOfWeek={1}
                        onDayClick={(day) => {
                          setAllDays([]);
                          setAdays([]);
                          setFieldValue(field.name, moment(day).format('DD-MM-YYYY'));
                          setSelectedDate(moment(day).format('YYYY-MM-DD'));
                          setDisplayDayPicker(!displayDayPicker);
                        }} /> : null}
                    {errors && errors.date && errors.date.length > 0 ? (
                      <ErrorDiv >{errors.date}</ErrorDiv>
                    ) : null}
                  </>

                )
                }
              </Field>

            </Form.Group>
          </Form.Group>


          <Form.Group controlId="startTime">
            <Form.Label>{i18next.t('createAppointmentForm.labels.selectDuration')}</Form.Label>
            <div className="mb-3 ml-2">
              <Form.Check
                defaultChecked={values.duration === 15 ? true : false}
                inline
                label={'15 ' + i18next.t('createAppointmentForm.labels.min')}
                name="durationGroup"
                type='radio'
                onClick={() => {
                  setAllDays([]);
                  setAdays([]);
                  setFieldValue('duration', 15);
                  setDuration(15);
                  setFieldValue('startTime', '');
                  setFieldValue('endTime', '');
                }}
              />
              <Form.Check
                inline
                defaultChecked={values.duration === 30 ? true : false}
                label={'30 ' + i18next.t('createAppointmentForm.labels.min')}
                name="durationGroup"
                type='radio'
                onClick={() => {
                  setAllDays([]);
                  setAdays([]);
                  setFieldValue('duration', 30);
                  setDuration(30);
                  setFieldValue('startTime', '');
                  setFieldValue('endTime', '');
                }}
              />
              <Form.Check
                inline
                defaultChecked={values.duration === 45 ? true : false}
                label={'45 ' + i18next.t('createAppointmentForm.labels.min')}
                name="durationGroup"
                type='radio'
                onClick={() => {
                  setAllDays([]);
                  setAdays([]);
                  setFieldValue('duration', 45);
                  setDuration(45);
                  setFieldValue('startTime', '');
                  setFieldValue('endTime', '');
                }}
              />
              <Form.Check
                inline
                defaultChecked={values.duration === 60 ? true : false}
                label={'60 ' + i18next.t('createAppointmentForm.labels.min')}
                name="durationGroup"
                type='radio'
                onClick={() => {
                  setAllDays([]);
                  setAdays([]);
                  setFieldValue('duration', 60);
                  setDuration(60);
                  setFieldValue('startTime', '');
                  setFieldValue('endTime', '');
                }}
              />
            </div>
          </Form.Group>

          <Form.Group controlId="endTime">
            <Form.Label>{i18next.t('createAppointmentForm.labels.startTime')}</Form.Label>
            <RowDiv id="create-appointment-form">
              <Row className="mr-3 ml-0">
                {isLoading ? <Col md={12}>Loading...</Col> : <Col md={11} className='p-0'>

                  {allDays && allDays.length > 0 ?
                    <Row className='nopadding'>
                      {map(allDays, (a, index) => {
                        return (<Col md={3} xs={4} key={'stall-' + index} className='pr-0'>
                          <Button size='sm' className='m-1 form-control p-0 bold-border-2 ' disabled={a.isBooked} title={a.isBooked ? '' + i18next.t('createAppointmentForm.labels.bookedTooltip') : moment.parseZone(a.startDateTime).format('HH:mm') + ' - ' + moment.parseZone(a.endDateTime).format('HH:mm')}
                            variant={a.isBooked ? 'outline-dark' : (values.startTime === moment.parseZone(a.startDateTime).format('HH:mm') ? 'primary' : 'outline-primary')}
                            onClick={async (e) => {
                              await setFieldValue('startTime', moment.parseZone(a.startDateTime).format('HH:mm'));
                              await setFieldValue('endTime', moment.parseZone(a.endDateTime).format('HH:mm'));
                            }}
                          >{moment.parseZone(a.startDateTime).format('HH:mm')}</Button></Col>);
                      })}
                    </Row>
                    : <Col md={12}>{i18next.t('createAppointmentForm.labels.noAvailableTime')}</Col>}
                </Col>
                }
              </Row>

            </RowDiv>
          </Form.Group>
          {values.endTime ? <Alert variant='dark'>
            {i18next.t('createAppointmentForm.labels.selectedTime')} {values.endTime ? <span>{values.date} {i18next.t('createAppointmentForm.labels.selectedTimeAt')} {values.startTime} - {values.endTime}</span> : null}

          </Alert> : null}
          {errors && errors.startTime && errors.startTime.length > 0 ? (
            <ErrorDiv>{errors.startTime}</ErrorDiv>
          ) : null}
          <Button type="submit" disabled={!dirty || !isValid || isSubmitting}>
            {i18next.t('createAppointmentForm.labels.submit')}
          </Button>
        </Form>
      )
      }
    </Formik >
  );
};

export default CreateAppointmentForm;
