import i18next from 'i18next';
import React, { FC, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { ITherapy } from '../util/types';
import FormGroup from './FormGroup';
import ContraindicationModal from './ContraindicationModal';
const CreateRoomFormComponent = styled.div`
  .form-check-label {
    padding-left: 1rem;
  }
`;

const FormFooter = styled.div`
  display: flex;
  flex-flow: row-reverse;
`;

const CreateTherapyForm: FC<{
  onUpdate: (therapy: ITherapy) => void;
  therapy?: ITherapy;
  onUserDataChange: (filledData: any) => void;
  filledData: any;
}> = ({ onUpdate, therapy, onUserDataChange, filledData }) => {
  const shouldSendInviteEmail = false;
  const isPatientProfile = therapy?.patientProfile || false;
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState((therapy?.patientProfile && therapy.patient.email) || therapy?.email || filledData?.email);
  const [firstName, setFirstName] = useState(
    (therapy?.patientProfile && therapy.patient.firstName) || therapy?.firstName || filledData?.firstName
  );
  const [lastName, setLastName] = useState(
    (therapy?.patientProfile && therapy.patient.lastName) || therapy?.lastName || filledData?.lastName
  );

  const isEdit = !!therapy;
  const [validated, setValidated] = useState(false);
  const [showContraindication, setShowContraindication] = useState(false);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      setValidated(true);
      setShowContraindication(true);
    }
  };

  const handleContraindication = () => {
    setShowContraindication(false);
    setIsLoading(true);
    const patientProfile = true;
    const isMobileRole = true;
    const inviteMessage = '';
    const seniorMode = false;

    onUpdate({
      ...therapy,
      email,
      firstName,
      lastName,
      shouldSendInviteEmail,
      inviteMessage,
      seniorMode,
      patientProfile,
      isMobileRole,
    });
  }

  // const handleIsMobileRoleCheckboxChanged = (checked) => {
  //   setIsMobileRole(checked);
  // };
  return (
    <CreateRoomFormComponent>
      <p dangerouslySetInnerHTML={{ __html: i18next.t('createPatientForm.intro') }} />
      <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
        <Form.Row>
          <FormGroup as={Col}>
            <Form.Label>{i18next.t('createPatientForm.firstName')}</Form.Label>
            <Form.Control
              disabled={isPatientProfile}
              required
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
                onUserDataChange({ firstName: e.target.value, lastName: lastName, email: email });
              }}
            />
            <Form.Control.Feedback type="invalid">
              {i18next.t('createPatientForm.invalidFirstName')}
            </Form.Control.Feedback>
          </FormGroup>
          <FormGroup as={Col}>
            <Form.Label>{i18next.t('createPatientForm.lastName')}</Form.Label>
            <Form.Control
              disabled={isPatientProfile}
              required
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
                onUserDataChange({ firstName: firstName, lastName: e.target.value, email: email });
              }}
            />
            <Form.Control.Feedback type="invalid">
              {i18next.t('createPatientForm.invalidLastName')}
            </Form.Control.Feedback>
          </FormGroup>
        </Form.Row>
        <Form.Row>
          <FormGroup as={Col}>
            <Form.Label>{i18next.t('createPatientForm.email')}</Form.Label>
            <Form.Control
              type="email"
              required
              disabled={therapy?.patientProfile && therapy.patient.email ? true : false}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                onUserDataChange({ firstName: firstName, lastName: lastName, email: e.target.value });
              }}
            />
            <Form.Control.Feedback type="invalid">{i18next.t('createPatientForm.invalidEmail')}</Form.Control.Feedback>
          </FormGroup>
        </Form.Row>

        <FormFooter>
          <Button disabled={isLoading} variant="primary" type="submit">
            {isLoading
              ? i18next.t('generic.loading')
              : i18next.t(
                isEdit
                  ? 'generic.save'
                  : shouldSendInviteEmail
                    ? 'createPatientForm.createRoomAndSendEmail'
                    : 'createPatientForm.createRoom'
              )}
          </Button>
        </FormFooter>
      </Form>
      <ContraindicationModal
        show={showContraindication}
        title={i18next.t('createPatientForm.contraindication.title')}
        titleIntro={i18next.t('createPatientForm.contraindication.titleIntro')}
        body={i18next.t('createPatientForm.contraindication.body')}
        buttonPositive={i18next.t('createPatientForm.contraindication.save')}
        buttonNegative={i18next.t('generic.back')}
        onPositive={handleContraindication}
        onNegative={() => setShowContraindication(false)}
        initValue={false}
      ></ContraindicationModal>
    </CreateRoomFormComponent>
  );
};

export default CreateTherapyForm;
