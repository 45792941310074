import { isIOS } from './deviceClassification';
import { VideoTrack } from 'twilio-video';

const REQUESTED_HD_VIDEO_DIMENSIONS = {
  width: 1280,
  height: 720,
  facingMode: 'user',
};

const SCREENSHOT_IMAGE_QUALITY = 0.8;

async function getHDUserMediaDeviceStream() {
  const constraints = {
    audio: false,
    video: REQUESTED_HD_VIDEO_DIMENSIONS,
  };
  try {
    return navigator.mediaDevices.getUserMedia(constraints);
  } catch (e) {
    console.warn(`Requesting media stream with HD dimensions for screenshot failed. Retry without constraints`, e);
  }

  try {
    return navigator.mediaDevices.getUserMedia();
  } catch (e) {
    console.warn(`Requesting media stream for screenshot failed`, e);
  }
}

export interface VideoScreenshotDataObject {
  dataUrl: string;
  width: number;
  height: number;
  blob: Blob;
}

async function getNewVideoForScreenshotCapture() {
  const stream = await getHDUserMediaDeviceStream();
  // create temporary (hidden) video element and canvas to draw image
  const video = document.createElement('video');
  video.setAttribute('autoplay', '');
  video.setAttribute('muted', '');
  video.setAttribute('playsinline', '');
  // start video
  video.srcObject = stream;
  await video.play();
  return video;
}

function getLocalParticipantVideo() {
  return document.getElementsByClassName('local-participant').item(0).children.item(0).children.item(0);
}

function getVideoFromVideoTrack(track: VideoTrack) {
  return track._attachments.values().next().value;
}

export default async function generateVideoScreenshot(track?: VideoTrack): Promise<VideoScreenshotDataObject> {
  let createdNewVideoForScreenshot;
  let video;
  if (track) {
    video = getVideoFromVideoTrack(track);
  } else {
    if (!isIOS()) {
      try {
        video = await getNewVideoForScreenshotCapture();
        createdNewVideoForScreenshot = true;
      } catch (e) {
        console.log(
          `Requesting new media device for screenshot capture failed, try to fall back to live video-stream.`,
          e
        );
      }
    }
    if (!video) {
      createdNewVideoForScreenshot = false;
      try {
        video = getLocalParticipantVideo();
      } catch (e) {
        console.warn('Could not get video of local participant, default to first video in dom', e);
        video = document.getElementsByTagName('video').item(0);
      }
    }
  }

  // generate screenshot
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const ratio = video.videoWidth / video.videoHeight;
  const width = video.videoWidth;
  const height = Math.round(width / ratio);
  canvas.width = width;
  canvas.height = height;
  context.fillRect(0, 0, width, height);
  const dataUrl = (await new Promise((resolve) => {
    setTimeout(() => {
      context.drawImage(video, 0, 0, width, height);
      resolve(canvas.toDataURL('image/jpeg', SCREENSHOT_IMAGE_QUALITY));
    }, 1000);
  })) as string;

  // clean up
  if (createdNewVideoForScreenshot) {
    video.pause();
    video.remove();
  }

  // generate blob
  const imageBlob: Blob = await new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob);
      },
      'image/jpeg',
      0.8
    );
  });

  canvas.remove();

  return { dataUrl: dataUrl, width: width, height: height, blob: imageBlob };
}
