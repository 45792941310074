import React, { FC } from 'react';
import useTrack from '../../hooks/useTrack/useTrack';
import AudioTrack from './AudioTrack';
import VideoTrack from './VideoTrack';

import {
  AudioTrack as IAudioTrack,
  LocalTrackPublication,
  Participant,
  RemoteTrackPublication,
  Track,
} from 'twilio-video';

import { IVideoTrack } from '../../util/types';

interface PublicationProps {
  publication: LocalTrackPublication | RemoteTrackPublication;
  participant: Participant;
  isLocal: boolean;
  disableAudio?: boolean;
  videoPriority?: Track.Priority | null;
  hideVideo?: boolean;
}

const Publication: FC<PublicationProps> = ({ publication, isLocal, disableAudio, videoPriority, hideVideo }) => {
  const track = useTrack(publication);

  if (!track) return null;

  switch (track.kind) {
    case 'video':
      if (hideVideo) return null;
      return (
        <VideoTrack
          track={track as IVideoTrack}
          priority={videoPriority}
          isLocal={track.name.includes('camera') && isLocal}
        />
      );
    case 'audio':
      return disableAudio ? null : <AudioTrack track={track as IAudioTrack} />;
    default:
      return null;
  }
};

export default Publication;
