import React, { FC } from 'react';
import styled from 'styled-components';
import useParticipants from '../../../hooks/useParticipants/useParticipants';
import RequestScreenshotButton from './RequestScreenshotButton';
import ShareFileControls from './ShareFileControls';
import ToggleAudioButton from './ToggleAudioButton';
import ToggleFullScreenButton from './ToggleFullScreenButton';
import ToggleVideoButton from './ToggleVideoButton';
import ToggleViewButton from './ToggleViewButton';
import MuteAllAudioButton from './MuteAllAudioButton';
import ToggleSelectCameraButton from './ToggleSelectCameraButton';

const ControlsWrapper = styled.div`
  position: absolute;
  display: flex;
  left: 50%;
  transform: translateX(-50%);
  bottom: 5rem;
  z-index: 1;
  > button {
    margin: 0 0.5rem;
  }
`;

const ControlsBar: FC<{
  isConnected?: boolean;
  isCallHost?: boolean;
  isP2P?: boolean;
  isFullScreen?: boolean;
}> = ({ isConnected, isCallHost, isP2P, isFullScreen }) => {
  const participants = useParticipants();

  return (
    <ControlsWrapper>
      {isCallHost && !isP2P && !isFullScreen && <ToggleViewButton />}
      {isConnected && !isFullScreen && <ToggleFullScreenButton />}
      <ToggleVideoButton />
      <ToggleAudioButton />
      <ToggleSelectCameraButton />
      {isConnected && isCallHost && participants.length === 1 && <RequestScreenshotButton />}
      {isConnected && isCallHost && <ShareFileControls />}
      {isCallHost && !isP2P && isFullScreen && <MuteAllAudioButton />}
      {/*
        <Button><FaExpandArrowsAlt /></Button>
        <Button><FaExpand /></Button>
      */}
    </ControlsWrapper>
  );
};

export default ControlsBar;
