import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import DeleteFileModal from '../components/DeleteFileModal';
import { emitNotification } from '../components/Notification';
import TherapyDetails from '../components/TherapyDetails';
import ScheduleSession from '../components/ScheduleSession';
import SessionList from '../components/SessionList';
import WorkoutsContainer from './Workouts/WorkoutsContainer';
import { fetchFromRestAPI } from '../util/api';
import { useAuth0 } from '../util/auth0';
import { sendRoomInviteEmail } from '../util/roomInviteEmail';
import { Accordion, Card, Button, Alert, Row, Col } from 'react-bootstrap';
import QuestionnaireContainer from './Proms/PromsContainer';
import { ITherapy } from '../util/types';
import SecondaryButton from '../components/SecondaryButton';
import AppointmentList from '../components/appointment/AppointmentList';
import i18next from 'i18next';
import AnswerAndActivity from '../components/activity/AnswerAndActivity';
import { getTimeZoneOffset } from '../util/dateTimeUtils';
import moment from 'moment';

const Loader = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-grow: 1;
  font-size: 30px;
  justify-content: center;
  padding-top: 100px;
  color: white;
`;
const AppointmentDiv = styled.div`
    max-height: 40vh;
    overflow-y: auto;
    overflow-x:hidden;
`;
const alertBox = {
  background: '#FFCC4A',
  borderColor: '#FFCC4A',
  color: '#000000',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const PatientDetailsContainer: FC = () => {
  const { user, getIdTokenClaims } = useAuth0();
  const { therapyId } = useParams<{ therapyId: string }>();
  const [patientEmail, setPatientEmail] = useState<{ email: string }>();
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [therapy, setTherapy] = useState<ITherapy>();
  const [createTrainingPlanForSessionID, setCreateTrainingPlanForSessionId] = useState(null);
  const [fileToEdit, setFileToEdit] = useState(null);
  const [scheduledSessions, setScheduledSessions] = useState([]);
  const [scheduleNewSession, setScheduleNewSession] = useState(null);
  const [scheduledAppointments, setScheduledAppointments] = useState([]);
  const [activeId, setActiveId] = useState('0');
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  async function editFile(file) {
    const token = await getIdTokenClaims();

    const response = await fetchFromRestAPI(`/api/v1/files`, {
      method: 'POST',
      body: file,
      token,
    });

    const update = {
      ...therapy,
      sessions: therapy.sessions.reduce((memo, session) => {
        if (session.id !== response.session) {
          memo.push(session);
        } else {
          memo.push({
            ...session,
            images: session.images.reduce((memo, image) => {
              if (image.id !== response.id) memo.push(image);
              else if (!response.deleted) memo.push(response);
              return memo;
            }, []),
          });
        }

        return memo;
      }, []),
    };

    setTherapy(update);
    setPatientEmail({ email: update.patient.email });
  }

  async function removeFile() {
    await editFile({ ...fileToEdit, deleted: true });
    setFileToEdit(null);
  }

  const fetchAllUpcomingScheduledSessions = async () => {
    const idToken: any = await getIdTokenClaims();

    const scheduledSessionsFromRemote = await fetchFromRestAPI('/api/v1/scheduled-sessions', {
      token: idToken,
    });

    setScheduledSessions([...scheduledSessionsFromRemote, ...scheduledSessions]);
  };

  const fetchAllUpcomingScheduledAppointment = async () => {
    if (therapy) {
      const idToken: any = await getIdTokenClaims();
      const appointmentFromRemote = await fetchFromRestAPI(`/api/v1/appointment/patient/web/${therapy['patient']['_id']}`, {
        token: idToken,
      });
      setScheduledAppointments([...appointmentFromRemote, ...scheduledAppointments]);
    }
  };

  const sendNewScheduledSessionInviteEmail = async (scheduledSession, emailAddress, inviteMessage) => {
    const token = await getIdTokenClaims();

    await sendRoomInviteEmail({
      to: emailAddress,
      patientLastName: therapy.lastName,
      patientFirstName: therapy.firstName,
      inviteMessage: inviteMessage,
      token: token,
      therapistFullname: user.name,
      patientId: therapy.id,
      sessionDate: scheduledSession.start,
    });
    emitNotification(`Einladung erfolgreich an '${emailAddress}' versendet!`);
  };

  const resentActivationEmail = async () => {
    const idToken: any = await getIdTokenClaims();

    await fetchFromRestAPI(`/api/v1/patients/resend-email/`, {
      method: 'POST',
      token: idToken,
      body: { accountId: therapy.patient.accountId }
    });
    emitNotification(i18next.t('therapyDetails.resendActivationEmail.successMsg', patientEmail));
  };


  const createNewScheduledSession = async (
    scheduledSessionDate,
    shouldSendEmailInvite,
    emailAddress,
    emailInviteMessage
  ) => {
    const idToken: any = await getIdTokenClaims();
    setScheduleNewSession(false);

    const scheduledSessionFromRemote = await fetchFromRestAPI('/api/v1/scheduled-sessions', {
      method: 'POST',
      body: scheduledSessionDate,
      token: idToken,
    });

    setScheduledSessions([...scheduledSessions, scheduledSessionFromRemote]);
    if (shouldSendEmailInvite) {
      await sendNewScheduledSessionInviteEmail(scheduledSessionFromRemote, emailAddress, emailInviteMessage);
    }
  };

  const fetchTherapyDetails = async (): Promise<void> => {
    const idToken: any = await getIdTokenClaims();

    const therapyData = await fetchFromRestAPI(`/api/v1/therapies/${therapyId}`, {
      token: idToken,
    });
    setTherapy(therapyData);
    setPatientEmail({ email: therapyData.patient.email })
  };

  useEffect(() => {
    if (therapy) {
      const fetchDataAsync = async () => {
        await fetchAllUpcomingScheduledAppointment();
      };
      fetchDataAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [therapy]);

  useEffect(() => {
    if (!user) return;

    fetchTherapyDetails()
      .then(() => {
        setIsFetchingData(false);
        return fetchAllUpcomingScheduledSessions();
      })
      .catch((err) => {
        console.error('Error occurred while fetching patient details data', err);
      });
  }, [user]); // eslint-disable-line

  if (isFetchingData) {
    return (
      <Loader>
        <i className="fas fa-spin fa-cog" />
      </Loader>
    );
  }
  let patientDetailsChildren;
  const closeButtonNavigateTo = '/therapien';
  if (createTrainingPlanForSessionID) {
    return <Redirect to={`/patienten/${therapyId}/trainingsplan/${createTrainingPlanForSessionID}`} />;
  } else if (scheduleNewSession) {
    patientDetailsChildren = (
      <ScheduleSession
        patientWithSessions={therapy}
        scheduledSessions={scheduledSessions}
        onNewScheduledSessionCreated={createNewScheduledSession}
      />
    );
  } else {
    patientDetailsChildren = (
      <React.Fragment>
        <Accordion defaultActiveKey="3">
          <Card>
            <Card.Header>
              <Accordion.Toggle onClick={() => toggleActive('3')} as={Button} variant="link" eventKey="3">
                {i18next.t('therapyDetails.appointments.title')}
              </Accordion.Toggle>
              <SecondaryButton className="float-right" onClick={() => setShowAppointmentModal(true)}>
                {i18next.t('therapyDetails.appointments.button')}
              </SecondaryButton>
            </Card.Header>
            <Accordion.Collapse eventKey="3">
              <Card.Body>

                {scheduledAppointments ? (
                  <Row>
                    <Col md={12}>
                      <AppointmentDiv>
                        <AppointmentList
                          therapy={therapy}
                          appointmentList={scheduledAppointments}
                          isShow={showAppointmentModal}
                          onModalHide={(isHide) => setShowAppointmentModal(isHide)}
                          onCreateAppointment={async ({ date, startTime, endTime, duration }) => {
                            const token = await getIdTokenClaims();
                            const sDate = new Date();
                            var userTimezoneOffset = sDate.getTimezoneOffset() * 60000;
                            await fetchFromRestAPI('/api/v1/appointment/', {
                              method: 'POST',
                              body: {
                                therapist: therapy['therapist'],
                                patient: therapy['patient']['_id'],
                                active: true,
                                status: 'confirmed',
                                appointmentDate: date,
                                startTime: startTime,
                                endTime: endTime,
                                duration: duration,
                                utcDiff: getTimeZoneOffset(moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')),
                                userTimezone: userTimezoneOffset
                              },
                              token,
                            }).then((response) => {
                              if (response && !response.error) scheduledAppointments.unshift(response);
                            }).catch((err) => {
                              console.error('Error creating appointment', err);
                            });

                          }}

                        />
                      </AppointmentDiv>
                    </Col>
                  </Row>
                ) : null}
                {scheduledSessions && scheduledSessions.length > 0 ?
                  <SessionList
                    patient={therapy}
                    scheduledSessions={scheduledSessions}
                    onCreateTrainingPlan={(sessionId) => {
                      setCreateTrainingPlanForSessionId(sessionId);
                    }}
                    onRemoveFile={setFileToEdit}
                    onScheduleNewSession={() => setScheduleNewSession(true)}
                    onCreateManualSession={async ({ start, end }) => {
                      const token = await getIdTokenClaims();
                      const newSession = await fetchFromRestAPI('/api/v1/session/', {
                        method: 'POST',
                        body: { patient: therapy.id, start, end },
                        token,
                      });
                      setTherapy((pd) => ({ ...pd, sessions: [newSession, ...pd.sessions] }));
                    }}
                  /> : null}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <Accordion defaultActiveKey="1">
          <Card>
            <Card.Header>
              <Accordion.Toggle onClick={() => toggleActive('1')} as={Button} variant="link" eventKey="1">
                {i18next.t('therapyDetails.workouts.title')}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                {therapy ? <WorkoutsContainer therapy={therapy} /> : null}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Accordion defaultActiveKey="2">
          <Card>
            <Card.Header>
              <Accordion.Toggle onClick={() => toggleActive('2')} as={Button} variant="link" eventKey="2">
                {i18next.t('therapyDetails.questionnaire.title')}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                {therapy ? <QuestionnaireContainer therapy={therapy} /> : null}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Accordion defaultActiveKey="4">
          <Card>
            <Card.Header>
              <Accordion.Toggle onClick={() => toggleActive('4')} as={Button} variant="link" eventKey="4">
                {i18next.t('therapyDetails.answerAndActivity.title')}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
                {therapy ? <AnswerAndActivity therapy={therapy} /> : null}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <DeleteFileModal show={fileToEdit} onDelete={removeFile} onAbort={() => setFileToEdit(null)} />
      </React.Fragment>
    );
  }

  return (
    <>
      {!therapy.patient.isEmailVerified ?

        <Alert variant="warning" style={alertBox} >
          <Row><Col md={12} xs={10}> <span dangerouslySetInnerHTML={{ __html: i18next.t('therapyDetails.resendActivationEmail.body', patientEmail) }}></span>
            &nbsp;&nbsp;&nbsp;
            <SecondaryButton onClick={resentActivationEmail}>
              {i18next.t('therapyDetails.resendActivationEmail.button')}
            </SecondaryButton>
          </Col></Row>
        </Alert> : null}
      <TherapyDetails user={user} therapy={therapy} closeButtonNavigateTo={closeButtonNavigateTo}>

        {patientDetailsChildren}
      </TherapyDetails>
    </>
  );
};

export default PatientDetailsContainer;
