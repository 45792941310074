import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import styled from 'styled-components';
import SecondaryButton from '../../components/SecondaryButton';
import { fetchFromRestAPI } from '../../util/api';
import { useAuth0 } from '../../util/auth0';
import DetailsModal from './DetailsModal';
import { map } from 'lodash';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import PrimaryButton from '../../components/PrimaryButton';
import Image from 'react-bootstrap/Image'
const RefreshButton = styled(PrimaryButton)`
  float: right;
  margin: 1rem 0;
`;

const ActionButton = styled(SecondaryButton)`
  float: center;
`;


const Therapists = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [therapists, setTherapists] = useState([]);
  const [planList, setPlanList] = useState([]);
  const [detailsModal, setDetailsModal] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [activeTherapist, setActiveTherapist] = useState({});
  const { getIdTokenClaims, user } = useAuth0();

  const fetchPlanList = async (token) => {
    if (planList.length === 0 && token) {
        const plans = await fetchFromRestAPI('/api/v1/insurance/all', {
          method: 'GET',
          token,
        });
        if (plans && plans.length > 0) {
          const p = [];
          plans.forEach(element => {
            p.push(element.planCode);
          });
          setPlanList(p);
        }
    }
  }
  const fetchTherapists = useCallback(async () => {

    if (!user) {
      return;
    }
    setIsLoading(true);
    const token = await getIdTokenClaims();
    const activeSessionsResponse = await fetchFromRestAPI('/api/v1/admin/therapists/' + pageNo, {
      method: 'GET',
      token,
    });
    fetchPlanList(token);
    if (activeSessionsResponse && activeSessionsResponse.length > 0) {
      const newArray = [...therapists, ...activeSessionsResponse];
      setTherapists(newArray);
      setPageNo(pageNo + 1);
    }
    setIsLoading(false);
    // eslint-disable-next-line
  }, [user, getIdTokenClaims, pageNo]);

  const markTherapistAsTestUser = async (id, isTestUser) => {
    setIsLoading(true);
    const token = await getIdTokenClaims();
    const body = { id: id, isTestUser: isTestUser };
    await fetchFromRestAPI('/api/v1/admin/therapists/testuser', {
      method: 'POST',
      token,
      body
    });
    setIsLoading(false);
  }
  const blockUnblock = async (id, action) => {
    setIsLoading(true);
    const token = await getIdTokenClaims();
    const body = { id: id, action: action };
    await fetchFromRestAPI('/api/v1/admin/therapist/block', {
      method: 'POST',
      token,
      body
    });
    setDetailsModal(false);
    setIsLoading(false);
    setTherapists([]);
    setPageNo(1);
  }
  const addTherapistPlan = async (id, plan) => {
    setIsLoading(true);
    const token = await getIdTokenClaims();
    const body = { id: id, plan: plan };
    await fetchFromRestAPI('/api/v1/admin/therapists/update/plan', {
      method: 'POST',
      token,
      body
    });
    setIsLoading(false);
  }
  const displayDetails = (therapist) => {
    setActiveTherapist(therapist);
    setDetailsModal(true);
  };

  useEffect(() => {
    fetchTherapists();
    
    // eslint-disable-next-line
  }, [user, fetchTherapists, pageNo]);

  return (
    <React.Fragment>
      <RefreshButton onClick={() => { setTherapists([]); setPageNo(1); }} disabled={isLoading}>
        {isLoading ? 'Refreshing...' : 'Refresh'}
      </RefreshButton>

      <Table striped bordered hover className="h-75 overflow-auto">
        <thead>
          <tr>
            <th>Therapist</th>
            <th className='centered-td'>P2P Rooms/Sessions</th>
            <th className='centered-td'>P2P Hours</th>
            <th className='centered-td'>GROUP Rooms/Sessions</th>
            <th className='centered-td'>GROUP Hours</th>
            <th className='centered-td'>Credits Hours</th>
            <th className='centered-td'>Plans</th>
            <th className='centered-td'>Test User</th>
            <th className='centered-td'>Actions</th>

          </tr>
        </thead>
        <tbody key={therapists.length} >

          {map(therapists, (therapist) => {
            return (
              <tr key={therapist.email}>
                <td className='centered-td'>
                  <Image src={therapist.profilePic ? therapist.profilePic : '../default_profile.png'} roundedCircle={true} className='border' width='60px' height='60px' />
                  <p>{therapist.email} <Button variant="link" className="p-0" onClick={() => displayDetails(therapist)}>{therapist.approved ? <i className="fas fa-check-circle text-success" aria-hidden="true" /> : <i className="fas fa-ban text-danger" aria-hidden="true" />}</Button></p>
                </td>
                <td className='centered-td'>
                  {therapist.p2pStats && therapist.p2pStats.rooms ? therapist.p2pStats.rooms : '0'} /{' '}
                  {therapist.p2pStats && therapist.p2pStats.count ? therapist.p2pStats.count : '0'}
                </td>
                <td className='centered-td'>
                  {therapist.p2pStats && therapist.p2pStats.duration ? (therapist.p2pStats.duration / (60 * 60)).toFixed(2) : '0'}
                </td>
                <td className='centered-td'>
                  {therapist.groupStats && therapist.groupStats.rooms ? therapist.groupStats.rooms : '0'} /{' '}
                  {therapist.groupStats && therapist.groupStats.count ? therapist.groupStats.count : '0'}
                </td>
                <td className='centered-td'> {therapist.groupStats && therapist.groupStats.duration ? (therapist.groupStats.duration / (60 * 60)).toFixed(2) : '0'}
                </td>
                <td className='centered-td'> {therapist.remainingCreditsInSecs && therapist.remainingCreditsInSecs ? (therapist.remainingCreditsInSecs / (60 * 60)).toFixed(2) : '0'}
                </td>
                <td className='centered-td'>
                  <Typeahead
                    id="plan-typeahead-multiple"
                    multiple
                    onChange={(e) => {
                      therapist.plans = e;
                      addTherapistPlan(therapist._id, e);
                    }}
                    labelKey={(option) => `${option}`}
                    options={planList}
                    placeholder="Insurance Plan"
                    selected={therapist.plans}
                  />
                </td>
                <td className='centered-td'>
                  <Form.Check
                    inline
                    label=""
                    type="checkbox"
                    defaultChecked={therapist.isTestUser}
                    onChange={(e) => markTherapistAsTestUser(therapist._id, e.target.checked)}
                  /></td>
                <td className='centered-td'>
                  <ActionButton onClick={() => displayDetails(therapist)}>Details</ActionButton>
                </td>
              </tr>
            );
          })}

        </tbody>
      </Table>
      <DetailsModal show={detailsModal} therapistData={activeTherapist} blockUnblock={blockUnblock} onClose={() => setDetailsModal(false)} />
    </React.Fragment>
  );
};

export default Therapists;
