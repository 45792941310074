import i18next from 'i18next';
import { filter, isEmpty, orderBy } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { Button, Modal, Container, Col, Row, Card, Badge, Form, Table } from 'react-bootstrap';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { fetchFromRestAPI } from '../../util/api';
import { useAuth0 } from '../../util/auth0';
import { sendRoomInviteEmail } from '../../util/roomInviteEmail';
import CreateTherapyForm from '../../components/CreateTherapyForm';
import { emitNotification } from '../../components/Notification';
import StyledModal from '../../components/StyledModal';
import { Theme } from '../../components/ThemeProvider/useTheme/useTheme';
import { IPatient } from '../../util/types';
import { fetchUserAccountRemote, fetchTherapistKey } from '../../util/UserAccount';
import { daysRemaining } from '../../util/dateTimeUtils';
import { titleCase } from '../../util/urls';
import moment from 'moment';

const Wrapper = styled.main`
  display: flex;
  flex-grow: 1;
  color: white;
  flex-direction: column;
  padding: 20px;
  .btn-secondary {
    border: 2px solid ${(p) => (p.theme.mode === Theme.FITNESS ? '#1B7B7C' : '#ff695f')};
    color: ${(p) => (p.theme.mode === Theme.FITNESS ? '#1B7B7C' : '#ff695f')};
  }
  h1 {
    font-weight: bold;
    font-size: 4rem;
  }
  .banner {
    display: flex;
    > div {
      max-width: 480px;
    }
  }
  .control-btns {
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: auto;
    .btn {
      margin-bottom: 8px;
    }
  }
  .therapy-overview {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    > div {
      &:hover {
        background-color: #ececec;
        cursor: pointer;
        .name {
          font-weight: 700;
        }
      }
      display: flex;
      white-space: nowrap;
      padding: 4px 16px;
      align-items: center;
      background: white;
      &:first-of-type {
        padding-top: 8px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      &:last-of-type {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        padding-bottom: 8px;
      }
      .name {
        flex: 1;
        color: #444;
      }
      .controls{
        flex: 1;
      }
      .btn-secondary {
        margin-left: 12px;
      }
      input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        height: 39px;
        width: 250px;
      }
    }
  }

  @media (max-width: 750px), (max-device-width: 815px) and (-webkit-min-device-pixel-ratio: 3) {
    .banner {
      h1 {
        font-size: 1.8rem;
      }
      flex-direction: column;
      .control-btns {
        align-items: flex-start;
      }
    }
    .therapy-overview {
      > div {
        flex-direction: column;
        border-top: 1px solid #eee;
        &:first-of-type {
          border-top: 0;
        }
        .name {
          width: 100%;
          padding: 0;
        }
        .btn-group input {
          width: 100%;
        }
        .btn-secondary {
          margin: 8px auto 8px 0;
        }
      }
    }
  }
`;

const WorkoutStatusBadge: FC<{ status: string, endDate: any }> = ({ status, endDate }) => {
  const variantByStatus = {
    UNPUBLISHED: 'badge-bg-warning',
    PUBLISHED: 'badge-bg-success',
    DRAFT: 'badge-bg-warning',
  };
  if (status.toUpperCase() === 'PUBLISHED') {
    return <Badge className={'float-left text-uppercase badge-patient align-top font-weight-bold w-140 ' + variantByStatus[status.toUpperCase()]}>
      {daysRemaining(endDate) + " " + i18next.t(`patients.daysRemainingLabel`)}</Badge>;

  }
  return <Badge className={'float-left text-uppercase badge-patient align-top font-weight-bold w-140 ' + variantByStatus[status.toUpperCase()]}>
    {i18next.t(`trainingPlan.status.${status}`)}</Badge>;
};
type Props = {
  onLoadCompleted: (userProfile, token, eKey) => void;
};
const PatientListContainer: FC<Props> = ({
  onLoadCompleted
}) => {
  const history = useHistory();
  const { user, getIdTokenClaims, getAccessTokenClaims } = useAuth0();
  const [therapies, setTherapies] = useState([]);
  const [therapiesOriginal, setTherapiesOriginal] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [order, setOrderBy] = useState('');
  const [filledData, setFilledData] = useState({ firstName: '', lastName: '', email: '' });
  const [editPatient, setEditRoom] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showCreatePatientModal, setShowCreatePatientModal] = useState(false);
  const [modal, setModal] = useState(null);
  const i18nextLng = localStorage.getItem('i18nextLng');
  const userLanguage = i18nextLng && i18nextLng.includes('-') ? i18nextLng.split('-')[0] : i18nextLng;
  useEffect(() => {
    if (!user) return;

    async function load() {
      setIsLoading(true);
      const token = await getIdTokenClaims();
      const response = await fetchFromRestAPI('/api/v1/therapies', { token });
      if (response && !response.error) {
        setTherapies(response);
        setTherapiesOriginal(response);
      }
      const idToken = await getAccessTokenClaims();
      const userData = await fetchUserAccountRemote(token);
      const keyData = await fetchTherapistKey(idToken, token);
      onLoadCompleted(userData, token.__raw, keyData);
      setIsLoading(false);
    }

    load();
  }, [user]); // eslint-disable-line
  async function upsertPatientRemote(patientData: IPatient) {
    setIsLoading(true);
    const token = await getIdTokenClaims();
    const therapy = await fetchFromRestAPI('/api/v1/therapies', {
      method: 'POST',
      body: patientData,
      token,
    });
    setIsLoading(false);
    if (therapy && therapy.error) {
      return null;
    } else if (therapy) {
      setFilledData({ firstName: '', lastName: '', email: '' });
      return therapy;
    } else {
      return null;
    }
   
  }


  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, sortBy, order]);
  const handleSearch = () => {
    let newTherapies = [];
    if (!isEmpty(searchKey) && searchKey.length > 2) {
      newTherapies = filter(therapiesOriginal, function (o) {
        return o.patient ? ((o.patient.firstName + " " + o.patient.lastName + "").toLowerCase().indexOf(searchKey.toLowerCase()) >= 0 || (o.patient.email + "").toLowerCase().indexOf(searchKey.toLowerCase()) >= 0) || (o.insuranceplan && o.insuranceplan.planCode? o.insuranceplan.planCode.toLowerCase().indexOf(searchKey.toLowerCase())>=0 : false) : false;
      });
    } else {
      newTherapies = therapiesOriginal;
    }
    let sortedTherapis = newTherapies;
    if (!isEmpty(order)) {
      switch (sortBy) {
        case 'name':
          sortedTherapis = orderBy(newTherapies, (a) => (a.patient.firstName + "" + a.patient.lastName), [order === 'asc' ? 'asc' : 'desc']);
          break;
        case 'plan':
          sortedTherapis = orderBy(newTherapies, (a) => (a.insuranceplan?.planCode), [order === 'asc' ? 'asc' : 'desc']);
          break;
        case 'appointment':
          sortedTherapis = orderBy(newTherapies, (a) => (a.appointment?.startDateTime), [order === 'asc' ? 'asc' : 'desc']);
          break;
      }
    }
    setTherapies(sortedTherapis);
  }

  async function handlePatientRoomChanged(patientRoom) {
    const body = await upsertPatientRemote(patientRoom);
    if (body) {
      setTherapies(therapies => [...therapies, body]);
      setModal(null);
      setFilledData({ firstName: '', lastName: '', email: '' });
    }
    setShowCreatePatientModal(false);
  }

  async function handlePatientArchive(therapy) {
    const token = await getIdTokenClaims();
    await fetchFromRestAPI(`/api/v1/therapies/${therapy.id}`, {
      method: 'DELETE',
      token,
    });
    setModal(null);
    setShowCreatePatientModal(false);
    window.location.reload();
  }

  async function performSendRoomInviteEmail(patient: IPatient, inviteMessage: string) {
    const token = await getIdTokenClaims();
    await sendRoomInviteEmail({
      to: patient.email,
      patientLastName: patient.lastName,
      patientFirstName: patient.firstName,
      inviteMessage: inviteMessage,
      token: token,
      therapistFullname: user.name,
      patientId: patient.id,
    });
    emitNotification(`Einladung erfolgreich an '${patient.email}' versendet!`);
  }

  function toggleShowCreatePatientModal() {
    setEditRoom(null);
    setShowCreatePatientModal(!showCreatePatientModal);
  }

  const handleOnUserDataChange = (filledData) => {
    setFilledData(filledData);
  }

  const createInstantRoom = async () => {
    const token = await getIdTokenClaims();
    const roomRequestResponse = await fetchFromRestAPI('/api/v1/group-rooms', {
      method: 'POST',
      body: { instantRoomBookingTime: new Date(), maxParticipants: '1' },
      token,
    });
    history.push(`/${roomRequestResponse.id}`);
  };

  const handlePatientCreated = (patientInviteData) => {
    upsertPatientRemote({
      firstName: patientInviteData.firstName,
      lastName: patientInviteData.lastName,
      email: patientInviteData.email,
      seniorMode: patientInviteData.seniorMode,
      patientProfile: patientInviteData.patientProfile,
      isMobileRole: patientInviteData.isMobileRole,
    }).then((patientDataWithID: IPatient) => {
      if (patientDataWithID) {
        setTherapies(therapies => [...therapies, patientDataWithID]);
        if (patientInviteData.shouldSendInviteEmail) {
          return performSendRoomInviteEmail(patientDataWithID, patientInviteData.inviteMessage);
        }
      }
      setShowCreatePatientModal(false);
    })
      .catch((err) => {
        console.error('Error creating patient', err);
      });
  };

  const renderPatientRow = (therapy: any, index: any) => {
    if (therapy.status === 'deleted') return null;
    const patientProfile = therapy.patientProfile;
    let planCode = therapy.insuranceplan ? therapy.insuranceplan.planCode : '';
    const firstName = patientProfile && therapy.patient && therapy.patient.firstName ? therapy.patient.firstName : '';
    const lastName = patientProfile && therapy.patient && therapy.patient.lastName ? therapy.patient.lastName : '';
    const name = titleCase(firstName + " " + lastName);
    const email = patientProfile && therapy.patient ? therapy.patient.email : '';
    if (userLanguage === 'de')
      moment.locale('de')
    const aDate = therapy.appointment ? moment.parseZone(therapy.appointment.startDateTime).format('DD-MMM-YYYY HH:mm') : '';
    const tDate = therapy.trainingplan ? therapy.trainingplan.endDate : null;
    const tStatus = therapy.trainingplan ? therapy.trainingplan.status : null;
    return (<tr key={'therapy' + index} >
      <th className="w-30" onClick={() => history.push(`/therapien/${therapy.id}`)}>
        <p className='patientName p-0 m-0'>{name}</p>
        <p className='small fontGray p-0 m-0'>{email}</p>
      </th>
      <th onClick={() => history.push(`/therapien/${therapy.id}`)}>{email === user.email ? i18next.t('patients.therapistBadge') : planCode}</th>
      <th onClick={() => history.push(`/therapien/${therapy.id}`)}>{aDate}</th>
      <th onClick={() => history.push(`/therapien/${therapy.id}`)}>{tStatus && tDate ? <WorkoutStatusBadge status={tStatus} endDate={tDate}></WorkoutStatusBadge> : null}</th>
      <th> {email !== user.email ?
        <Button className='float-right'
          onClick={() => {
            setModal({ type: 'delete-patient', therapy });
          }}
          variant="secondary"
        >
          <i className="fa fa-trash" aria-hidden="true" />
        </Button> : null}</th>
    </tr>);
  }
  const getOrderByIcon = () => {
    if (order === 'asc') {
      return (<span className="float-right" ><i className="fa fa-fw fa-sort-up"></i></span>);
    } else if (order === 'desc') {
      return (<span className="float-right" ><i className="fa fa-fw fa-sort-down"></i></span>);
    } else {
      return (<span className="float-right" ><i className="fa fa-fw fa-sort"></i></span>);
    }
  }
  const handleOrderClick = () => {
    if (order === 'asc') {
      setOrderBy('desc');
    } else if (order === 'desc') {
      setOrderBy('');
    } else {
      setOrderBy('asc');
    }
  }
  return (
    <Container>
      <Wrapper>
        <Row>
          <Col md={9} className="text-break">
            <div dangerouslySetInnerHTML={{ __html: i18next.t('patients.introText') }} />
          </Col>
          <Col md={3} className="text-right">
            <Button onClick={toggleShowCreatePatientModal}>
              {i18next.t('patients.createPatient')}</Button><br />
            <Button variant="secondary" onClick={createInstantRoom} className="mt-2">
              {i18next.t('patients.createInstantRoom')}
            </Button>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <h2>{i18next.t('patients.heading')} {!isEmpty(therapies) ? `(${therapies.length - 1})` : null}</h2>
            <Card className="rounded-left rounded-right">
              <Card.Header className="pb-0 pr-2 pl-2 pt-0 border-0 bg-white">
                <Row>
                  <Col md={12}>
                    <Form.Group className='p-0 m-0 pt-1 pb-1'>
                      <Form.Control type="text" placeholder={i18next.t(`patients.searchLabel`)} defaultValue={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Header>
              {!isLoading ?
                <Card.Body className='p-0 '>
                  <div className="table-responsive">
                    <Table className="table-patient">
                      <thead>
                        <tr key={sortBy + order}>
                          <th className="w-30" onClick={() => { handleOrderClick(); setSortBy('name'); }}>{i18next.t(`patients.tableHeader.name`)}

                            {sortBy === 'name' ? getOrderByIcon() : <span className="float-right" ><i className="fa fa-fw fa-sort"></i></span>}</th>
                          <th onClick={() => { handleOrderClick(); setSortBy('plan'); }}>{i18next.t(`patients.tableHeader.program`)}

                            {sortBy === 'plan' ? getOrderByIcon() : <span className="float-right" ><i className="fa fa-fw fa-sort"></i></span>}
                          </th>
                          <th onClick={() => { handleOrderClick(); setSortBy('appointment'); }}>{i18next.t(`patients.tableHeader.nextTherapy`)}

                            {sortBy === 'appointment' ? getOrderByIcon() : <span className="float-right" ><i className="fa fa-fw fa-sort"></i></span>}
                          </th>
                          <th>{i18next.t(`patients.tableHeader.trainingPlan`)} </th>
                          <th></th>
                        </tr>
                      </thead>
                      {!isEmpty(therapies) ? <tbody>

                        {therapies.map((therapy, index) => {
                          return renderPatientRow(therapy, index);
                        })}
                      </tbody> : <tbody><tr><th colSpan={5}>{i18next.t(`patients.noPatientFound`)}</th></tr></tbody>}
                      <tbody>

                      </tbody>
                    </Table>
                  </div>
                </Card.Body> :
                <Card.Body className='p-0 text-center'>
                  <span className="loader">
                    <i className="fas fa-spin fa-cog fa-2x text-dark" />
                  </span>
                </Card.Body>
              }
            </Card>
          </Col>
        </Row>
        <StyledModal show={showCreatePatientModal} dialogClassName="modal-dialog-centered" onHide={toggleShowCreatePatientModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {i18next.t(editPatient?.id ? 'patients.editPatientRoom' : 'patients.createPatient')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CreateTherapyForm
              onUserDataChange={handleOnUserDataChange}
              filledData={filledData}
              therapy={editPatient}
              onUpdate={editPatient ? handlePatientRoomChanged : handlePatientCreated}
            />
          </Modal.Body>
        </StyledModal>

        <StyledModal show={modal && modal.type === 'delete-patient'} dialogClassName="modal-dialog-centered" onHide={() => setModal(null)}>
          {modal !== null && modal.therapy ? (
            <>
              <Modal.Header closeButton>
                <Modal.Title>{i18next.t('patients.archivePatientTitle')}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{i18next.t('patients.archivePatientBody')}</Modal.Body>
              <Modal.Footer>
                <Button style={{ marginRight: 'auto' }} variant="secondary" onClick={() => setModal(null)}>
                  {i18next.t('generic.cancel')}
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handlePatientArchive(modal.therapy)}
                >
                  {i18next.t('generic.archive')}
                </Button>
              </Modal.Footer>
            </>
          ) : null}
        </StyledModal>

      </Wrapper>
    </Container >
  )
};

export default PatientListContainer;
