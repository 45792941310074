import { isEmpty, isPlainObject, uniq } from 'lodash';

export const isMobile = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

// This function ensures that the user has granted the browser permission to use audio and video
// devices. If permission has not been granted, it will cause the browser to ask for permission
// for audio and video at the same time (as opposed to separate requests).
export function ensureMediaPermissions() {
  return navigator.mediaDevices
    .enumerateDevices()
    .then((devices) => devices.every((device) => !(device.deviceId && device.label)))
    .then((shouldAskForMediaPermissions) => {
      if (shouldAskForMediaPermissions) {
        return navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then((mediaStream) => mediaStream.getTracks().forEach((track) => track.stop()));
      }
    });
}

// Recursively removes any object keys with a value of undefined
export function removeUndefineds<T>(obj: T): T {
  if (!isPlainObject(obj)) return obj;

  const target: { [name: string]: any } = {};

  for (const key in obj) {
    const val = obj[key];
    if (typeof val !== 'undefined') {
      target[key] = removeUndefineds(val);
    }
  }

  return target as T;
}

export function setOnlineUser(connectionId, isOnline) {
  let onlineUserArray = [];
  const store = localStorage.getItem('_online_');
  if (isEmpty(store))
    onlineUserArray = [];
  else
    onlineUserArray = store.split(',');
  const index = onlineUserArray.indexOf(connectionId);
  if (isOnline && index < 0) {
    onlineUserArray.push(connectionId);
  } else if (!isOnline && index >= 0) {
    onlineUserArray.splice(index, 1);
  }

  onlineUserArray = uniq(onlineUserArray)
  localStorage.setItem('_online_', onlineUserArray.join(','));
}
export function resetNotificationDot() {
  const spanNotification = document.getElementById('notificationBadge');
  const notificationTextBold = document.getElementById('notificationTextBold');
  if (spanNotification) {
    spanNotification.style.display = 'none';
    spanNotification.classList.add('d-none');
  }
  if (notificationTextBold) {
    notificationTextBold.classList.remove('font-weight-bold');
  }
}

export function displayNotificationDot() {
  const spanNotification = document.getElementById('notificationBadge');
  const notificationTextBold = document.getElementById('notificationTextBold');
  if (spanNotification) {
    spanNotification.style.display = 'block';
    spanNotification.classList.remove('d-none');
  }
  if (notificationTextBold) {
    notificationTextBold.classList.add('font-weight-bold');
  }
}
export function displayLocalLastMessage(id: string, message: string, dateStr: string) {
  const lastMessageDate = document.getElementById('lastMessageDate-' + id);
  const lastMessage = document.getElementById('lastMessage-' + id);
  if (lastMessage) {
    let actualMsg = message.replace(/<[^>]*>?/gm, '');
    actualMsg = actualMsg && actualMsg.length > 20 ? actualMsg.substring(0, 20) + '...' : actualMsg;
    lastMessage.innerHTML = actualMsg;
  }
  if (lastMessageDate)
    lastMessageDate.innerHTML = dateStr;
}
export function confirmSentMessage(ackId: string = '') {
  //chat-date-loading
  setTimeout(
    function () {
      var ackIdDiv = document.getElementById(ackId);
      if (ackIdDiv) {
        ackIdDiv.classList.remove("chat-bg-right-notsent");
        ackIdDiv.classList.remove('chat-bg-right-discard');
      }

      const loaderDiv = document.getElementsByClassName('chat-date-loading');
      if (loaderDiv && loaderDiv.length > 0) {
        Array.from(loaderDiv).forEach(element => {
          element.classList.add('d-none');
        });

      }
      const errorDiv = document.getElementsByClassName('chat-error-message-' + ackId);
      if (errorDiv && errorDiv.length > 0) {
        Array.from(errorDiv).forEach(element => {
          element.classList.remove('chat-error-message-' + ackId);
        });
      }
      const messageBox = document.getElementById('chat-message-body-' + ackId);
      if (messageBox) {
        messageBox.classList.add("no-event");
      }
    }, 500);
}
export function blurUnsentMessage(ackId: string = '') {
  //chat-date-loading
  setTimeout(
    function () {
      const objDiv = document.getElementsByClassName('chat-bg-right-notsent')
      if (objDiv && objDiv.length > 0) {
        Array.from(objDiv).forEach(element => {
          element.classList.remove('chat-bg-right-notsent');
          element.classList.add('chat-bg-right-discard');
        });

      }
      const loaderDiv = document.getElementsByClassName('chat-date-loading');
      if (loaderDiv && loaderDiv.length > 0) {
        Array.from(loaderDiv).forEach(element => {
          element.classList.add('d-none');
        });

      }
      const errorDiv = document.getElementsByClassName('chat-error-message-' + ackId);
      if (errorDiv && errorDiv.length > 0) {
        Array.from(errorDiv).forEach(element => {
          element.classList.remove('d-none');
        });

      }
    }, 4000);
}

export function showUpgradeModel(isShow, title='', body='') {
  if (isShow) {
    document.getElementById("upgradeNotificationTitle").innerHTML = title;
    document.getElementById("upgradeNotificationBody").innerHTML = body;
    document.querySelector(".modal").classList.add("show");
    document.getElementById("upgradeModal").style.display = "block";
  } else {
    document.getElementById("upgradeNotificationTitle").innerHTML = '';
    document.getElementById("upgradeNotificationBody").innerHTML = '';
    document.querySelector(".modal").classList.remove("show");
    document.getElementById("upgradeModal").style.display = "none";
  }
}