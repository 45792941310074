import { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';

export enum Theme {
  FITNESS = 'FITNESS',
  PHYSIO = 'PHYSIO',
}

const cookies = new Cookies();

export interface ThemeProps {
  theme: Theme;
}

export const isFitness =
  window.location.href.indexOf('fitness') !== -1 || cookies.get('room4_feature_fitness') === 'true';

export default function useTheme(): { mode: Theme } {
  const [theme, setTheme] = useState({ mode: Theme.PHYSIO });

  useEffect(() => {
    if (isFitness) {
      setTheme({ mode: Theme.FITNESS });
    }
  }, []);

  return theme;
}
