import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useFormik } from 'formik';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import FormGroup from '../FormGroup';
import PrimaryButton from '../PrimaryButton';
import { fetchFromRestAPI } from '../../util/api';
import { Theme } from '../ThemeProvider/useTheme/useTheme';
import i18next from 'i18next';

const Support = styled.div`
  text-align: center;
  font-size: 1.2rem;
`;

const FullSizePrimaryButton = styled(PrimaryButton)`
  width: 100%;
`;

const RoomCodeInputControl = styled(Form.Control)`
  letter-spacing: 0.5rem;
`;

const RoomCodeWrongError = styled.div`
  font-size: 1rem;
  font-weight: 700;
  color: ${(p) => (p.theme.mode === Theme.FITNESS ? '#1B7B7C' : '#ff695f')};
`;

const getRoomRemote = async (roomCode) => {
  return fetchFromRestAPI(`/api/v1/group-rooms/${roomCode}`);
};

const PatientRoomCodeBox = () => {
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      roomCode: '',
    },
    validate: (values) => {
      const errors: any = {};
      if (!values.roomCode) {
        errors.roomCode = i18next.t('loginpage.joinRoomEmptyError');
      } else if (values.roomCode.length < 4) {
        errors.roomCode = i18next.t('loginpage.joinRoomLengthError')
      }
      return errors;
    },
    onSubmit: (values, actions) => {
      getRoomRemote(values.roomCode) // this just checks whether the room exists. Is this really required?
        .then((room) => {
          if(room && room.id)
          history.push(`/${room.id}`); // or values.roomCode
        })
        .catch((error) => {
          actions.setFieldError('roomCode', `Der Raum-code "${values.roomCode}" konnte nicht gefunden werden`);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
  });

  return (
    <React.Fragment>
      <div>
      {i18next.t('loginpage.joinRoomDescription')}
      </div>
      <form onSubmit={formik.handleSubmit}>
        <FormGroup as={Col}>
          <Form.Label>{i18next.t('loginpage.joinRoomHeading')}</Form.Label>
          {formik.errors.roomCode && <RoomCodeWrongError>{formik.errors.roomCode}</RoomCodeWrongError>}
          <RoomCodeInputControl
            placeholder="XXXX"
            value={formik.values.roomCode}
            id="roomCode"
            name="roomCode"
            onChange={formik.handleChange}
          />
        </FormGroup>
        <FullSizePrimaryButton type="submit" disabled={!formik.isValid || formik.isSubmitting}>
        {i18next.t('loginpage.joinRoomButton')}
        </FullSizePrimaryButton>
      </form>
      <Support>
      <p dangerouslySetInnerHTML={{ __html: i18next.t('loginpage.joinRoomSupport') }} />
      </Support>
    </React.Fragment>
  );
};

export default PatientRoomCodeBox;
