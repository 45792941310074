import { FC, useEffect } from 'react';
import useParticipantSentMessage from '../useParticipantSentMessage/useParticipantSentMessage';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { CallMessagingAction } from '../../../hooks/useDataTrack/useDataTrack';
import { LocalDataTrack } from 'twilio-video';

const EndCallOnHostRequestHandler: FC = () => {
  const participantSentMessage = useParticipantSentMessage();
  const { localTracks, endCall, pinCode } = useVideoContext();

  useEffect(() => {
    participantSentMessage.on(CallMessagingAction.ACTION_END_CALL, (eventData) => {
      console.log('Request to end call received from host');
      endCall(pinCode).catch((err) => {
        console.error('Error ending call on host request', err);
      });

      localTracks.forEach((track) => {
        if (!(track instanceof LocalDataTrack)) {
          track.stop();
        }
      });
    });
    return () => {
      participantSentMessage.off(CallMessagingAction.ACTION_END_CALL);
    };
  }, [localTracks, participantSentMessage, endCall, pinCode]);
  return null;
};

export default EndCallOnHostRequestHandler;
