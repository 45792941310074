import React, { memo, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import i18next from 'i18next';
import { useParams } from 'react-router-dom';
import { fetchFromRestAPI } from '../../util/api';
import ConfirmationModal from '../ConfirmationModal';
import { Form } from 'react-bootstrap';
import { emitNotification } from '../Notification';

const Wrapper = styled.div`
    background: #ffffff;
    width : 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content : center;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content : center;
    align-items: center;
`;


const RejectAppointment = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [comment, setComment] = useState('');
    const { appointmentId } = useParams<{ appointmentId: string }>();
    const handleRejectAppointment = async () => {
        setIsLoading(true);
        await fetchFromRestAPI(`/api/v1/appointment/public/therapist/reject/${appointmentId}`, {
            method: 'POST',
            body: { comment: comment }
        })
            .then((response) => {
                if (response && !response.error) {
                    history.replace('/');
                } else {
                    emitNotification(i18next.t('errorMessageTemplate.genericMsg'), {
                        type: 'error',
                        position: 'top-right',
                    });
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.error('Error reject appointment', err);
                setIsLoading(false);
                window.close();
            });
    };
    return (
        <Wrapper>
            <Content>
                <ConfirmationModal
                    disableButton={isLoading}
                    show={true}
                    title={i18next.t('createAppointmentForm.rejectModal.title')}
                    body={
                        <>
                            <p>{i18next.t('createAppointmentForm.rejectModal.body')}</p>
                            <Form.Control as='textarea' rows={3} value={comment}
                                onChange={e => { comment.length < 200 ? setComment(e.target.value) : setComment(comment.slice(0, 200)) }}
                            />
                        </>
                    }
                    buttonPositive={i18next.t('createAppointmentForm.labels.reject')}
                    buttonNegative={i18next.t('generic.cancel')}
                    onPositive={handleRejectAppointment}
                    onNegative={() => window.close()}
                />
            </Content>
        </Wrapper>
    );
};

export default memo(RejectAppointment);
