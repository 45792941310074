import React, { FC, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Auth0Provider } from '../util/auth0';
import PatientListContainer from '../containers/Therapies/PatientListContainer';
import TherapyDetailsContainer from '../containers/TherapyDetailsContainer';
import MyAccount from './MyAccount';
import AdminPage from '../containers/Admin/AdminPage';
import WorkoutDetailsContainer from '../containers/Workouts/WorkoutDetailsContainer';
import LoginPageContainer from '../containers/LoginPageConatiner';
import PageLayout from './PageLayout';
import AccountSetupContainer from '../containers/AccountSetupContainer';
import AuthenticatedRoute from './AuthenticatedRoute';
import GroupCallContainer from '../containers/GroupCallContainer';
import LeftGroupRoom from './groupRoom/LeftGroupRoom';
import { ThemeProvider } from 'styled-components';
import useTheme from './ThemeProvider/useTheme/useTheme';
import { AUTH0_REGISTRATION_START, REACT_APP_WEBSOCKET_URL } from '../config';
import About from './About';
import NotFound from './404';
import AppointmentContainer from '../containers/AppointmentContainer';
import RegisterPageConatiner from '../containers/RegisterPageConatiner';
import AcceptAppointment from './appointment/AcceptAppointment';
import RejectAppointment from './appointment/RejectAppointment';
import ChatPageContainer from '../containers/ChatPageContainer';
import { generateKeyPair } from '../util/crypto';
import { confirmSentMessage, displayNotificationDot, resetNotificationDot, setOnlineUser } from '../util';

const App: FC = () => {
  const theme = useTheme();
  const [newMessage, setNewMessages] = useState(null);
  const [chatKey, setChatKey] = useState('');
  const [selectedTherapy, setSelectedTherapy] = useState(null);
  const [tKeyPair, setTKeyPair] = useState(null);
  const [userProfile, setUserProfile] = useState({});
  const handleTherapySelection = (therapy) =>{
    setSelectedTherapy(therapy);
  }
  const resetNewChatMessage = () => {
    setNewMessages(null);
  }
  const getWS = (therapistId, token, retry) => {
    resetNotificationDot();
    if (retry <= 3) {
      try {
        const wss = new WebSocket(REACT_APP_WEBSOCKET_URL + "/t:" + therapistId, token);
        wss.onopen = () => {
          localStorage.removeItem('_online_');
          retry = 0;
        }
        wss.onmessage = (e) => {
          const message = JSON.parse(e.data);
          if (message) {
            switch (message.messageType) {
              case 'OFFLINE':
                setNewMessages(message.message);
                displayNotificationDot();
                setChatKey(message.senderId);
                break;
              case 'ACK':
                confirmSentMessage(message.ackId);
                break;
              default:
                setNewMessages(message);
                displayNotificationDot();
                setOnlineUser(message.senderId, true);
                setChatKey(message.senderId);
                break;
            }
          }
        }
        wss.onclose = () => {
          setWs(getWS(therapistId, token, retry + 1));
        }
        wss.onerror = () => {
          setWs(getWS(therapistId, token, retry + 1));
        }

        return wss;
      } catch (error) {
        return getWS(therapistId, token, retry + 1);
      }
    }
  }
  const [ws, setWs] = useState(null);

  const initWebSocket = (userData: any, token: any, eKey: any) => {
    if (userData && userData._id && token && !ws && eKey) {
      const kp = generateKeyPair(eKey.toString());
      setTKeyPair(kp);
      setWs(getWS(userData._id, token, 0))
      setUserProfile(userData);
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Auth0Provider>
          <PageLayout>
            <Switch>
              <AuthenticatedRoute path="/wordpress"
                component={() => {
                  window.location.href = AUTH0_REGISTRATION_START;
                  return null;
                }}
              />
              <AuthenticatedRoute path="/appointments" exact strict sensitive>
                <AppointmentContainer />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/about" exact strict sensitive>
                <About />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/therapien" exact strict sensitive>
                <PatientListContainer onLoadCompleted={initWebSocket} />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/messages" exact strict sensitive>
                <ChatPageContainer userProfile={userProfile} webSocket={ws} selectedTherapy={selectedTherapy} handleTherapySelection={handleTherapySelection} onLoadCompleted={initWebSocket} messages={newMessage} chatKey={chatKey} tKeyPair={tKeyPair} resetNewChatMessage={resetNewChatMessage} />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/therapien/:therapyShortId/workout/:trainingId" exact strict sensitive>
                <WorkoutDetailsContainer />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/therapien/:therapyShortId/workout" exact strict sensitive>
                <WorkoutDetailsContainer />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/therapien/:therapyId" exact strict sensitive>
                <TherapyDetailsContainer />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/admin" exact strict sensitive>
                <AdminPage />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/account" exact strict sensitive>
                <MyAccount />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/account-setup" exact strict sensitive>
                <AccountSetupContainer />
              </AuthenticatedRoute>
              <Route path="/404" exact>
                <NotFound />
              </Route>
              <Route path="/register" exact>
                <RegisterPageConatiner signIn={false} />
              </Route>
              <Route path="/teletherapie/accept/:appointmentId">
                <AcceptAppointment />
              </Route>
              <Route path="/teletherapie/reject/:appointmentId">
                <RejectAppointment />
              </Route>
              <Route path="/:code/verlassen/:isTrainer/:pin?">
                <LeftGroupRoom />
              </Route>
              <Route path="/:code/:pin?">
                <GroupCallContainer />
              </Route>
              <Route path="/">
                <LoginPageContainer signIn={true} />
              </Route>
              <Route component={NotFound} />
            </Switch>
          </PageLayout>
        </Auth0Provider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
