import { getFullURL } from './endpoints';
import { startsWith } from 'lodash';
import { emitNotification } from '../components/Notification';
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, AUTH0_LOGOUT_URL } from '../config';
import i18next from 'i18next';
import { Auth0Lock } from 'auth0-lock';
import { showUpgradeModel } from '../util';
export interface APIOptions {
  token?: any;
  body?: any;
  method?: string;
  type?: string;
}

// class RestAPIError extends Error {
//   code;

//   constructor(message, errorCode) {
//     super(message);
//     this.code = errorCode;
//     this.name = 'RestAPIError';
//   }
// }
export const localLogout = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('id_token');
  localStorage.removeItem('expires_at');
  localStorage.removeItem('user');

  const lock = new Auth0Lock(
    AUTH0_CLIENT_ID,
    AUTH0_DOMAIN
  );

  lock.logout({ returnTo: AUTH0_LOGOUT_URL });
}
export async function fetchFromRestAPI(path, options?: APIOptions) {
  let fetchRequestConfig = {
    method: options && options.method ? options.method : 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
    },
  };

  if (options && options.body) {
    fetchRequestConfig = Object.assign({}, fetchRequestConfig, {
      body: JSON.stringify(options.body),
    });
  }

  if (options && options.token) {
    fetchRequestConfig.headers = Object.assign({}, fetchRequestConfig.headers, {
      Authorization: `Bearer ${options.token.__raw}`,
    });
  }

  const fetchRequest = await fetch(getFullURL(path, options && options.type && options.type), fetchRequestConfig);
  const responseText = await fetchRequest.text();

  const isApplicationJson = startsWith(fetchRequest.headers.get('content-type'), 'application/json');

  const errorNtfnSettings = {
    type: 'error',
    position: 'top-right'
  };

  switch (fetchRequest.status) {
    case 401:
      emitNotification(i18next.t('errorMessageTemplate.authError'), errorNtfnSettings);
      localLogout();
      break;
    case 451:
      if (responseText) {
        if (isApplicationJson) {
          const { error } = JSON.parse(responseText);
          if (error && error.startsWith("key_")) {
            const key = "errorMessageTemplate." + error;
            showUpgradeModel(true, i18next.t(`${key}`), i18next.t('errorMessageTemplate.key_TherapistPlanUpgradeError'));
          }
        }
      }
      break;
    case 500:
      emitNotification(i18next.t('errorMessageTemplate.genericMsg'), errorNtfnSettings);
      break;
    default:
      if (responseText && JSON.parse(responseText)) {
        if (isApplicationJson) {
          const { error } = JSON.parse(responseText);
          if (error && error === 'key_PlanExpried') {
            if (error.startsWith("key_")) {
              const key = "errorMessageTemplate." + error;
              emitNotification(i18next.t(`${key}`), errorNtfnSettings);
            } else {
              emitNotification(error, errorNtfnSettings);
            }
            //setTimeout(() => { localLogout() }, 2000); //need to clearify this why we are loggin out if plan expire
          }
          else if (error && error !== 'key_NoProfileFound') {
            if (error.startsWith("key_")) {
              const key = "errorMessageTemplate." + error;
              emitNotification(i18next.t(`${key}`), errorNtfnSettings);
            } else {
              emitNotification(error, errorNtfnSettings);
            }
          }
        } else {
          emitNotification(i18next.t('errorMessageTemplate.genericMsg'), errorNtfnSettings);
        }
      }
      break;
  }

  if (responseText) {
    return isApplicationJson ? JSON.parse(responseText) : responseText;
  }
}
