import '@fortawesome/fontawesome-free/js/all';
import * as Sentry from '@sentry/browser';
import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import ReactDOM from 'react-dom';
import YAML from 'yaml';
import App from './components/App';
// import { isFitness } from './components/ThemeProvider/useTheme/useTheme';
import { I18NEXT_DEBUG, I18NEXT_URL } from './config';
import './style.scss';
import LanguageDetector from 'i18next-browser-languagedetector';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

//set leanguage detection options
const lngOptions = {
  order: ['querystring', 'cookie', 'navigator'],
};

try {
  if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
    Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
    Sentry.setUser(null);
  }
} catch (ex) {
  console.error(ex);
}

// const defaultNS = isFitness ? 'room4fitness' : 'room4physio';
i18next
  .use(Backend)
  .use(LanguageDetector)
  .init(
    {
      //lng: 'de',
      load: 'languageOnly',
      fallbackLng: 'de',
      preload: ['en', 'de'],
      nonExplicitSupportedLngs: true,
      detection: lngOptions,
      debug: Boolean(I18NEXT_DEBUG),
      ns: ['common'],
      fallbackNS: 'common',
      backend: {
        loadPath: I18NEXT_URL,
        parse: YAML.parse,
      },
      saveMissing: true,
      missingKeyHandler: console.log,
    },
    () => {
      // wait until localizations are loaded
      ReactDOM.render(
        <DndProvider backend={HTML5Backend}>
          <App />
        </DndProvider>, document.getElementById('root'));
    }
  );
